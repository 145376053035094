/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import { keysOf } from '../common';
import classNames from 'classnames';
var typeToClassNameMap = {
  regular: 'ouiCommentEvent--regular',
  update: 'ouiCommentEvent--update'
};
export var TYPES = keysOf(typeToClassNameMap);
export var OuiCommentEvent = ({
  children,
  className,
  username,
  timestamp,
  type = 'regular',
  event,
  actions
}) => {
  const classes = classNames('ouiCommentEvent', typeToClassNameMap[type], className);
  const isFigure = type === 'regular' || type === 'update' && typeof children !== 'undefined';
  const Element = isFigure ? 'figure' : 'div';
  const HeaderElement = isFigure ? 'figcaption' : 'div';
  return <Element className={classes}>
      <HeaderElement className="ouiCommentEvent__header">
        <div className="ouiCommentEvent__headerData">
          <div className="ouiCommentEvent__headerUsername">{username}</div>
          <div className="ouiCommentEvent__headerEvent">{event}</div>
          {timestamp ? <div className="ouiCommentEvent__headerTimestamp">
              <time>{timestamp}</time>
            </div> : undefined}
        </div>
        {actions ? <div className="ouiCommentEvent__headerActions">{actions}</div> : undefined}
      </HeaderElement>
      {children ? <div className="ouiCommentEvent__body">{children}</div> : undefined}
    </Element>;
};
OuiCommentEvent.propTypes = {
  /**
     * Author of the comment. Display a small icon or avatar with it if needed.
     */
  username: PropTypes.node.isRequired,

  /**
     * Time of occurrence of the event. Its format is set on the consumer's side
     */
  timestamp: PropTypes.node,

  /**
     * Describes the event that took place
     */
  event: PropTypes.node,

  /**
     * Custom actions that the user can perform from the comment's header
     */
  actions: PropTypes.node,

  /**
     * Use "update" when the comment is primarily showing info about actions that the user or the system has performed (e.g. "user1 edited a case").
     */
  type: PropTypes.oneOf(["regular", "update"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiCommentEvent.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiCommentEvent',
    methods: [],
    props: {
      username: {
        defaultValue: null,
        description: 'Author of the comment. Display a small icon or avatar with it if needed.',
        name: 'username',
        parent: {
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        },
        declarations: [{
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      timestamp: {
        defaultValue: null,
        description: "Time of occurrence of the event. Its format is set on the consumer's side",
        name: 'timestamp',
        parent: {
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        },
        declarations: [{
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      event: {
        defaultValue: null,
        description: 'Describes the event that took place',
        name: 'event',
        parent: {
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        },
        declarations: [{
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      actions: {
        defaultValue: null,
        description: "Custom actions that the user can perform from the comment's header",
        name: 'actions',
        parent: {
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        },
        declarations: [{
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      type: {
        defaultValue: {
          value: 'regular'
        },
        description: 'Use "update" when the comment is primarily showing info about actions that the user or the system has performed (e.g. "user1 edited a case").',
        name: 'type',
        parent: {
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        },
        declarations: [{
          fileName: 'oui/src/components/comment_list/comment_event.tsx',
          name: 'OuiCommentEventProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"regular" | "update"',
          value: [{
            value: '"regular"'
          }, {
            value: '"update"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiCommentEventProps', 'CommonProps']
  };
} catch (e) {}