/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
import { setPropsForRestrictedPageWidth } from './_restrict_width';
var paddingSizeToClassNameMap = {
  none: null,
  s: 'ouiPage--paddingSmall',
  m: 'ouiPage--paddingMedium',
  l: 'ouiPage--paddingLarge'
};
var directionToClassNameMap = {
  row: null,
  column: 'ouiPage--column'
};
export var SIZES = keysOf(paddingSizeToClassNameMap);
export var DIRECTIONS = keysOf(directionToClassNameMap);
export var OuiPage = ({
  children,
  restrictWidth = false,
  style,
  className,
  paddingSize = 'm',
  grow = true,
  direction = 'row',
  ...rest
}) => {
  const {
    widthClassName,
    newStyle
  } = setPropsForRestrictedPageWidth(restrictWidth, style);
  const classes = classNames('ouiPage', paddingSizeToClassNameMap[paddingSize], directionToClassNameMap[direction], {
    'ouiPage--grow': grow,
    [`ouiPage--${widthClassName}`]: widthClassName
  }, className);
  return <div className={classes} style={newStyle || style} {...rest}>
      {children}
    </div>;
};
OuiPage.propTypes = {
  /**
     * Adjust the padding.
     * When using this setting it's best to be consistent throughout all similar usages
     */
  paddingSize: PropTypes.any,

  /**
     * Adds `flex-grow: 1` to the whole page for stretching to fit vertically.
     * Must be wrapped inside a flexbox, preferrably with `min-height: 100vh`
     */
  grow: PropTypes.bool,

  /**
     * Changes the `flex-direction` property.
     * Flip to `column` when not including a sidebar.
     */
  direction: PropTypes.oneOf(["row", "column"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1000px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired])
};

try {
  OuiPage.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPage',
    methods: [],
    props: {
      paddingSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'Adjust the padding.\n' + "When using this setting it's best to be consistent throughout all similar usages",
        name: 'paddingSize',
        parent: {
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        },
        declarations: [{
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds `flex-grow: 1` to the whole page for stretching to fit vertically.\n' + 'Must be wrapped inside a flexbox, preferrably with `min-height: 100vh`',
        name: 'grow',
        parent: {
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        },
        declarations: [{
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      direction: {
        defaultValue: {
          value: 'row'
        },
        description: 'Changes the `flex-direction` property.\n' + 'Flip to `column` when not including a sidebar.',
        name: 'direction',
        parent: {
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        },
        declarations: [{
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      restrictWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1000px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | number | string'
        }
      }
    },
    extendedInterfaces: ['OuiPageProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}