/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { Browser } from '../../../services/browser';
import { keys } from '../../../services';
import { OuiFormControlLayout } from '../form_control_layout';
import { OuiValidatableControl } from '../validatable_control';
var isSearchSupported = false;
export class OuiFieldSearch extends Component {
  static defaultProps = {
    fullWidth: false,
    isLoading: false,
    incremental: false,
    compressed: false,
    isClearable: true
  };
  state = {
    value: this.props.value || (this.props.defaultValue ? `${this.props.defaultValue}` : '')
  };
  inputElement = null;
  cleanups = [];

  componentDidMount() {
    if (!this.inputElement) return;
    isSearchSupported = Browser.isEventSupported('search', this.inputElement);

    if (isSearchSupported) {
      const onSearch = event => {
        if (this.props.onSearch) {
          if (!event || !event.target || event.defaultPrevented) return;
          this.props.onSearch(event.target.value);
        }
      };

      this.inputElement.addEventListener('search', onSearch);
      this.cleanups.push(() => {
        if (!this.inputElement) return;
        this.inputElement.removeEventListener('search', onSearch);
      });
    }

    const onChange = event => {
      if (event.target && event.target.value !== this.state.value) {
        this.setState({
          value: event.target.value
        });

        if (this.props.onSearch) {
          this.props.onSearch(event.target.value);
        }
      }
    };

    this.inputElement.addEventListener('change', onChange);
  }

  onClear = () => {
    // clear the field's value
    // 1. React doesn't listen for `change` events, instead it maps `input` events to `change`
    // 2. React only fires the mapped `change` event if the element's value has changed
    // 3. An input's value is, in addition to other methods, tracked by intercepting element.value = '...'
    //
    // So we have to go below the element's value setter to avoid React intercepting it,
    // only then will React treat the value as different and fire its `change` event
    //
    // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
    const nativeInputValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
    const nativeInputValueSetter = nativeInputValue ? nativeInputValue.set : undefined;

    if (nativeInputValueSetter) {
      nativeInputValueSetter.call(this.inputElement, '');
    } // dispatch input event, with IE11 support/fallback


    let event;

    if ('Event' in window && typeof Event === 'function') {
      event = new Event('input', {
        bubbles: true,
        cancelable: false
      });
    } else {
      // IE11
      event = document.createEvent('Event');
      event.initEvent('input', true, false);
    }

    if (this.inputElement) {
      if (event) {
        this.inputElement.dispatchEvent(event);
      } // set focus on the search field


      this.inputElement.focus();
      this.inputElement.dispatchEvent(new Event('change'));
    }

    this.setState({
      value: ''
    });
    const {
      incremental,
      onSearch
    } = this.props;

    if (onSearch && incremental) {
      onSearch('');
    }
  };

  componentWillUnmount() {
    this.cleanups.forEach(cleanup => cleanup());
  }

  setRef = inputElement => {
    this.inputElement = inputElement;

    if (this.props.inputRef) {
      this.props.inputRef(inputElement);
    }
  };
  onKeyUp = (event, incremental, onSearch) => {
    this.setState({
      value: event.target.value
    });

    if (this.props.onKeyUp) {
      this.props.onKeyUp(event);

      if (event.defaultPrevented) {
        return;
      }
    }

    if (onSearch && (event.key !== keys.ENTER && incremental || event.key === keys.ENTER && !isSearchSupported)) {
      onSearch(event.target.value);
    }
  };

  render() {
    const {
      className,
      id,
      name,
      placeholder,
      isInvalid,
      fullWidth,
      isLoading,
      inputRef,
      incremental,
      compressed,
      onSearch,
      isClearable,
      append,
      prepend,
      ...rest
    } = this.props;
    let value = this.props.value;
    if (typeof this.props.value !== 'string') value = this.state.value;
    const classes = classNames('ouiFieldSearch', {
      'ouiFieldSearch--fullWidth': fullWidth,
      'ouiFieldSearch--compressed': compressed,
      'ouiFieldSearch--inGroup': prepend || append,
      'ouiFieldSearch-isLoading': isLoading,
      'ouiFieldSearch-isClearable': isClearable && value
    }, className);
    return <OuiFormControlLayout icon="search" fullWidth={fullWidth} isLoading={isLoading} clear={isClearable && value && !rest.readOnly && !rest.disabled ? {
      onClick: this.onClear
    } : undefined} compressed={compressed} append={append} prepend={prepend}>
        <OuiValidatableControl isInvalid={isInvalid}>
          <input type="search" id={id} name={name} placeholder={placeholder} className={classes} onKeyUp={e => this.onKeyUp(e, incremental, onSearch)} ref={this.setRef} {...rest} />
        </OuiValidatableControl>
      </OuiFormControlLayout>;
  }

}
OuiFieldSearch.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isInvalid: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,

  /**
     * Called when the user presses [Enter] OR on change if the incremental prop is `true`.
     * If you don't need the on[Enter] functionality, prefer using onChange
     */
  onSearch: PropTypes.func,

  /**
     * When `true` the search will be executed (that is, the `onSearch` will be called) as the
     * user types.
     */
  incremental: PropTypes.bool,

  /**
     * when `true` creates a shorter height input
     */
  compressed: PropTypes.bool,
  inputRef: PropTypes.func,

  /**
     * Shows a button that quickly clears any input
     */
  isClearable: PropTypes.bool,

  /**
     * Creates an input group with element(s) coming before input
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

  /**
     * Creates an input group with element(s) coming after input.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiFieldSearch.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFieldSearch',
    methods: [],
    props: {
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      placeholder: {
        defaultValue: null,
        description: '',
        name: 'placeholder',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onSearch: {
        defaultValue: null,
        description: 'Called when the user presses [Enter] OR on change if the incremental prop is `true`.\n' + "If you don't need the on[Enter] functionality, prefer using onChange",
        name: 'onSearch',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(value: string) => void'
        }
      },
      incremental: {
        defaultValue: {
          value: 'false'
        },
        description: 'When `true` the search will be executed (that is, the `onSearch` will be called) as the\n' + 'user types.',
        name: 'incremental',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(node: HTMLInputElement) => void'
        }
      },
      isClearable: {
        defaultValue: {
          value: 'true'
        },
        description: 'Shows a button that quickly clears any input',
        name: 'isClearable',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiFieldSearchProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}