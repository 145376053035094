function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

import PropTypes from "prop-types";

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */


import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { keys, DEFAULT_VISUALIZATION_COLOR, getSteppedGradient } from '../../../services';
import { OuiColorStopThumb } from './color_stop_thumb';
import { addStop, addDefinedStop, getPositionFromStop, getStopFromMouseLocation, isInvalid, removeStop } from './utils';
import { getChromaColor } from '../utils';
import { OuiI18n } from '../../i18n';
import { OuiScreenReaderOnly } from '../../accessibility';
import { OuiRangeHighlight } from '../../form/range/range_highlight';
import { OuiRangeTrack } from '../../form/range/range_track';
import { OuiRangeWrapper } from '../../form/range/range_wrapper'; // Because of how the thumbs are rendered in the popover, using ref results in an infinite loop.
// We'll instead use old fashioned namespaced DOM selectors to get references

var STOP_ATTR = 'ouiColorStop_';
var DEFAULT_MIN = 0;
var DEFAULT_MAX = 100;

function isTargetAThumb(target) {
  var element = target;
  var attr = element.getAttribute('data-index');
  return attr && attr.indexOf(STOP_ATTR) > -1;
}

function sortStops(colorStops) {
  return colorStops.map(function (el, index) {
    return _objectSpread(_objectSpread({}, el), {}, {
      id: index
    });
  }).sort(function (a, b) {
    return a.stop - b.stop;
  });
}

function getValidStops(colorStops) {
  return colorStops.map(function (el) {
    return el.stop;
  }).filter(function (stop) {
    return !isNaN(stop);
  });
}

function getRangeMin(colorStops, min) {
  var rangeMin = min || DEFAULT_MIN;
  var stops = getValidStops(colorStops);
  var first = Math.min.apply(Math, _toConsumableArray(stops)); // https://johnresig.com/blog/fast-javascript-maxmin/

  if (first < rangeMin) {
    if (stops.length === 1) {
      return first - DEFAULT_MIN;
    } else if (stops.length >= 2) {
      return first;
    }
  }

  return DEFAULT_MIN;
}

function getRangeMax(colorStops, max) {
  var rangeMax = max || DEFAULT_MAX;
  var stops = getValidStops(colorStops);
  var last = Math.max.apply(Math, _toConsumableArray(stops)); // https://johnresig.com/blog/fast-javascript-maxmin/

  if (last > rangeMax) {
    if (stops.length === 1) {
      return last + DEFAULT_MAX;
    } else if (stops.length >= 2) {
      return last;
    }
  }

  return DEFAULT_MAX;
}

export var OuiColorStops = ({
  addColor = DEFAULT_VISUALIZATION_COLOR,
  max,
  min,
  mode = 'default',
  colorStops,
  onChange,
  disabled,
  readOnly,
  compressed,
  fullWidth,
  className,
  label,
  stopType = 'gradient',
  stepNumber = 10,
  swatches,
  showAlpha = false,
  valueInputProps
}) => {
  const sortedStops = useMemo(() => sortStops(colorStops), [colorStops]);
  const rangeMax = useMemo(() => {
    const result = max != null ? max : getRangeMax(colorStops, max);
    const width = max != null ? 0 : Math.round(result * 0.05);
    return !isNaN(result) ? result + width : DEFAULT_MAX;
  }, [colorStops, max]);
  const rangeMin = useMemo(() => {
    const result = min != null ? min : getRangeMin(colorStops, min);
    const width = min != null ? 0 : Math.round(rangeMax * 0.05);
    return !isNaN(result) ? result - width : DEFAULT_MIN;
  }, [colorStops, min, rangeMax]);
  const [hasFocus, setHasFocus] = useState(false);
  const [focusedStopIndex, setFocusedStopIndex] = useState(null);
  const [openedStopId, setOpenedStopId] = useState(null);
  const [wrapperRef, setWrapperRef] = useState(null);
  const [addTargetPosition, setAddTargetPosition] = useState(0);
  const [isHoverDisabled, setIsHoverDisabled] = useState(false);
  const [focusStopOnUpdate, setFocusStopOnUpdate] = useState(null);
  const isNotInteractive = disabled || readOnly;
  const classes = classNames('ouiColorStops', {
    'ouiColorStops-isDragging': isHoverDisabled,
    'ouiColorStops-isDisabled': disabled,
    'ouiColorStops-isReadOnly': readOnly
  }, className);

  const getStopFromMouseLocationFn = location => {
    // Guard against `null` ref in usage
    return getStopFromMouseLocation(location, wrapperRef, min || rangeMin, max || rangeMax);
  };

  const getPositionFromStopFn = stop => {
    // Guard against `null` ref in usage
    return getPositionFromStop(stop, wrapperRef, min || rangeMin, max || rangeMax);
  };

  const handleOnChange = useCallback(colorStops => {
    onChange(colorStops, isInvalid(colorStops, showAlpha));
  }, [onChange, showAlpha]);
  const onFocusStop = useCallback(index => {
    if (disabled || !wrapperRef) return;
    const toFocus = wrapperRef.querySelector(`[data-index=${STOP_ATTR}${index}]`);

    if (toFocus) {
      setHasFocus(false);
      setFocusedStopIndex(index);
      toFocus.focus();
    }
  }, [disabled, wrapperRef]);
  useEffect(() => {
    if (focusStopOnUpdate !== null) {
      const toFocusIndex = sortedStops.map(el => el.stop).indexOf(focusStopOnUpdate);
      const toFocusId = toFocusIndex > -1 ? sortedStops[toFocusIndex].id : null;
      onFocusStop(toFocusIndex);
      setOpenedStopId(toFocusId);
      setFocusStopOnUpdate(null);
    }
  }, [sortedStops, onFocusStop, setFocusStopOnUpdate, focusStopOnUpdate]);
  const onFocusWrapper = useCallback(() => {
    setFocusedStopIndex(null);

    if (wrapperRef) {
      wrapperRef.focus();
    }
  }, [wrapperRef]);

  const setWrapperHasFocus = e => {
    if (e.target === wrapperRef) {
      setHasFocus(true);
    }
  };

  const removeWrapperFocus = () => {
    setHasFocus(false);
  };

  const onAdd = () => {
    const stops = sortedStops.map(({
      color,
      stop
    }) => {
      return {
        color,
        stop
      };
    });
    const newColorStops = addStop(stops, addColor, max || rangeMax);
    setFocusStopOnUpdate(newColorStops[colorStops.length].stop);
    handleOnChange(newColorStops);
  };

  const onRemove = useCallback(index => {
    const newColorStops = removeStop(colorStops, index);
    onFocusWrapper();
    handleOnChange(newColorStops);
  }, [colorStops, handleOnChange, onFocusWrapper]);

  const disableHover = () => {
    if (disabled) return;
    setIsHoverDisabled(true);
  };

  const enableHover = () => {
    if (disabled) return;
    setIsHoverDisabled(false);
  };

  const handleAddHover = e => {
    if (isNotInteractive || !wrapperRef) return;
    const stop = getStopFromMouseLocationFn({
      x: e.pageX,
      y: e.pageY
    });
    const position = getPositionFromStopFn(stop);
    setAddTargetPosition(position);
  };

  const handleAddClick = e => {
    if (isNotInteractive || isTargetAThumb(e.target) || !wrapperRef) return;
    const newStop = getStopFromMouseLocationFn({
      x: e.pageX,
      y: e.pageY
    });
    const newColorStops = addDefinedStop(colorStops, newStop, addColor);
    setFocusStopOnUpdate(newStop);
    handleOnChange(newColorStops);
  };

  const handleKeyDown = event => {
    if (disabled) return;

    switch (event.key) {
      case keys.ESCAPE:
        onFocusWrapper();
        break;

      case keys.ENTER:
        if (readOnly || !hasFocus) return;
        onAdd();
        break;

      case keys.BACKSPACE:
        if (readOnly || hasFocus || focusedStopIndex == null) return;

        if (isTargetAThumb(event.target)) {
          if (min == null && focusedStopIndex === 0 || max == null && focusedStopIndex === sortedStops.length - 1) {
            return;
          }

          const index = sortedStops[focusedStopIndex].id;
          onRemove(index);
        }

        break;

      case keys.ARROW_DOWN:
        if (event.target === wrapperRef || isTargetAThumb(event.target)) {
          event.preventDefault();

          if (focusedStopIndex == null) {
            onFocusStop(0);
          } else {
            const next = focusedStopIndex === sortedStops.length - 1 ? focusedStopIndex : focusedStopIndex + 1;
            onFocusStop(next);
          }
        }

        break;

      case keys.ARROW_UP:
        if (event.target === wrapperRef || isTargetAThumb(event.target)) {
          event.preventDefault();

          if (focusedStopIndex == null) {
            onFocusStop(0);
          } else {
            const next = focusedStopIndex === 0 ? focusedStopIndex : focusedStopIndex - 1;
            onFocusStop(next);
          }
        }

        break;
    }
  };

  const thumbs = useMemo(() => {
    const handleStopChange = (stop, id) => {
      const newColorStops = [...colorStops];
      newColorStops.splice(id, 1, stop);
      handleOnChange(newColorStops);
    };

    return sortedStops.map((colorStop, index) => <OuiColorStopThumb isRangeMin={min == null && colorStop.stop === rangeMin} isRangeMax={max == null && colorStop.stop === rangeMax} data-index={`${STOP_ATTR}${index}`} key={colorStop.id} globalMin={min || rangeMin} globalMax={max || rangeMax} min={min} max={max} localMin={index === 0 ? min || rangeMin : sortedStops[index - 1].stop + 1} localMax={index === sortedStops.length - 1 ? max || rangeMax : sortedStops[index + 1].stop - 1} stop={colorStop.stop} color={colorStop.color} onRemove={sortedStops.length > 1 ? () => onRemove(colorStop.id) : undefined} onChange={stop => handleStopChange(stop, colorStop.id)} onFocus={() => setFocusedStopIndex(index)} parentRef={wrapperRef} colorPickerMode={mode} colorPickerShowAlpha={showAlpha} colorPickerSwatches={swatches} disabled={disabled} readOnly={readOnly} aria-valuetext={`Stop: ${colorStop.stop}, Color: ${colorStop.color} (${index + 1} of ${colorStops.length})`} isPopoverOpen={colorStop.id === openedStopId} openPopover={() => {
      setOpenedStopId(colorStop.id);
    }} closePopover={() => {
      setOpenedStopId(null);
    }} valueInputProps={valueInputProps} />);
  }, [colorStops, disabled, handleOnChange, max, min, mode, onRemove, openedStopId, rangeMax, rangeMin, readOnly, showAlpha, sortedStops, swatches, wrapperRef, valueInputProps]);
  const positions = wrapperRef ? sortedStops.map(({
    stop
  }) => getPositionFromStopFn(stop)) : [];

  const gradientStop = (colorStop, index) => {
    const color = getChromaColor(colorStop.color, showAlpha);
    const rgba = color ? color.css() : 'currentColor';

    if (index === 0) {
      return `currentColor, currentColor ${positions[index]}%, ${rgba} ${positions[index]}%`;
    }

    return `${rgba} ${positions[index]}%`;
  };

  const fixedStop = (colorStop, index) => {
    if (index === sortedStops.length - 1) {
      return gradientStop(colorStop, index);
    } else {
      return `${gradientStop(colorStop, index)}, ${gradientStop(colorStop, index + 1)}`;
    }
  };

  let gradient = '';

  if (stopType === 'stepped' && positions.length > 0) {
    const trailingPercentage = positions[0];
    const endingPercentage = positions[positions.length - 1];
    const steppedColors = getSteppedGradient(colorStops, stepNumber);
    let steppedGradient = '';
    const percentage = (endingPercentage - trailingPercentage) / steppedColors.length;
    let percentageSteps = (endingPercentage - trailingPercentage) / steppedColors.length + trailingPercentage;
    steppedColors.forEach(color => {
      steppedGradient = steppedGradient.concat(`${color} ${percentageSteps - percentage}% ${percentageSteps}%, `);
      percentageSteps = percentageSteps + percentage;
    });
    steppedGradient = steppedGradient.substring(0, steppedGradient.length - 2);
    gradient = `linear-gradient(to right, currentColor ${trailingPercentage}%, ${steppedGradient})`;
  } else {
    const linearGradient = sortedStops.map(stopType === 'gradient' ? gradientStop : fixedStop);
    gradient = `linear-gradient(to right,${linearGradient})`;
  }

  return <OuiRangeWrapper data-test-subj="ouiColorStops" ref={setWrapperRef} className={classes} fullWidth={fullWidth} tabIndex={disabled ? -1 : 0} onMouseDown={disableHover} onMouseUp={enableHover} onMouseLeave={enableHover} onKeyDown={handleKeyDown} onFocus={setWrapperHasFocus} onBlur={removeWrapperFocus}>
      <OuiScreenReaderOnly>
        <p aria-live="polite">
          <OuiI18n values={{
          label,
          disabled: disabled ? 'Disabled.' : '',
          readOnly: readOnly ? 'Read-only.' : ''
        }} token="ouiColorStops.screenReaderAnnouncement" default="{label}: {readOnly} {disabled} Color stop picker. Each stop consists of a number and corresponding color value. Use the Down and Up arrow keys to select individual stops. Press the Enter key to create a new stop." />
        </p>
      </OuiScreenReaderOnly>
      <OuiRangeTrack min={min || rangeMin} max={max || rangeMax} compressed={compressed} disabled={disabled} step={1}>
        <OuiRangeHighlight className="ouiColorStops__highlight" min={min || rangeMin} max={max || rangeMax} lowerValue={min || rangeMin} upperValue={max || rangeMax} background={gradient} compressed={compressed} />
        <div data-test-subj="ouiColorStopsAdd" className={classNames('ouiColorStops__addContainer', {
        'ouiColorStops__addContainer-isDisabled': isHoverDisabled || disabled || readOnly
      })} onClick={handleAddClick} onMouseMove={handleAddHover}>
          <div className="ouiColorStops__addTarget" style={{
          left: `${addTargetPosition}%`
        }} />
        </div>
        {thumbs}
      </OuiRangeTrack>
    </OuiRangeWrapper>;
};
OuiColorStops.propTypes = {
  addColor: PropTypes.string,

  /**
     * An array of #ColorStop. The stops must be numbers in an ordered range.
     */
  colorStops: PropTypes.arrayOf(PropTypes.shape({
    stop: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  }).isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool,
  compressed: PropTypes.bool,
  className: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  label: PropTypes.string.isRequired,

  /**
     *  Specify the type of stops:
     *  `fixed`: individual color blocks.
     *  `gradient`: each color fades into the next.
     *  `stepped`: interpolation between colors with a fixed number of steps.
     */
  stopType: PropTypes.oneOf(["fixed", "gradient", "stepped"]),

  /**
     * Only works when `stopType="stepped"`
     */
  stepNumber: PropTypes.number,
  mode: PropTypes.oneOf(["default", "swatch", "picker", "secondaryInput"]),
  swatches: PropTypes.arrayOf(PropTypes.string.isRequired),
  showAlpha: PropTypes.bool,

  /**
     * Props passed to the value input field in the color stop popover.
     * Can be used to configure functionality like append or prepend.
     */
  valueInputProps: PropTypes.any,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiColorStops.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiColorStops',
    methods: [],
    props: {
      addColor: {
        defaultValue: {
          value: 'VISUALIZATION_COLORS[1]'
        },
        description: '',
        name: 'addColor',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      colorStops: {
        defaultValue: null,
        description: 'An array of #ColorStop. The stops must be numbers in an ordered range.',
        name: 'colorStops',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: true,
        type: {
          name: 'ColorStop[]'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: true,
        type: {
          name: '(stops?: ColorStop[], isInvalid?: boolean) => void'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: null,
        description: '',
        name: 'readOnly',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      invalid: {
        defaultValue: null,
        description: '',
        name: 'invalid',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      stopType: {
        defaultValue: {
          value: 'gradient'
        },
        description: 'Specify the type of stops:\n' + '`fixed`: individual color blocks.\n' + '`gradient`: each color fades into the next.\n' + '`stepped`: interpolation between colors with a fixed number of steps.',
        name: 'stopType',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "gradient" | "stepped"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"gradient"'
          }, {
            value: '"stepped"'
          }]
        }
      },
      stepNumber: {
        defaultValue: {
          value: '10'
        },
        description: 'Only works when `stopType="stepped"`',
        name: 'stepNumber',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      mode: {
        defaultValue: {
          value: 'default'
        },
        description: '',
        name: 'mode',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiColorPickerMode',
          value: [{
            value: '"default"'
          }, {
            value: '"swatch"'
          }, {
            value: '"picker"'
          }, {
            value: '"secondaryInput"'
          }]
        }
      },
      swatches: {
        defaultValue: null,
        description: '',
        name: 'swatches',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      showAlpha: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'showAlpha',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      valueInputProps: {
        defaultValue: null,
        description: 'Props passed to the value input field in the color stop popover.\n' + 'Can be used to configure functionality like append or prepend.',
        name: 'valueInputProps',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stops.tsx',
          name: 'OuiColorStopsProps'
        }],
        required: false,
        type: {
          name: 'Partial<Pick<OuiFieldNumberProps, "append" | "property" | "dir" | "form" | "slot" | "style" | "title" | "pattern" | "children" | "id" | "defaultChecked" | "defaultValue" | ... 274 more ... | "controlOnly">>'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiColorStopsProps', 'CommonProps']
  };
} catch (e) {}