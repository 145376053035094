/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import unified from 'unified';
import { defaultProcessingPlugins, defaultParsingPlugins } from './plugins/markdown_default_plugins';
export var OuiMarkdownFormat = ({
  children,
  parsingPluginList = defaultParsingPlugins,
  processingPluginList = defaultProcessingPlugins
}) => {
  const processor = useMemo(() => unified().use(parsingPluginList).use(processingPluginList), [parsingPluginList, processingPluginList]);
  const result = useMemo(() => {
    try {
      const processed = processor.processSync(children); // `.result` is intentionally `unknown` (https://github.com/vfile/vfile/pull/53)
      // cast to something expected.

      return processed.result ?? processed.contents;
    } catch (e) {
      return children;
    }
  }, [children, processor]);
  return <div className="ouiMarkdownFormat">{result}</div>;
};
OuiMarkdownFormat.propTypes = {
  children: PropTypes.string.isRequired,

  /** array of unified plugins to parse content into an AST */
  parsingPluginList: PropTypes.any,

  /** array of unified plugins to convert the AST into a ReactNode */
  processingPluginList: PropTypes.any
};

try {
  OuiMarkdownFormat.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiMarkdownFormat',
    methods: [],
    props: {
      parsingPluginList: {
        defaultValue: {
          value: 'getDefaultOuiMarkdownParsingPlugins()'
        },
        description: 'array of unified plugins to parse content into an AST',
        name: 'parsingPluginList',
        parent: {
          fileName: 'oui/src/components/markdown_editor/markdown_format.tsx',
          name: 'OuiMarkdownFormatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/markdown_editor/markdown_format.tsx',
          name: 'OuiMarkdownFormatProps'
        }],
        required: false,
        type: {
          name: 'PluggableList<Settings>'
        }
      },
      processingPluginList: {
        defaultValue: {
          value: 'getDefaultOuiMarkdownProcessingPlugins()'
        },
        description: 'array of unified plugins to convert the AST into a ReactNode',
        name: 'processingPluginList',
        parent: {
          fileName: 'oui/src/components/markdown_editor/markdown_format.tsx',
          name: 'OuiMarkdownFormatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/markdown_editor/markdown_format.tsx',
          name: 'OuiMarkdownFormatProps'
        }],
        required: false,
        type: {
          name: 'PluggableList<Settings>'
        }
      }
    },
    extendedInterfaces: ['OuiMarkdownFormatProps']
  };
} catch (e) {}