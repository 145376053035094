/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { Component } from 'react';
import PropTypes from "prop-types";

function isComponentBecomingVisible() {
  var prevHide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var nextHide = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return prevHide === true && nextHide === false;
}

export class OuiDelayHide extends Component {
  static defaultProps = {
    hide: false,
    minimumDuration: 1000
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const isBecomingVisible = isComponentBecomingVisible(prevState.hide, nextProps.hide);
    return {
      hide: nextProps.hide,
      countdownExpired: isBecomingVisible ? false : prevState.countdownExpired
    };
  }

  state = {
    hide: this.props.hide,
    countdownExpired: this.props.hide
  };

  componentDidMount() {
    // if the component begins visible start counting
    if (this.props.hide === false) {
      this.startCountdown();
    }
  }

  componentDidUpdate(prevProps) {
    const isBecomingVisible = isComponentBecomingVisible(prevProps.hide, this.props.hide);

    if (isBecomingVisible) {
      this.startCountdown();
    }
  }

  componentWillUnmount() {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
    }
  }

  startCountdown = () => {
    // only start the countdown if there is not one in progress
    if (this.timeoutId == null) {
      this.timeoutId = setTimeout(this.finishCountdown, // even though `minimumDuration` cannot be undefined, passing a strict number type to setTimeout makes TS interpret
      // it as a NodeJS.Timer instead of a number. The DOM lib defines the setTimeout call as taking `number | undefined`
      // so we cast minimumDuration to this type instead to force TS's cooperation
      this.props.minimumDuration);
    }
  };
  finishCountdown = () => {
    this.timeoutId = undefined;
    this.setState({
      countdownExpired: true
    });
  };

  render() {
    const shouldHideContent = this.props.hide === true && this.state.countdownExpired;
    return shouldHideContent ? null : this.props.render();
  }

}
OuiDelayHide.propTypes = {
  hide: PropTypes.bool.isRequired,
  minimumDuration: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};

try {
  OuiDelayHide.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiDelayHide',
    methods: [],
    props: {
      hide: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'hide',
        parent: {
          fileName: 'oui/src/components/delay_hide/delay_hide.tsx',
          name: 'OuiDelayHideProps'
        },
        declarations: [{
          fileName: 'oui/src/components/delay_hide/delay_hide.tsx',
          name: 'OuiDelayHideProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      minimumDuration: {
        defaultValue: {
          value: '1000'
        },
        description: '',
        name: 'minimumDuration',
        parent: {
          fileName: 'oui/src/components/delay_hide/delay_hide.tsx',
          name: 'OuiDelayHideProps'
        },
        declarations: [{
          fileName: 'oui/src/components/delay_hide/delay_hide.tsx',
          name: 'OuiDelayHideProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      render: {
        defaultValue: null,
        description: '',
        name: 'render',
        parent: {
          fileName: 'oui/src/components/delay_hide/delay_hide.tsx',
          name: 'OuiDelayHideProps'
        },
        declarations: [{
          fileName: 'oui/src/components/delay_hide/delay_hide.tsx',
          name: 'OuiDelayHideProps'
        }],
        required: true,
        type: {
          name: '() => ReactNode'
        }
      }
    },
    extendedInterfaces: ['OuiDelayHideProps']
  };
} catch (e) {}