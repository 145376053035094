/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import { OuiButtonIcon } from '../button';
import { useOuiI18n } from '../i18n';
import classNames from 'classnames';
export var OuiNotificationEventReadButton = ({
  id,
  isRead,
  onClick,
  eventName,
  ...rest
}) => {
  const classesReadState = classNames('ouiNotificationEventReadButton', {
    'ouiNotificationEventReadButton--isRead': isRead
  });
  const markAsReadAria = useOuiI18n('ouiNotificationEventReadButton.markAsReadAria', 'Mark {eventName} as read', {
    eventName
  });
  const markAsUnreadAria = useOuiI18n('ouiNotificationEventReadButton.markAsUnreadAria', 'Mark {eventName} as unread', {
    eventName
  });
  const markAsRead = useOuiI18n('ouiNotificationEventReadButton.markAsRead', 'Mark as read');
  const markAsUnread = useOuiI18n('ouiNotificationEventReadButton.markAsUnread', 'Mark as unread');
  const buttonAriaLabel = isRead ? markAsUnreadAria : markAsReadAria;
  const buttonTitle = isRead ? markAsUnread : markAsRead;
  return <OuiButtonIcon iconType="dot" aria-label={buttonAriaLabel} title={buttonTitle} className={classesReadState} onClick={onClick} data-test-subj={`${id}-notificationEventReadButton`} {...rest} />;
};
OuiNotificationEventReadButton.propTypes = {
  /**
     * Any of the named color palette options.
     * **`subdued` set to be DEPRECATED, use `text` instead**
     */
  color: PropTypes.oneOf(["accent", "danger", "ghost", "primary", "subdued", "success", "text", "warning"]),
  "aria-label": PropTypes.string,
  "aria-labelledby": PropTypes.string,

  /**
     * Size of the icon only.
     * This will not affect the overall size of the button
     */
  iconSize: PropTypes.oneOf(["original", "s", "m", "l", "xl", "xxl"]),

  /**
     * Sets the display style for matching other OuiButton types.
     * `base` is equivelant to a typical OuiButton
     * `fill` is equivelant to a filled OuiButton
     * `empty` (default) is equivelant to an OuiButtonEmpty
     */
  display: PropTypes.oneOf(["base", "empty", "fill"]),
  className: PropTypes.string,
  "data-test-subj": PropTypes.string,
  id: PropTypes.string.isRequired,

  /**
     * Shows an indicator of the read state of the event
     */
  isRead: PropTypes.bool.isRequired,

  /**
     * Applies an `onClick` handler to the `read` indicator.
     */
  onClick: PropTypes.func.isRequired,

  /**
     * A unique, human-friendly name for the event to be used in aria attributes (e.g. "alert-critical-01", "cloud-no-severity-12", etc..).
     */
  eventName: PropTypes.string.isRequired
};

try {
  OuiNotificationEventReadButton.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiNotificationEventReadButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      color: {
        defaultValue: null,
        description: 'Any of the named color palette options.\n' + '**`subdued` set to be DEPRECATED, use `text` instead**',
        name: 'color',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiButtonIconColor',
          value: [{
            value: '"text"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: '',
        name: 'aria-labelledby',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      display: {
        defaultValue: null,
        description: 'Sets the display style for matching other OuiButton types.\n' + '`base` is equivelant to a typical OuiButton\n' + '`fill` is equivelant to a filled OuiButton\n' + '`empty` (default) is equivelant to an OuiButtonEmpty',
        name: 'display',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"base" | "empty" | "fill"',
          value: [{
            value: '"base"'
          }, {
            value: '"empty"'
          }, {
            value: '"fill"'
          }]
        }
      },
      iconSize: {
        defaultValue: null,
        description: 'Size of the icon only.\n' + 'This will not affect the overall size of the button',
        name: 'iconSize',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl" | "original" | "xxl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"original"'
          }, {
            value: '"xxl"'
          }]
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/notification/notification_event_read_button.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      isRead: {
        defaultValue: null,
        description: 'Shows an indicator of the read state of the event',
        name: 'isRead',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/notification/notification_event_read_button.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: 'Applies an `onClick` handler to the `read` indicator.',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/notification/notification_event_read_button.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      eventName: {
        defaultValue: null,
        description: 'A unique, human-friendly name for the event to be used in aria attributes (e.g. "alert-critical-01", "cloud-no-severity-12", etc..).',
        name: 'eventName',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/notification/notification_event_read_button.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiButtonIconProps', 'CommonProps']
  };
} catch (e) {}