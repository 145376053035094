/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiPage } from './page';
import { OuiPageSideBar } from './page_side_bar';
import { OuiPageBody } from './page_body';
import { OuiPageHeader } from './page_header';
import { OuiPageContent, OuiPageContentBody } from './page_content';
import { OuiBottomBar } from '../bottom_bar';
import { useIsWithinBreakpoints } from '../../services';
import { OuiFlexGroup, OuiFlexItem } from '../flex';
export var TEMPLATES = ['default', 'centeredBody', 'centeredContent', 'empty'];
export var OuiPageTemplate = ({
  template = 'default',
  restrictWidth = true,
  grow = true,
  paddingSize = 'l',
  fullHeight,
  children,
  className,
  pageSideBar,
  pageSideBarProps,
  pageHeader,
  pageBodyProps,
  pageContentProps,
  pageContentBodyProps,
  bottomBar,
  bottomBarProps,
  minHeight = 460,
  ...rest
}) => {
  /**
   * Full height ~madness~ logic
   */
  const canFullHeight = useIsWithinBreakpoints(['m', 'l', 'xl']) && (template === 'default' || template === 'empty');
  const fullHeightClass = {
    'oui-fullHeight': fullHeight && canFullHeight
  };
  const yScrollClass = {
    'oui-yScroll': fullHeight && canFullHeight
  };

  if (canFullHeight && fullHeight) {
    // By using flex group it will also fix the negative margin issues for nested flex groups
    children = <OuiFlexGroup className="oui-fullHeight" gutterSize="none" direction="column" responsive={false}>
        <OuiFlexItem className={classNames({
        'oui-yScroll': fullHeight === true,
        'oui-fullHeight': fullHeight === 'noscroll'
      })} grow={true}>
          {children}
        </OuiFlexItem>
      </OuiFlexGroup>;
    pageBodyProps = { ...pageBodyProps,
      className: classNames(fullHeightClass, pageBodyProps?.className)
    };
    pageContentProps = { ...pageContentProps,
      className: classNames(yScrollClass, pageContentProps?.className)
    };
    pageContentBodyProps = { ...pageContentBodyProps,
      className: classNames(fullHeightClass, pageContentBodyProps?.className)
    };
  }

  const classes = classNames('ouiPageTemplate', fullHeightClass, className);
  const pageStyle = {
    minHeight,
    ...rest.style
  };
  /**
   * This seems very repetitious but it's the most readable, scalable, and maintainable
   */

  switch (template) {
    /**
     * CENTERED BODY
     * The panelled content is centered
     */
    case 'centeredBody':
      return pageSideBar ? <OuiPage className={classes} paddingSize="none" grow={grow} {...rest} style={pageStyle}>
          <OuiPageSideBar sticky paddingSize={paddingSize} {...pageSideBarProps}>
            {pageSideBar}
          </OuiPageSideBar>

          <OuiPageBody paddingSize={paddingSize} {...pageBodyProps}>
            {pageHeader && <OuiPageHeader restrictWidth={restrictWidth} {...pageHeader} />}
            <OuiPageContent verticalPosition="center" horizontalPosition="center" paddingSize={paddingSize} {...pageContentProps}>
              <OuiPageContentBody restrictWidth={restrictWidth} {...pageContentBodyProps}>
                {children}
              </OuiPageContentBody>
            </OuiPageContent>
          </OuiPageBody>
        </OuiPage> : <OuiPage className={classes} paddingSize={paddingSize} grow={grow} {...rest} style={pageStyle}>
          <OuiPageBody restrictWidth={restrictWidth} {...pageBodyProps}>
            {pageHeader && <OuiPageHeader paddingSize="none" restrictWidth={false} bottomBorder {...pageHeader} />}
            {
            /* Extra page body to get the correct alignment and padding of the centered OuiPageContent */
          }
            <OuiPageBody>
              <OuiPageContent verticalPosition="center" horizontalPosition="center" paddingSize={paddingSize} {...pageContentProps}>
                <OuiPageContentBody paddingSize="none" restrictWidth={restrictWidth} {...pageContentBodyProps}>
                  {children}
                </OuiPageContentBody>
              </OuiPageContent>
            </OuiPageBody>
          </OuiPageBody>
        </OuiPage>;

    /**
     * CENTERED CONTENT
     * The content inside the panel is centered
     */

    case 'centeredContent':
      return pageSideBar ? <OuiPage className={classes} paddingSize="none" grow={grow} {...rest} style={pageStyle}>
          <OuiPageSideBar sticky paddingSize={paddingSize} {...pageSideBarProps}>
            {pageSideBar}
          </OuiPageSideBar>

          <OuiPageBody panelled paddingSize={paddingSize} {...pageBodyProps}>
            {pageHeader && <OuiPageHeader restrictWidth={restrictWidth} {...pageHeader} />}
            <OuiPageContent verticalPosition="center" horizontalPosition="center" hasShadow={false} color="subdued" paddingSize={paddingSize} {...pageContentProps}>
              <OuiPageContentBody restrictWidth={restrictWidth} {...pageContentBodyProps}>
                {children}
              </OuiPageContentBody>
            </OuiPageContent>
          </OuiPageBody>
        </OuiPage> : <OuiPage className={classes} paddingSize="none" grow={grow} {...rest} style={pageStyle}>
          <OuiPageBody {...pageBodyProps}>
            {pageHeader && <OuiPageHeader paddingSize={paddingSize} restrictWidth={restrictWidth} {...pageHeader} />}
            {
            /* Extra page content to get the correct alignment and padding of the centered OuiPageContent */
          }
            <OuiPageContent role={null} borderRadius="none" hasShadow={false} paddingSize={paddingSize} style={{
            display: 'flex'
          }}>
              <OuiPageContent verticalPosition="center" horizontalPosition="center" hasShadow={false} color="subdued" paddingSize={paddingSize} {...pageContentProps}>
                <OuiPageContentBody restrictWidth={restrictWidth} {...pageContentBodyProps}>
                  {children}
                </OuiPageContentBody>
              </OuiPageContent>
            </OuiPageContent>
          </OuiPageBody>
        </OuiPage>;

    /**
     * EMPTY
     * No panelling at all
     */

    case 'empty':
      return pageSideBar ? <OuiPage className={classes} paddingSize="none" grow={grow} {...rest} style={pageStyle}>
          <OuiPageSideBar sticky paddingSize={paddingSize} {...pageSideBarProps}>
            {pageSideBar}
          </OuiPageSideBar>

          <OuiPageBody paddingSize={paddingSize} {...pageBodyProps}>
            {pageHeader && <OuiPageHeader restrictWidth={restrictWidth} {...pageHeader} />}
            <OuiPageContent hasBorder={false} hasShadow={false} paddingSize={'none'} color={'transparent'} borderRadius={'none'} {...pageContentProps}>
              <OuiPageContentBody restrictWidth={restrictWidth} {...pageContentBodyProps}>
                {children}
              </OuiPageContentBody>
            </OuiPageContent>
          </OuiPageBody>
        </OuiPage> : <OuiPage className={classes} paddingSize={paddingSize} grow={grow} {...rest} style={pageStyle}>
          <OuiPageBody restrictWidth={restrictWidth} {...pageBodyProps}>
            {pageHeader && <OuiPageHeader paddingSize="none" restrictWidth={false} bottomBorder {...pageHeader} />}
            <OuiPageContent hasBorder={false} hasShadow={false} paddingSize={'none'} color={'transparent'} borderRadius={'none'} {...pageContentProps}>
              <OuiPageContentBody paddingSize="none" {...pageContentBodyProps}>
                {children}
              </OuiPageContentBody>
            </OuiPageContent>
          </OuiPageBody>
        </OuiPage>;

    /**
     * DEFAULT
     * Typical layout with nothing "centered"
     */

    default:
      // Only the default template can display a bottom bar
      const bottomBarNode = bottomBar ? <OuiBottomBar paddingSize={paddingSize} position={canFullHeight && fullHeight ? 'static' : 'sticky'} // Using uknown here because of the possible conflict with overriding props and position `sticky`
      {...bottomBarProps}>
          {
          /* Wrapping the contents with OuiPageContentBody allows us to match the restrictWidth to keep the contents aligned */
        }
          <OuiPageContentBody paddingSize={'none'} restrictWidth={restrictWidth}>
            {bottomBar}
          </OuiPageContentBody>
        </OuiBottomBar> : undefined;
      return pageSideBar ? <OuiPage className={classes} paddingSize="none" grow={grow} {...rest} style={pageStyle}>
          <OuiPageSideBar sticky paddingSize={paddingSize} {...pageSideBarProps}>
            {pageSideBar}
          </OuiPageSideBar>

          {
          /* The extra PageBody is to accommodate the bottom bar stretching to both sides */
        }
          <OuiPageBody panelled paddingSize="none" {...pageBodyProps}>
            <OuiPageBody component="div" paddingSize={paddingSize} className={pageBodyProps?.className}>
              {pageHeader && <OuiPageHeader bottomBorder restrictWidth={restrictWidth} {...pageHeader} />}
              <OuiPageContent hasShadow={false} hasBorder={false} color={'transparent'} borderRadius={'none'} paddingSize="none" {...pageContentProps}>
                <OuiPageContentBody restrictWidth={restrictWidth} {...pageContentBodyProps}>
                  {children}
                </OuiPageContentBody>
              </OuiPageContent>
            </OuiPageBody>
            {bottomBarNode}
          </OuiPageBody>
        </OuiPage> : <OuiPage className={classes} paddingSize="none" grow={grow} {...rest} style={pageStyle}>
          <OuiPageBody {...pageBodyProps}>
            {pageHeader && <OuiPageHeader restrictWidth={restrictWidth} paddingSize={paddingSize} {...pageHeader} />}
            <OuiPageContent hasBorder={pageHeader === undefined ? false : undefined} hasShadow={false} paddingSize={'none'} color={'plain'} borderRadius={'none'} {...pageContentProps}>
              <OuiPageContentBody restrictWidth={restrictWidth} paddingSize={paddingSize} {...pageContentBodyProps}>
                {children}
              </OuiPageContentBody>
            </OuiPageContent>
            {bottomBarNode}
          </OuiPageBody>
        </OuiPage>;
  }
};
OuiPageTemplate.propTypes = {
  /**
     * Choose between 3 types of templates.
     * `default`: Typical layout with nothing centered
     * `centeredBody`: The panelled content is centered
     * `centeredContent`: The content inside the panel is centered
     * `empty`: Removes the panneling of the page content
     */
  template: PropTypes.any,

  /**
     * Padding size will not get applied to the over-arching #OuiPage,
     * but will propogate through all the components to keep them in sync
     */
  paddingSize: PropTypes.any,

  /**
     * Optionally include #OuiPageSideBar content.
     * The inclusion of this will affect the whole layout
     */
  pageSideBar: PropTypes.node,

  /**
     * Gets passed along to the #OuiPageSideBar component
     */
  pageSideBarProps: PropTypes.shape({
    /**
       * Adds `position: sticky` and affords for any fixed position headers
       */
    sticky: PropTypes.bool,

    /**
       * Adds padding around the children
       */
    paddingSize: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  }),

  /**
     * Optionally include an #OuiPageHeader by passing an object of its props
     */
  pageHeader: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Wrapped in an `H1` so choose appropriately.
       * A simple string is best
       */
    pageTitle: PropTypes.node,

    /**
       * In-app navigation presented as large borderless tabs.
       * Accepts an array of `OuiTab` objects;
       * HELP: This is evaluating to `any[]` in the props table
       */
    tabs: PropTypes.arrayOf(PropTypes.shape({
      /**
         * Visible text of the tab
         */
      label: PropTypes.node.isRequired
    }).isRequired),

    /**
       * Any extras to apply to the outer tabs container.
       * Extends `OuiTabs`
       */
    tabsProps: PropTypes.shape({
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,

      /**
           * ReactNode to render as this component's content
           */
      children: PropTypes.node
    }),

    /**
         * Position is dependent on existing with a `pageTitle` or `tabs`
         * Automatically get wrapped in a single paragraph tag inside an OuiText block
         */
    description: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),

    /**
         * Set to false if you don't want the children to stack at small screen sizes.
         * Set to `reverse` to display the right side content first for the sack of hierarchy (like global time)
         */
    responsive: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["reverse"])]),

    /**
         * Vertical alignment of the left and right side content;
         * Default is `middle` for custom content, but `top` for when `pageTitle` or `tabs` are included
         */
    alignItems: PropTypes.any,

    /**
         * Pass custom an array of content to this side usually up to 3 buttons.
         * The first button should be primary, usually with `fill` and will be visually displayed as the last item,
         * but first in the tab order
         */
    rightSideItems: PropTypes.arrayOf(PropTypes.node.isRequired),

    /**
         * Additional OuiFlexGroup props to pass to the container of the `rightSideItems`
         */
    rightSideGroupProps: PropTypes.any,

    /**
         * Custom children will be rendered before the `tabs` unless no `pageTitle` is present, then it will be the last item
         */
    children: PropTypes.node,

    /**
       * Sets the max-width of the page,
       * set to `true` to use the default size of `1000px`,
       * set to `false` to not restrict the width,
       * set to a number for a custom width in px,
       * set to a string for a custom width in custom measurement.
       */
    restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),

    /**
         * Adjust the padding.
         * When using this setting it's best to be consistent throughout all similar usages
         */
    paddingSize: PropTypes.any,

    /**
         * Adds a bottom border to separate it from the content after
         */
    bottomBorder: PropTypes.bool
  }),

  /**
     * Gets passed along to the #OuiPageBody component
     */
  pageBodyProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Sets the max-width of the page,
       * set to `true` to use the default size of `1000px`,
       * set to `false` to not restrict the width,
       * set to a number for a custom width in px,
       * set to a string for a custom width in custom measurement.
       */
    restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),

    /**
         * Sets the HTML element for `OuiPageBody`.
         */
    component: PropTypes.any,

    /**
         * Uses an OuiPanel as the main component instead of a plain div
         */
    panelled: PropTypes.bool,

    /**
         * Extends any extra OuiPanel props if `panelled=true`
         */
    panelProps: PropTypes.any,

    /**
         * Adjusts the padding
         */
    paddingSize: PropTypes.any
  }),

  /**
     * Gets passed along to the #OuiPageContent component
     */
  pageContentProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Adds a medium shadow to the panel;
       * Only works when `color="plain"`
       */

    /**
       * Adds a medium shadow to the panel;
       * Only works when `color="plain"`
       */
    hasShadow: PropTypes.bool,

    /**
       * Adds a slight 1px border on all edges.
       * Only works when `color="plain | transparent"`
       * Default is `undefined` and will default to that theme's panel style
       */

    /**
       * Adds a slight 1px border on all edges.
       * Only works when `color="plain | transparent"`
       * Default is `undefined` and will default to that theme's panel style
       */
    hasBorder: PropTypes.bool,

    /**
       * Padding for all four sides
       */

    /**
       * Padding for all four sides
       */
    paddingSize: PropTypes.any,

    /**
       * Corner border radius
       */

    /**
       * Corner border radius
       */
    borderRadius: PropTypes.any,

    /**
       * When true the panel will grow in height to match `OuiFlexItem`
       */

    /**
       * When true the panel will grow in height to match `OuiFlexItem`
       */
    grow: PropTypes.bool,
    panelRef: PropTypes.any,

    /**
       * Background color of the panel;
       * Usually a lightened form of the brand colors
       */

    /**
       * Background color of the panel;
       * Usually a lightened form of the brand colors
       */
    color: PropTypes.any,
    element: PropTypes.oneOf(["div"]),

    /**
         * **DEPRECATED: use `paddingSize` instead.**
         */
    panelPaddingSize: PropTypes.any,
    verticalPosition: PropTypes.oneOf(["center"]),
    horizontalPosition: PropTypes.oneOf(["center"]),

    /**
         * There should only be one OuiPageContent per page and should contain the main contents.
         * If this is untrue, set role = `null`, or change it to match your needed aria role
         */
    role: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.oneOf([null])])
  }),

  /**
     * Gets passed along to the #OuiPageContentBody component
     */
  pageContentBodyProps: PropTypes.shape({
    /**
       * Adjust the padding.
       * When using this setting it's best to be consistent throughout all similar usages
       */
    paddingSize: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Sets the max-width of the page,
       * set to `true` to use the default size of `1000px`,
       * set to `false` to not restrict the width,
       * set to a number for a custom width in px,
       * set to a string for a custom width in custom measurement.
       */
    restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired])
  }),

  /**
     * Adds contents inside of an OuiBottomBar.
     * Only works when `template = 'default'`
     */
  bottomBar: PropTypes.any,

  /**
     * Gets passed along to the #OuiBottomBar component if `bottomBar` has contents
     */
  bottomBarProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
         * How to position the bottom bar against its parent.
         */
    position: PropTypes.oneOfType([PropTypes.oneOf(["fixed"]), PropTypes.oneOf(["static", "sticky"]).isRequired]),

    /**
         * Whether to wrap in an OuiPortal which appends the component to the body element.
         * Only works if `position` is `fixed`.
         */
    usePortal: PropTypes.bool,

    /**
         * Whether the component should apply padding on the document body element to afford for its own displacement height.
         * Only works if `usePortal` is true and `position` is `fixed`.
         */
    affordForDisplacement: PropTypes.bool,

    /**
         * Padding applied to the bar. Default is 'm'.
         */
    paddingSize: PropTypes.oneOf(["none", "s", "m", "l"]),

    /**
         * Optional class applied to the body element on mount.
         */
    bodyClassName: PropTypes.string,

    /**
         * Customize the screen reader heading that helps users find this control. Default is 'Page level controls'.
         */
    landmarkHeading: PropTypes.string,

    /**
         * Starting vertical position when `fixed` position.
         * Offset from the top of the window when `sticky` position.
         * Has no affect on `static` positions.
         */
    top: PropTypes.any,

    /**
         * Ending horizontal position when `fixed` position.
         * Has no affect on `static` or `sticky` positions.
         */
    right: PropTypes.any,

    /**
         * Starting vertical position when `fixed` position.
         * Offset from the bottom of the window when `sticky` position.
         * Has no affect on `static` positions.
         */
    bottom: PropTypes.any,

    /**
         * Starting horizontal position when `fixed` position.
         * Has no affect on `static` or `sticky` positions.
         */
    left: PropTypes.any
  }),

  /**
     * Stretches or restricts the height to 100% of the parent;
     * `true`: scrolls the OuiPageContentBody;
     * `noscroll`: removes all scroll ability;
     * Only works when `template = 'default | empty'` and breakpoint is `m` and above
     */
  fullHeight: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["noscroll"])]),

  /**
     * Minimum height in which to enforce scrolling
     */
  minHeight: PropTypes.any
};

try {
  OuiPageTemplate.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPageTemplate',
    methods: [],
    props: {
      direction: {
        defaultValue: null,
        description: 'Changes the `flex-direction` property.\n' + 'Flip to `column` when not including a sidebar.',
        name: 'direction',
        parent: {
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        },
        declarations: [{
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds `flex-grow: 1` to the whole page for stretching to fit vertically.\n' + 'Must be wrapped inside a flexbox, preferrably with `min-height: 100vh`',
        name: 'grow',
        parent: {
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        },
        declarations: [{
          fileName: 'oui/src/components/page/page.tsx',
          name: 'OuiPageProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      restrictWidth: {
        defaultValue: {
          value: 'true'
        },
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1000px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | number | string'
        }
      },
      template: {
        defaultValue: {
          value: 'default'
        },
        description: 'Choose between 3 types of templates.\n' + '`default`: Typical layout with nothing centered\n' + '`centeredBody`: The panelled content is centered\n' + '`centeredContent`: The content inside the panel is centered\n' + '`empty`: Removes the panneling of the page content',
        name: 'template',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"default" | "empty" | "centeredBody" | "centeredContent"',
          value: [{
            value: '"default"'
          }, {
            value: '"empty"'
          }, {
            value: '"centeredBody"'
          }, {
            value: '"centeredContent"'
          }]
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'l'
        },
        description: 'Padding size will not get applied to the over-arching #OuiPage,\n' + 'but will propogate through all the components to keep them in sync',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      pageSideBar: {
        defaultValue: null,
        description: 'Optionally include #OuiPageSideBar content.\n' + 'The inclusion of this will affect the whole layout',
        name: 'pageSideBar',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      pageSideBarProps: {
        defaultValue: null,
        description: 'Gets passed along to the #OuiPageSideBar component',
        name: 'pageSideBarProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiPageSideBarProps'
        }
      },
      pageHeader: {
        defaultValue: null,
        description: 'Optionally include an #OuiPageHeader by passing an object of its props',
        name: 'pageHeader',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLElement> & HTMLAttributes<HTMLDivElement> & OuiPageHeaderContentTitle & ... 4 more ... & { ...; }'
        }
      },
      pageBodyProps: {
        defaultValue: null,
        description: 'Gets passed along to the #OuiPageBody component',
        name: 'pageBodyProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & ClassAttributes<HTMLElement> & HTMLAttributes<HTMLElement> & _OuiPageRestrictWidth & { ...; }'
        }
      },
      pageContentProps: {
        defaultValue: null,
        description: 'Gets passed along to the #OuiPageContent component',
        name: 'pageContentProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiPageContentProps'
        }
      },
      pageContentBodyProps: {
        defaultValue: null,
        description: 'Gets passed along to the #OuiPageContentBody component',
        name: 'pageContentBodyProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiPageContentBodyProps'
        }
      },
      bottomBar: {
        defaultValue: null,
        description: 'Adds contents inside of an OuiBottomBar.\n' + "Only works when `template = 'default'`",
        name: 'bottomBar',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      bottomBarProps: {
        defaultValue: null,
        description: 'Gets passed along to the #OuiBottomBar component if `bottomBar` has contents',
        name: 'bottomBarProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiBottomBarProps'
        }
      },
      fullHeight: {
        defaultValue: null,
        description: 'Stretches or restricts the height to 100% of the parent;\n' + '`true`: scrolls the OuiPageContentBody;\n' + '`noscroll`: removes all scroll ability;\n' + "Only works when `template = 'default | empty'` and breakpoint is `m` and above",
        name: 'fullHeight',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "noscroll"'
        }
      },
      minHeight: {
        defaultValue: {
          value: '460'
        },
        description: 'Minimum height in which to enforce scrolling',
        name: 'minHeight',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/page/page_template.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      }
    },
    extendedInterfaces: ['OuiPageProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}