/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
import { OuiFlexGroup } from '../flex';
var layoutToClassNameMap = {
  vertical: 'ouiFacetGroup--vertical',
  horizontal: 'ouiFacetGroup--horizontal'
};
export var LAYOUTS = keysOf(layoutToClassNameMap);
var gutterSizeToClassNameMap = {
  none: 'ouiFacetGroup--gutterNone',
  s: 'ouiFacetGroup--gutterSmall',
  m: 'ouiFacetGroup--gutterMedium',
  l: 'ouiFacetGroup--gutterLarge'
};
export var GUTTER_SIZES = keysOf(gutterSizeToClassNameMap);
export var OuiFacetGroup = ({
  children,
  className,
  layout = 'vertical',
  gutterSize = 'm',
  ...rest
}) => {
  const classes = classNames('ouiFacetGroup', layoutToClassNameMap[layout], gutterSizeToClassNameMap[gutterSize], className);
  const direction = layout === 'vertical' ? 'column' : 'row';
  const wrap = layout === 'vertical' ? false : true;
  return <OuiFlexGroup className={classes} direction={direction} wrap={wrap} gutterSize="none" {...rest}>
      {children}
    </OuiFlexGroup>;
};
OuiFacetGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Vertically in a column, or horizontally in one wrapping line
       */
  layout: PropTypes.oneOf(["vertical", "horizontal"]),

  /**
       * Distance between facet buttons.
       * Horizontal layout always adds more distance horizontally between buttons.
       */
  gutterSize: PropTypes.oneOf(["none", "s", "m", "l"])
};

try {
  OuiFacetGroup.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFacetGroup',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      layout: {
        defaultValue: {
          value: 'vertical'
        },
        description: 'Vertically in a column, or horizontally in one wrapping line',
        name: 'layout',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/facet/facet_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"horizontal" | "vertical"',
          value: [{
            value: '"horizontal"'
          }, {
            value: '"vertical"'
          }]
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'Distance between facet buttons.\n' + 'Horizontal layout always adds more distance horizontally between buttons.',
        name: 'gutterSize',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/facet/facet_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}