/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
var borderToClassNameMap = {
  left: 'ouiHeaderSectionItem--borderLeft',
  right: 'ouiHeaderSectionItem--borderRight',
  none: undefined
};
export var OuiHeaderSectionItem = ({
  border = 'left',
  children,
  className,
  ...rest
}) => {
  const classes = classNames('ouiHeaderSectionItem', borderToClassNameMap[border], className);
  return <div className={classes} {...rest}>
      {children}
    </div>;
};
OuiHeaderSectionItem.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Side to display a short border on.
     */
  border: PropTypes.oneOf(["left", "right", "none"]),

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node
};

try {
  OuiHeaderSectionItem.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiHeaderSectionItem',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      border: {
        defaultValue: {
          value: 'left'
        },
        description: 'Side to display a short border on.',
        name: 'border',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_section/header_section_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "none"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"none"'
          }]
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_section/header_section_item.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}