/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';
import { OuiNotificationBadge } from '../../badge/notification_badge/badge_notification';
import { OuiIcon } from '../../icon';
import { OuiButtonEmpty } from '../../button';
import { OuiHideFor, OuiShowFor } from '../../responsive';
export var OuiHeaderSectionItemButton = forwardRef(({
  children,
  className,
  notification,
  notificationColor = 'accent',
  ...rest
},
/**
 * Allows for animating with .ouiAnimate()
 */
ref) => {
  const buttonRef = useRef(null);
  const animationTargetRef = useRef(null);
  useImperativeHandle(ref, () => {
    buttonRef.current.ouiAnimate = () => {
      const keyframes = [{
        transform: 'rotate(0)',
        offset: 0,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(30deg)',
        offset: 0.01,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-28deg)',
        offset: 0.03,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(34deg)',
        offset: 0.05,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-32deg)',
        offset: 0.07,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(30deg)',
        offset: 0.09,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-28deg)',
        offset: 0.11,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(26deg)',
        offset: 0.13,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-24deg)',
        offset: 0.15,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(22deg)',
        offset: 0.17,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-20deg)',
        offset: 0.19,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(18deg)',
        offset: 0.21,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-16deg)',
        offset: 0.23,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(14deg)',
        offset: 0.25,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-12deg)',
        offset: 0.27,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(10deg)',
        offset: 0.29,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-8deg)',
        offset: 0.31,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(6deg)',
        offset: 0.33,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-4deg)',
        offset: 0.35,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(2deg)',
        offset: 0.37,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(-1deg)',
        offset: 0.39,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(1deg)',
        offset: 0.41,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(0)',
        offset: 0.43,
        easing: 'ease-in-out'
      }, {
        transform: 'rotate(0)',
        offset: 1,
        easing: 'ease-in-out'
      }];
      animationTargetRef.current?.animate(keyframes, {
        duration: 5000
      });
    };

    return buttonRef.current;
  }, []);
  const classes = classNames('ouiHeaderSectionItemButton', className);
  const animationClasses = classNames(['ouiHeaderSectionItemButton__content']);
  const notificationDot = <OuiIcon className="ouiHeaderSectionItemButton__notification ouiHeaderSectionItemButton__notification--dot" color={notificationColor} type="dot" size="l" />;
  let buttonNotification;

  if (notification === true) {
    buttonNotification = notificationDot;
  } else if (notification) {
    buttonNotification = <>
          <OuiHideFor sizes={['xs']}>
            <OuiNotificationBadge className="ouiHeaderSectionItemButton__notification ouiHeaderSectionItemButton__notification--badge" color={notificationColor}>
              {notification}
            </OuiNotificationBadge>
          </OuiHideFor>
          <OuiShowFor sizes={['xs']}>{notificationDot}</OuiShowFor>
        </>;
  }

  return <OuiButtonEmpty className={classes} color="text" buttonRef={buttonRef} {...rest}>
        <span ref={animationTargetRef} className={animationClasses}>
          {children}
        </span>
        {buttonNotification}
      </OuiButtonEmpty>;
});
OuiHeaderSectionItemButton.displayName = 'OuiHeaderSectionItemButton';

try {
  OuiHeaderSectionItemButton.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiHeaderSectionItemButton',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: 'Any of our named colors',
        name: 'color',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiButtonEmptyColor',
          value: [{
            value: '"text"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      size: {
        defaultValue: null,
        description: '',
        name: 'size',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "l"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"l"'
          }]
        }
      },
      flush: {
        defaultValue: null,
        description: 'Ensure the text of the button sits flush to the left, right, or both sides of its container',
        name: 'flush',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "both"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"both"'
          }]
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '`disabled` is also allowed',
        name: 'isDisabled',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Force disables the button and changes the icon to a loading spinner',
        name: 'isLoading',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      rel: {
        defaultValue: null,
        description: '',
        name: 'rel',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'ButtonHTMLAttributes'
        }, {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"submit"'
          }]
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement | HTMLAnchorElement>'
        }
      },
      contentProps: {
        defaultValue: null,
        description: "Object of props passed to the <span/> wrapping the button's content",
        name: 'contentProps',
        parent: {
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonOuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'OuiButtonContentType'
        }
      },
      iconType: {
        defaultValue: null,
        description: 'Any `type` accepted by OuiIcon',
        name: 'iconType',
        parent: {
          fileName: 'oui/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      iconSide: {
        defaultValue: null,
        description: 'Can only be one side `left` or `right`',
        name: 'iconSide',
        parent: {
          fileName: 'oui/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToastSide',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      textProps: {
        defaultValue: null,
        description: "Object of props passed to the <span/> wrapping the content's text/children only (not icon)",
        name: 'textProps',
        parent: {
          fileName: 'oui/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        }],
        required: false,
        type: {
          name: "HTMLAttributes<HTMLSpanElement> & CommonProps & { ref?: Ref<HTMLSpanElement>; 'data-text'?: string; }"
        }
      },
      iconSize: {
        defaultValue: null,
        description: '',
        name: 'iconSize',
        parent: {
          fileName: 'oui/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: false,
        type: {
          name: '((event: MouseEvent<HTMLButtonElement, MouseEvent>) => void) | ((event: MouseEvent<HTMLAnchorElement, MouseEvent>) => void)'
        }
      },
      notification: {
        defaultValue: null,
        description: 'Inserts the node into a OuiBadgeNotification and places it appropriately against the button.\n' + 'Or pass `true` to render a simple dot',
        name: 'notification',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_section/header_section_item_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      notificationColor: {
        defaultValue: {
          value: 'accent'
        },
        description: 'Changes the color of the notification background',
        name: 'notificationColor',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_section/header_section_item_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"accent" | "subdued"',
          value: [{
            value: '"accent"'
          }, {
            value: '"subdued"'
          }]
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement & { ouiAnimate: () => void; }>'
        }
      }
    },
    extendedInterfaces: ['AnchorHTMLAttributes', 'CommonOuiButtonEmptyProps', 'OuiButtonContentProps', 'CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}