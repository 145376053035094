/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Fragment, createElement } from 'react';
import PropTypes from "prop-types";
import { keysOf } from '../common';
import classNames from 'classnames';
import { OuiText } from '../text';
import { OuiTitle } from '../title/title';
import { OuiScreenReaderOnly } from '../accessibility';
import { OuiI18n } from '../i18n';
var colorToClassNameMap = {
  default: null,
  subdued: 'ouiStat__title--subdued',
  primary: 'ouiStat__title--primary',
  secondary: 'ouiStat__title--secondary',
  success: 'ouiStat__title--success',
  danger: 'ouiStat__title--danger',
  accent: 'ouiStat__title--accent'
};
export var COLORS = keysOf(colorToClassNameMap);
var textAlignToClassNameMap = {
  left: 'ouiStat--leftAligned',
  center: 'ouiStat--centerAligned',
  right: 'ouiStat--rightAligned'
};
export var isColorClass = function isColorClass(input) {
  return colorToClassNameMap.hasOwnProperty(input);
};
export var ALIGNMENTS = keysOf(textAlignToClassNameMap);
export var OuiStat = ({
  children,
  className,
  description,
  isLoading = false,
  reverse = false,
  textAlign = 'left',
  title,
  titleColor = 'default',
  titleSize = 'l',
  titleElement = 'p',
  descriptionElement = 'p',
  ...rest
}) => {
  const classes = classNames('ouiStat', textAlignToClassNameMap[textAlign], className);
  const titleClasses = classNames('ouiStat__title', isColorClass(titleColor) ? colorToClassNameMap[titleColor] : null, {
    'ouiStat__title-isLoading': isLoading
  });
  const commonProps = {
    'aria-hidden': true
  };
  const descriptionDisplay = <OuiText size="s" className="ouiStat__description">
      {createElement(descriptionElement, commonProps, description)}
    </OuiText>;
  const titlePropsWithColor = {
    'aria-hidden': true,
    style: {
      color: `${titleColor}`
    }
  };
  const titleChildren = isLoading ? '--' : title;
  const titleDisplay = isColorClass(titleColor) ? <OuiTitle size={titleSize} className={titleClasses}>
      {createElement(titleElement, commonProps, titleChildren)}
    </OuiTitle> : <OuiTitle size={titleSize} className={titleClasses}>
      {createElement(titleElement, titlePropsWithColor, titleChildren)}
    </OuiTitle>;
  const screenReader = <OuiScreenReaderOnly>
      <p>
        {isLoading ? <OuiI18n token="ouiStat.loadingText" default="Statistic is loading" /> : <Fragment>
            {reverse ? `${title} ${description}` : `${description} ${title}`}
          </Fragment>}
      </p>
    </OuiScreenReaderOnly>;
  const statDisplay = <Fragment>
      {!reverse && descriptionDisplay}
      {titleDisplay}
      {reverse && descriptionDisplay}
      {typeof title === 'string' && typeof description === 'string' && screenReader}
    </Fragment>;
  return <div className={classes} {...rest}>
      {statDisplay}
      {children}
    </div>;
};
OuiStat.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Set the description (label) text
     */
  description: PropTypes.node.isRequired,

  /**
     * Will hide the title with an animation until false
     */
  isLoading: PropTypes.bool,

  /**
     * Flips the order of the description and title
     */
  reverse: PropTypes.bool,
  textAlign: PropTypes.oneOf(["left", "center", "right"]),

  /**
     * The (value) text
     */
  title: PropTypes.node.isRequired,

  /**
     * The color of the title text
     * **`secondary` color is DEPRECATED, use `success` instead**
     */
  titleColor: PropTypes.oneOfType([PropTypes.oneOf(["default", "subdued", "primary", "secondary", "success", "danger", "accent"]).isRequired, PropTypes.string.isRequired]),

  /**
     * Size of the title. See OuiTitle for options ('s', 'm', 'l'... etc)
     */
  titleSize: PropTypes.oneOf(["xxxs", "xxs", "xs", "s", "m", "l"]),

  /**
     * HTML Element to be used for title
     */
  titleElement: PropTypes.string,

  /**
     * HTML Element to be used for description
     */
  descriptionElement: PropTypes.string
};

try {
  isColorClass.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'isColorClass',
    methods: [],
    props: {},
    extendedInterfaces: ['String', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiStatProps']
  };
} catch (e) {}

try {
  OuiStat.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiStat',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      description: {
        defaultValue: null,
        description: 'Set the description (label) text',
        name: 'description',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: 'Will hide the title with an animation until false',
        name: 'isLoading',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      reverse: {
        defaultValue: {
          value: 'false'
        },
        description: 'Flips the order of the description and title',
        name: 'reverse',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      textAlign: {
        defaultValue: {
          value: 'left'
        },
        description: '',
        name: 'textAlign',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "center"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"center"'
          }]
        }
      },
      title: {
        defaultValue: null,
        description: 'The (value) text',
        name: 'title',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      titleColor: {
        defaultValue: {
          value: 'default'
        },
        description: 'The color of the title text\n' + '**`secondary` color is DEPRECATED, use `success` instead**',
        name: 'titleColor',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      titleSize: {
        defaultValue: {
          value: 'l'
        },
        description: "Size of the title. See OuiTitle for options ('s', 'm', 'l'... etc)",
        name: 'titleSize',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      titleElement: {
        defaultValue: {
          value: 'p'
        },
        description: 'HTML Element to be used for title',
        name: 'titleElement',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      descriptionElement: {
        defaultValue: {
          value: 'p'
        },
        description: 'HTML Element to be used for description',
        name: 'descriptionElement',
        parent: {
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        },
        declarations: [{
          fileName: 'oui/src/components/stat/stat.tsx',
          name: 'OuiStatProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['String', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiStatProps']
  };
} catch (e) {}