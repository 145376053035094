function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

import PropTypes from "prop-types";

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}
/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */


import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useOuiResizableContainerContext } from './context';
import { htmlIdGenerator } from '../../services';
import { OuiPanel } from '../panel';
import { panelPaddingValues } from '../panel/panel';
import { useOuiI18n } from '../i18n';
import { OuiResizableCollapseButton } from './resizable_collapse_button';
var defaultToggleOptions = {
  className: null,
  'data-test-subj': undefined,
  position: 'middle'
};
export var getModeType = function getModeType(mode) {
  return _typeof(mode) === 'object' ? mode[0] : mode;
};
export var getToggleOptions = function getToggleOptions(mode) {
  return _typeof(mode) === 'object' ? _objectSpread(_objectSpread({}, defaultToggleOptions), mode[1]) : defaultToggleOptions;
};
var paddingSizeToClassNameMap = {
  none: null,
  s: 'ouiResizablePanel--paddingSmall',
  m: 'ouiResizablePanel--paddingMedium',
  l: 'ouiResizablePanel--paddingLarge'
};

var getPosition = function getPosition(ref) {
  var position = 'middle';

  if (ref.matches(':first-of-type')) {
    position = 'first';
  } else if (ref.matches(':last-of-type')) {
    position = 'last';
  }

  return position;
};

var generatePanelId = htmlIdGenerator('resizable-panel');
export var OuiResizablePanel = ({
  children,
  className,
  id,
  isHorizontal,
  size,
  initialSize,
  minSize = '0px',
  // Actual minSize is calculated in `./helpers.ts`
  scrollable = true,
  style = {},
  mode,
  registration,
  onToggleCollapsed,
  onToggleCollapsedInternal,
  wrapperProps,
  hasShadow = false,
  borderRadius = 'none',
  color = 'transparent',
  paddingSize = 'm',
  wrapperPadding = 'none',
  ...rest
}) => {
  const {
    registry: {
      panels,
      resizers
    } = {
      panels: {},
      resizers: {}
    }
  } = useOuiResizableContainerContext();
  const divRef = useRef(null);
  const panelId = useRef(id || generatePanelId());
  const resizerIds = useRef([]);
  const modeType = useMemo(() => getModeType(mode), [mode]);
  const toggleOpts = useMemo(() => getToggleOptions(mode), [mode]);
  const innerSize = useMemo(() => (panels[panelId.current] && panels[panelId.current].size) ?? (initialSize || 0), [panels, initialSize]);
  const isCollapsed = useMemo(() => panels[panelId.current] && panels[panelId.current].isCollapsed || false, [panels]);
  const position = useMemo(() => panels[panelId.current] && panels[panelId.current].position || 'middle', [panels]);
  const isCollapsible = useMemo(() => modeType === 'collapsible', [modeType]);
  const direction = useMemo(() => {
    let direction = null;

    if (position === 'middle' && (isCollapsible || isCollapsed)) {
      const ids = Object.keys(panels);
      const index = ids.indexOf(panelId.current);
      const prevPanel = panels[ids[index - 1]];
      const nextPanel = panels[ids[index + 1]];
      const prevPanelMode = prevPanel ? getModeType(prevPanel.mode) : null;
      const nextPanelMode = nextPanel ? getModeType(nextPanel.mode) : null; // Intentional, preferential order

      if (prevPanelMode === 'main') {
        direction = 'right';
      } else if (nextPanelMode === 'main') {
        direction = 'left';
      } else if (prevPanelMode && prevPanelMode !== 'collapsible') {
        direction = 'right';
      } else if (nextPanelMode && nextPanelMode !== 'collapsible') {
        direction = 'left';
      } else if (prevPanel && nextPanel) {
        direction = prevPanel.size > nextPanel.size ? 'right' : 'left';
      } else if (prevPanel) {
        direction = 'right';
      } else if (nextPanel) {
        direction = 'left';
      }
    }

    return direction;
  }, [isCollapsed, isCollapsible, position, panels]);
  const padding = useMemo(() => {
    return `${panelPaddingValues[paddingSize] * 2}px`;
  }, [paddingSize]);
  const classes = classNames('ouiResizablePanel', paddingSizeToClassNameMap[wrapperPadding], {
    'ouiResizablePanel--collapsible': isCollapsible,
    'ouiResizablePanel-isCollapsed': isCollapsed
  }, `ouiResizablePanel--${position}`, wrapperProps && wrapperProps.className);
  const panelClasses = classNames('ouiResizablePanel__content', {
    'ouiResizablePanel__content--scrollable': scrollable
  }, className);
  let dimensions;

  if (size) {
    dimensions = {
      width: isHorizontal ? `${size}%` : '100%',
      height: isHorizontal ? 'auto' : `${size}%`
    };
  } else {
    dimensions = {
      width: isHorizontal ? `${innerSize}%` : '100%',
      height: isHorizontal ? 'auto' : `${innerSize}%`
    };
  }

  const styles = { ...style,
    ...dimensions
  };
  useEffect(() => {
    if (!registration) return;
    const id = panelId.current;
    const initSize = size ?? (initialSize || 0);
    resizerIds.current = [divRef.current.previousElementSibling ? divRef.current.previousElementSibling.id : '', divRef.current.nextElementSibling ? divRef.current.nextElementSibling.id : ''];
    registration.register({
      id,
      size: initSize,
      prevSize: initSize,

      getSizePx() {
        return isHorizontal ? divRef.current.getBoundingClientRect().width : divRef.current.getBoundingClientRect().height;
      },

      minSize: [minSize, padding],
      mode: modeType,
      isCollapsed: false,
      position: getPosition(divRef.current)
    });
    return () => {
      registration.deregister(id);
    };
  }, [initialSize, isHorizontal, minSize, size, registration, modeType, padding]);

  const onClickCollapse = options => {
    onToggleCollapsedInternal && onToggleCollapsedInternal(panelId.current, options);
    onToggleCollapsed && onToggleCollapsed(panelId.current, options);
  };

  const collapseRight = e => {
    onClickCollapse({
      direction: 'right'
    });
    if (e.detail) e.currentTarget.blur();
  };

  const collapseLeft = e => {
    onClickCollapse({
      direction: 'left'
    });
    if (e.detail) e.currentTarget.blur();
  };

  const toggleButtonAriaLabel = useOuiI18n('ouiResizablePanel.toggleButtonAriaLabel', 'Press to toggle this panel');
  const hasLeftToggle = (isCollapsible || isCollapsed) && (position === 'last' || position === 'middle' && direction === 'right');
  const hasRightToggle = (isCollapsible || isCollapsed) && (position === 'first' || position === 'middle' && direction === 'left');
  const hasVisibleToggle = modeType === 'custom' && isCollapsed || isCollapsible;
  let theToggle;
  let theResizer;

  if ((isCollapsible || modeType === 'custom') && hasLeftToggle) {
    theResizer = resizers[resizerIds.current[0]];
    theToggle = <OuiResizableCollapseButton externalPosition="before" direction={isHorizontal ? 'horizontal' : 'vertical'} isVisible={theResizer && (theResizer.isFocused || theResizer.isDisabled)} isCollapsed={theResizer && theResizer.isDisabled} internalPosition={toggleOpts.position} data-test-subj={toggleOpts['data-test-subj']} aria-label={toggleButtonAriaLabel} onClick={collapseRight} />;
  } else if ((isCollapsible || modeType === 'custom') && hasRightToggle) {
    theResizer = resizers[resizerIds.current[1]];
    theToggle = <OuiResizableCollapseButton externalPosition="after" direction={isHorizontal ? 'horizontal' : 'vertical'} isVisible={theResizer && (theResizer.isFocused || theResizer.isDisabled)} isCollapsed={theResizer && theResizer.isDisabled} internalPosition={toggleOpts.position} data-test-subj={toggleOpts['data-test-subj']} aria-label={toggleButtonAriaLabel} onClick={collapseLeft} />;
  }

  return <div {...wrapperProps} id={panelId.current} ref={divRef} style={styles} className={classes}>
      {
      /* The toggle is displayed on either side for tab order */
    }
      {hasVisibleToggle && hasLeftToggle && theToggle}
      <OuiPanel className={panelClasses} hasShadow={hasShadow} borderRadius={borderRadius} color={color} paddingSize={isCollapsed ? 'none' : paddingSize} {...rest}>
        {children}
      </OuiPanel>
      {
      /* The toggle is displayed on either side for tab order */
    }
      {hasVisibleToggle && hasRightToggle && theToggle}
    </div>;
};
OuiResizablePanel.propTypes = {
  /**
     * Specify a desired minimum panel size in pixels or percents,
     * for example "300px" or "30%"
     * The actual minimum size will be calculated,
     * using the larger of this prop and the panelProps.paddingSize
     */
  minSize: PropTypes.string,

  /**
     * Specify id of panel if you want to track panel size in "onPanelWidthChange" callback
     */
  id: PropTypes.string,

  /**
     * Initial size of the panel in percents
     * Specify this prop if you don't need to handle the panel size from outside
     */
  initialSize: PropTypes.number,

  /**
     * Size of the panel in percents.
     * Specify this prop if you want to control the size from outside, the panel will ignore the "initialSize"
     */
  size: PropTypes.number,

  /**
     * Add Oui scroll and overflow for the panel
     */
  scrollable: PropTypes.bool,

  /*
     * For use with collapsible panels.
     * Specify either `'collapsible'`, `'main'`, or `'custom'`.
     * `'collapsible'` also accepts an array where
     * the second item is attributes for the toggle button:
     * `['collapsible', {'data-test-subj'?: string, className?: string;}]
     */
  mode: PropTypes.oneOfType([PropTypes.oneOf(["collapsible", "main", "custom"]).isRequired, PropTypes.any.isRequired]),

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,

  /**
     * Custom CSS properties applied to the wrapping `.ouiResizablePanel` div
     */
  style: PropTypes.any,

  /**
     * Props to add to the wrapping `.ouiResizablePanel` div
     */
  wrapperProps: PropTypes.any,

  /**
     * Padding to add directly to the wrapping `.ouiResizablePanel` div
     * Gives space around the actual panel.
     */
  wrapperPadding: PropTypes.any,

  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  hasShadow: PropTypes.bool,

  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     * Default is `undefined` and will default to that theme's panel style
     */
  hasBorder: PropTypes.bool,

  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,

  /**
     * Corner border radius
     */
  borderRadius: PropTypes.any,

  /**
     * When true the panel will grow in height to match `OuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,

  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  color: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};
export function ouiResizablePanelWithControls(controls) {
  return props => <OuiResizablePanel {...controls} {...props} />;
}

try {
  ouiResizablePanelWithControls.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'ouiResizablePanelWithControls',
    methods: [],
    props: {
      isHorizontal: {
        defaultValue: null,
        description: '',
        name: 'isHorizontal',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      registration: {
        defaultValue: null,
        description: '',
        name: 'registration',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        }],
        required: true,
        type: {
          name: '{ register: (panel: OuiResizablePanelController) => void; deregister: (panelId: string) => void; }'
        }
      },
      onToggleCollapsed: {
        defaultValue: null,
        description: '#ToggleCollapseCallback',
        name: 'onToggleCollapsed',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: 'ToggleCollapseCallback'
        }
      },
      onToggleCollapsedInternal: {
        defaultValue: null,
        description: '',
        name: 'onToggleCollapsedInternal',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        }],
        required: true,
        type: {
          name: 'ToggleCollapseCallback'
        }
      }
    },
    extendedInterfaces: ['OuiResizablePanelControls', 'String', 'Object', 'Array', 'OuiResizablePanelProps', '_OuiPanelProps', 'CommonProps']
  };
} catch (e) {}

try {
  getModeType.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'getModeType',
    methods: [],
    props: {
      '0': {
        defaultValue: null,
        description: '',
        name: '0',
        parent: undefined,
        declarations: undefined,
        required: true,
        type: {
          name: 'string'
        }
      },
      '1': {
        defaultValue: null,
        description: '',
        name: '1',
        parent: undefined,
        declarations: undefined,
        required: true,
        type: {
          name: 'string | Partial<ToggleOptions>'
        }
      }
    },
    extendedInterfaces: ['OuiResizablePanelControls', 'String', 'Object', 'Array', 'OuiResizablePanelProps', '_OuiPanelProps', 'CommonProps']
  };
} catch (e) {}

try {
  getToggleOptions.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'getToggleOptions',
    methods: [],
    props: {
      '0': {
        defaultValue: null,
        description: '',
        name: '0',
        parent: undefined,
        declarations: undefined,
        required: true,
        type: {
          name: 'string'
        }
      },
      '1': {
        defaultValue: null,
        description: '',
        name: '1',
        parent: undefined,
        declarations: undefined,
        required: true,
        type: {
          name: 'string | Partial<ToggleOptions>'
        }
      }
    },
    extendedInterfaces: ['OuiResizablePanelControls', 'String', 'Object', 'Array', 'OuiResizablePanelProps', '_OuiPanelProps', 'CommonProps']
  };
} catch (e) {}

try {
  OuiResizablePanel.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiResizablePanel',
    methods: [],
    props: {
      minSize: {
        defaultValue: {
          value: '0px'
        },
        description: 'Specify a desired minimum panel size in pixels or percents,\n' + 'for example "300px" or "30%"\n' + 'The actual minimum size will be calculated,\n' + 'using the larger of this prop and the panelProps.paddingSize',
        name: 'minSize',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      id: {
        defaultValue: null,
        description: 'Specify id of panel if you want to track panel size in "onPanelWidthChange" callback',
        name: 'id',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      initialSize: {
        defaultValue: null,
        description: 'Initial size of the panel in percents\n' + "Specify this prop if you don't need to handle the panel size from outside",
        name: 'initialSize',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      size: {
        defaultValue: null,
        description: 'Size of the panel in percents.\n' + 'Specify this prop if you want to control the size from outside, the panel will ignore the "initialSize"',
        name: 'size',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      scrollable: {
        defaultValue: {
          value: 'true'
        },
        description: 'Add Oui scroll and overflow for the panel',
        name: 'scrollable',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      mode: {
        defaultValue: null,
        description: '',
        name: 'mode',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'ModeOptions'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      style: {
        defaultValue: {
          value: '{}'
        },
        description: 'Custom CSS properties applied to the wrapping `.ouiResizablePanel` div',
        name: 'style',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      wrapperProps: {
        defaultValue: null,
        description: 'Props to add to the wrapping `.ouiResizablePanel` div',
        name: 'wrapperProps',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLDivElement>'
        }
      },
      wrapperPadding: {
        defaultValue: {
          value: 'none'
        },
        description: 'Padding to add directly to the wrapping `.ouiResizablePanel` div\n' + 'Gives space around the actual panel.',
        name: 'wrapperPadding',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      hasShadow: {
        defaultValue: {
          value: 'false'
        },
        description: 'Adds a medium shadow to the panel;\nOnly works when `color="plain"`',
        name: 'hasShadow',
        parent: {
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasBorder: {
        defaultValue: null,
        description: 'Adds a slight 1px border on all edges.\n' + 'Only works when `color="plain | transparent"`\n' + "Default is `undefined` and will default to that theme's panel style",
        name: 'hasBorder',
        parent: {
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: {
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      borderRadius: {
        defaultValue: {
          value: 'none'
        },
        description: 'Corner border radius',
        name: 'borderRadius',
        parent: {
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"m" | "none"',
          value: [{
            value: '"m"'
          }, {
            value: '"none"'
          }]
        }
      },
      grow: {
        defaultValue: null,
        description: 'When true the panel will grow in height to match `OuiFlexItem`',
        name: 'grow',
        parent: {
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      color: {
        defaultValue: {
          value: 'transparent'
        },
        description: 'Background color of the panel;\n' + 'Usually a lightened form of the brand colors',
        name: 'color',
        parent: {
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"primary" | "success" | "accent" | "warning" | "danger" | "subdued" | "transparent" | "plain"',
          value: [{
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"transparent"'
          }, {
            value: '"plain"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isHorizontal: {
        defaultValue: null,
        description: '',
        name: 'isHorizontal',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      registration: {
        defaultValue: null,
        description: '',
        name: 'registration',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: '{ register: (panel: OuiResizablePanelController) => void; deregister: (panelId: string) => void; }'
        }
      },
      onToggleCollapsed: {
        defaultValue: null,
        description: '#ToggleCollapseCallback',
        name: 'onToggleCollapsed',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: 'ToggleCollapseCallback'
        }
      },
      onToggleCollapsedInternal: {
        defaultValue: null,
        description: '',
        name: 'onToggleCollapsedInternal',
        parent: {
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        },
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_panel.tsx',
          name: 'OuiResizablePanelControls'
        }],
        required: false,
        type: {
          name: 'ToggleCollapseCallback'
        }
      }
    },
    extendedInterfaces: ['OuiResizablePanelControls', 'String', 'Object', 'Array', 'OuiResizablePanelProps', '_OuiPanelProps', 'CommonProps']
  };
} catch (e) {}