/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useRouteMatch } from 'react-router';
import { OuiErrorBoundary } from '../../../../src/components/error_boundary';
import { OuiText } from '../../../../src/components/text';
import { OuiSwitch } from '../../../../src/components/form';
import { OuiButton } from '../../../../src/components/button';
import { slugify } from '../../../../src/services/string/slugify'; // @ts-ignore Not TS yet

import playgroundService from '../../services/playground/playground';
import { GuideSectionExample } from './guide_section_parts/guide_section_example';
import { GuideSectionExampleText } from './guide_section_parts/guide_section_text';
import { GuideSectionExampleTabs } from './guide_section_parts/guide_section_tabs';
import { GuideSectionPropsDescription } from './guide_section_parts/guide_section_props_description';
export var GuideSectionCodeTypesMap = {
  JS: {
    name: 'demoJS',
    displayName: 'Demo JS'
  },
  SNIPPET: {
    name: 'snippet',
    displayName: 'Snippet'
  },
  PROPS: {
    name: 'props',
    displayName: 'Props'
  }
};
export var GuideSection = ({
  id,
  title,
  text,
  demo,
  fullScreen,
  source = [],
  props = {},
  playground,
  ghostBackground,
  wrapText = true,
  demoPanelProps,
  snippet
}) => {
  const {
    path
  } = useRouteMatch();
  const [renderingPlayground, setRenderingPlayground] = useState(false);

  const renderTabs = () => {
    const hasSnippet = !!snippet; // Don't duplicate in case this function is run multiple times

    if (hasSnippet && !source.find(tab => tab.name === 'snippet')) {
      source.push({ ...GuideSectionCodeTypesMap.SNIPPET,
        snippets: snippet
      });
    }

    const hasPropsTabAlready = source.find(tab => tab.name === 'props');

    if (Object.keys(props).length && !hasPropsTabAlready // Don't duplicate in case this function is run multiple times
    ) {
      source.push({ ...GuideSectionCodeTypesMap.PROPS,
        props: props
      });
    }

    const tabs = [];

    if (source) {
      source.map(source => {
        // Forever skipping the HTML tab
        if (source.type === 'HTML') return;
        tabs.push({ // @ts-ignore Complicated
          ...GuideSectionCodeTypesMap[source.type],
          // Make sure the `name` is unique in case there are multiple source languages
          name: source.displayName ? slugify(source.displayName) : // @ts-ignore Complicated
          GuideSectionCodeTypesMap[source.type].name,
          disabled: renderingPlayground,
          ...source
        });
      });
    }

    return tabs.length ? <GuideSectionExampleTabs tabs={tabs} rightSideControl={renderPlaygroundToggle()} /> : undefined;
  };

  const renderPlaygroundToggle = () => {
    const isPlaygroundUnsupported = typeof window !== 'undefined' && typeof document !== 'undefined' && !!window.MSInputMethodContext && // @ts-ignore doesn't exist?
    !!document.documentMode;

    if (!isPlaygroundUnsupported && !!playground) {
      return <OuiSwitch onChange={() => {
        setRenderingPlayground(rendering => !rendering);
      }} checked={renderingPlayground} compressed label={<OuiText size="xs">
              <strong>Playground</strong>
            </OuiText>} />;
    }
  };

  const renderPlayground = () => {
    const {
      config,
      setGhostBackground,
      playgroundClassName
    } = playground();
    const description = <GuideSectionPropsDescription componentName={config.componentName} component={config.scope[config.componentName]} />;
    return playgroundService({
      config,
      setGhostBackground,
      playgroundClassName,
      playgroundToggle: renderPlaygroundToggle(),
      tabs: renderTabs(),
      description
    });
  };

  return <div className="guideSection" id={id}>
      <GuideSectionExampleText title={title} wrapText={wrapText}>
        {text}
      </GuideSectionExampleText>

      {renderingPlayground && renderPlayground()}
      {!renderingPlayground && (demo || fullScreen) && <GuideSectionExample example={<OuiErrorBoundary>
              {
        /* eslint-disable-next-line no-nested-ternary */
      }
              {fullScreen == null ? <div>{demo}</div> : demo == null ? <OuiButton fill iconType="fullScreen" href={`#${path}/${fullScreen.slug}`}>
                  Full screen demo
                </OuiButton> : demo}
            </OuiErrorBoundary>} tabs={renderTabs()} ghostBackground={ghostBackground} demoPanelProps={demoPanelProps} />}
    </div>;
};
GuideSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.node,
  source: PropTypes.arrayOf(PropTypes.any.isRequired),
  demo: PropTypes.node,
  fullScreen: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    demo: PropTypes.node.isRequired
  }),
  demoPanelProps: PropTypes.shape({
    /**
       * Padding for all four sides
       */
    paddingSize: PropTypes.any,

    /**
       * When true the panel will grow in height to match `OuiFlexItem`
       */
    grow: PropTypes.bool,
    panelRef: PropTypes.any,

    /**
       * Background color of the panel;
       * Usually a lightened form of the brand colors
       */
    color: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  }),
  props: PropTypes.any,
  playground: PropTypes.any,
  ghostBackground: PropTypes.bool,
  wrapText: PropTypes.bool,
  snippet: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired])
};

try {
  GuideSection.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'GuideSection',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      text: {
        defaultValue: null,
        description: '',
        name: 'text',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      source: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'source',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'any[]'
        }
      },
      demo: {
        defaultValue: null,
        description: '',
        name: 'demo',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      fullScreen: {
        defaultValue: null,
        description: '',
        name: 'fullScreen',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: '{ slug: string; demo: ReactNode; }'
        }
      },
      demoPanelProps: {
        defaultValue: null,
        description: '',
        name: 'demoPanelProps',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLDivElement> & Pick<_OuiPanelProps, "className" | "color" | "aria-label" | "data-test-subj" | "paddingSize" | "grow" | "panelRef">'
        }
      },
      props: {
        defaultValue: {
          value: '{}'
        },
        description: '',
        name: 'props',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'object'
        }
      },
      playground: {
        defaultValue: null,
        description: '',
        name: 'playground',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      ghostBackground: {
        defaultValue: null,
        description: '',
        name: 'ghostBackground',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      wrapText: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'wrapText',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      snippet: {
        defaultValue: null,
        description: '',
        name: 'snippet',
        parent: {
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSection'
        }],
        required: false,
        type: {
          name: 'string | string[]'
        }
      }
    },
    extendedInterfaces: ['GuideSection']
  };
} catch (e) {}