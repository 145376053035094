/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { Component } from 'react';
import PropTypes from "prop-types";
export class OuiWindowEvent extends Component {
  componentDidMount() {
    this.addEvent(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event || prevProps.handler !== this.props.handler) {
      this.removeEvent(prevProps);
      this.addEvent(this.props);
    }
  }

  componentWillUnmount() {
    this.removeEvent(this.props);
  }

  addEvent({
    event,
    handler
  }) {
    window.addEventListener(event, handler);
  }

  removeEvent({
    event,
    handler
  }) {
    window.removeEventListener(event, handler);
  }

  render() {
    return null;
  }

}
/* OUI -> EUI Aliases */

OuiWindowEvent.propTypes = {
  event: PropTypes.any.isRequired,
  handler: PropTypes.func.isRequired
};
export class EuiWindowEvent extends OuiWindowEvent {}
/* End of Aliases */

try {
  OuiWindowEvent.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiWindowEvent',
    methods: [],
    props: {
      event: {
        defaultValue: null,
        description: '',
        name: 'event',
        parent: {
          fileName: 'oui/src/services/window_event/window_event.ts',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/services/window_event/window_event.ts',
          name: 'Props'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"input" | "progress" | "select" | "abort" | "afterprint" | "beforeprint" | "beforeunload" | "blur" | "canplay" | "canplaythrough" | "change" | "click" | "compassneedscalibration" | ... 121 more ... | "unhandledrejection"',
          value: [{
            value: '"input"'
          }, {
            value: '"progress"'
          }, {
            value: '"select"'
          }, {
            value: '"abort"'
          }, {
            value: '"afterprint"'
          }, {
            value: '"beforeprint"'
          }, {
            value: '"beforeunload"'
          }, {
            value: '"blur"'
          }, {
            value: '"canplay"'
          }, {
            value: '"canplaythrough"'
          }, {
            value: '"change"'
          }, {
            value: '"click"'
          }, {
            value: '"compassneedscalibration"'
          }, {
            value: '"contextmenu"'
          }, {
            value: '"dblclick"'
          }, {
            value: '"devicelight"'
          }, {
            value: '"devicemotion"'
          }, {
            value: '"deviceorientation"'
          }, {
            value: '"deviceorientationabsolute"'
          }, {
            value: '"drag"'
          }, {
            value: '"dragend"'
          }, {
            value: '"dragenter"'
          }, {
            value: '"dragleave"'
          }, {
            value: '"dragover"'
          }, {
            value: '"dragstart"'
          }, {
            value: '"drop"'
          }, {
            value: '"durationchange"'
          }, {
            value: '"emptied"'
          }, {
            value: '"ended"'
          }, {
            value: '"error"'
          }, {
            value: '"focus"'
          }, {
            value: '"hashchange"'
          }, {
            value: '"invalid"'
          }, {
            value: '"keydown"'
          }, {
            value: '"keypress"'
          }, {
            value: '"keyup"'
          }, {
            value: '"load"'
          }, {
            value: '"loadeddata"'
          }, {
            value: '"loadedmetadata"'
          }, {
            value: '"loadstart"'
          }, {
            value: '"message"'
          }, {
            value: '"mousedown"'
          }, {
            value: '"mouseenter"'
          }, {
            value: '"mouseleave"'
          }, {
            value: '"mousemove"'
          }, {
            value: '"mouseout"'
          }, {
            value: '"mouseover"'
          }, {
            value: '"mouseup"'
          }, {
            value: '"mousewheel"'
          }, {
            value: '"MSGestureChange"'
          }, {
            value: '"MSGestureDoubleTap"'
          }, {
            value: '"MSGestureEnd"'
          }, {
            value: '"MSGestureHold"'
          }, {
            value: '"MSGestureStart"'
          }, {
            value: '"MSGestureTap"'
          }, {
            value: '"MSInertiaStart"'
          }, {
            value: '"MSPointerCancel"'
          }, {
            value: '"MSPointerDown"'
          }, {
            value: '"MSPointerEnter"'
          }, {
            value: '"MSPointerLeave"'
          }, {
            value: '"MSPointerMove"'
          }, {
            value: '"MSPointerOut"'
          }, {
            value: '"MSPointerOver"'
          }, {
            value: '"MSPointerUp"'
          }, {
            value: '"offline"'
          }, {
            value: '"online"'
          }, {
            value: '"orientationchange"'
          }, {
            value: '"pagehide"'
          }, {
            value: '"pageshow"'
          }, {
            value: '"pause"'
          }, {
            value: '"play"'
          }, {
            value: '"playing"'
          }, {
            value: '"popstate"'
          }, {
            value: '"ratechange"'
          }, {
            value: '"readystatechange"'
          }, {
            value: '"reset"'
          }, {
            value: '"resize"'
          }, {
            value: '"scroll"'
          }, {
            value: '"seeked"'
          }, {
            value: '"seeking"'
          }, {
            value: '"stalled"'
          }, {
            value: '"storage"'
          }, {
            value: '"submit"'
          }, {
            value: '"suspend"'
          }, {
            value: '"timeupdate"'
          }, {
            value: '"unload"'
          }, {
            value: '"volumechange"'
          }, {
            value: '"vrdisplayactivate"'
          }, {
            value: '"vrdisplayblur"'
          }, {
            value: '"vrdisplayconnect"'
          }, {
            value: '"vrdisplaydeactivate"'
          }, {
            value: '"vrdisplaydisconnect"'
          }, {
            value: '"vrdisplayfocus"'
          }, {
            value: '"vrdisplaypointerrestricted"'
          }, {
            value: '"vrdisplaypointerunrestricted"'
          }, {
            value: '"vrdisplaypresentchange"'
          }, {
            value: '"waiting"'
          }, {
            value: '"animationcancel"'
          }, {
            value: '"animationend"'
          }, {
            value: '"animationiteration"'
          }, {
            value: '"animationstart"'
          }, {
            value: '"auxclick"'
          }, {
            value: '"cancel"'
          }, {
            value: '"close"'
          }, {
            value: '"cuechange"'
          }, {
            value: '"dragexit"'
          }, {
            value: '"focusin"'
          }, {
            value: '"focusout"'
          }, {
            value: '"gotpointercapture"'
          }, {
            value: '"lostpointercapture"'
          }, {
            value: '"pointercancel"'
          }, {
            value: '"pointerdown"'
          }, {
            value: '"pointerenter"'
          }, {
            value: '"pointerleave"'
          }, {
            value: '"pointermove"'
          }, {
            value: '"pointerout"'
          }, {
            value: '"pointerover"'
          }, {
            value: '"pointerup"'
          }, {
            value: '"securitypolicyviolation"'
          }, {
            value: '"selectionchange"'
          }, {
            value: '"selectstart"'
          }, {
            value: '"toggle"'
          }, {
            value: '"touchcancel"'
          }, {
            value: '"touchend"'
          }, {
            value: '"touchmove"'
          }, {
            value: '"touchstart"'
          }, {
            value: '"transitioncancel"'
          }, {
            value: '"transitionend"'
          }, {
            value: '"transitionrun"'
          }, {
            value: '"transitionstart"'
          }, {
            value: '"wheel"'
          }, {
            value: '"languagechange"'
          }, {
            value: '"messageerror"'
          }, {
            value: '"rejectionhandled"'
          }, {
            value: '"unhandledrejection"'
          }]
        }
      },
      handler: {
        defaultValue: null,
        description: '',
        name: 'handler',
        parent: {
          fileName: 'oui/src/services/window_event/window_event.ts',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/services/window_event/window_event.ts',
          name: 'Props'
        }],
        required: true,
        type: {
          name: '(this: Window, ev: WindowEventMap[E]) => any'
        }
      }
    },
    extendedInterfaces: ['Props']
  };
} catch (e) {}

try {
  EuiWindowEvent.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'EuiWindowEvent',
    methods: [],
    props: {
      event: {
        defaultValue: null,
        description: '',
        name: 'event',
        parent: {
          fileName: 'oui/src/services/window_event/window_event.ts',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/services/window_event/window_event.ts',
          name: 'Props'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"input" | "progress" | "select" | "abort" | "afterprint" | "beforeprint" | "beforeunload" | "blur" | "canplay" | "canplaythrough" | "change" | "click" | "compassneedscalibration" | ... 121 more ... | "unhandledrejection"',
          value: [{
            value: '"input"'
          }, {
            value: '"progress"'
          }, {
            value: '"select"'
          }, {
            value: '"abort"'
          }, {
            value: '"afterprint"'
          }, {
            value: '"beforeprint"'
          }, {
            value: '"beforeunload"'
          }, {
            value: '"blur"'
          }, {
            value: '"canplay"'
          }, {
            value: '"canplaythrough"'
          }, {
            value: '"change"'
          }, {
            value: '"click"'
          }, {
            value: '"compassneedscalibration"'
          }, {
            value: '"contextmenu"'
          }, {
            value: '"dblclick"'
          }, {
            value: '"devicelight"'
          }, {
            value: '"devicemotion"'
          }, {
            value: '"deviceorientation"'
          }, {
            value: '"deviceorientationabsolute"'
          }, {
            value: '"drag"'
          }, {
            value: '"dragend"'
          }, {
            value: '"dragenter"'
          }, {
            value: '"dragleave"'
          }, {
            value: '"dragover"'
          }, {
            value: '"dragstart"'
          }, {
            value: '"drop"'
          }, {
            value: '"durationchange"'
          }, {
            value: '"emptied"'
          }, {
            value: '"ended"'
          }, {
            value: '"error"'
          }, {
            value: '"focus"'
          }, {
            value: '"hashchange"'
          }, {
            value: '"invalid"'
          }, {
            value: '"keydown"'
          }, {
            value: '"keypress"'
          }, {
            value: '"keyup"'
          }, {
            value: '"load"'
          }, {
            value: '"loadeddata"'
          }, {
            value: '"loadedmetadata"'
          }, {
            value: '"loadstart"'
          }, {
            value: '"message"'
          }, {
            value: '"mousedown"'
          }, {
            value: '"mouseenter"'
          }, {
            value: '"mouseleave"'
          }, {
            value: '"mousemove"'
          }, {
            value: '"mouseout"'
          }, {
            value: '"mouseover"'
          }, {
            value: '"mouseup"'
          }, {
            value: '"mousewheel"'
          }, {
            value: '"MSGestureChange"'
          }, {
            value: '"MSGestureDoubleTap"'
          }, {
            value: '"MSGestureEnd"'
          }, {
            value: '"MSGestureHold"'
          }, {
            value: '"MSGestureStart"'
          }, {
            value: '"MSGestureTap"'
          }, {
            value: '"MSInertiaStart"'
          }, {
            value: '"MSPointerCancel"'
          }, {
            value: '"MSPointerDown"'
          }, {
            value: '"MSPointerEnter"'
          }, {
            value: '"MSPointerLeave"'
          }, {
            value: '"MSPointerMove"'
          }, {
            value: '"MSPointerOut"'
          }, {
            value: '"MSPointerOver"'
          }, {
            value: '"MSPointerUp"'
          }, {
            value: '"offline"'
          }, {
            value: '"online"'
          }, {
            value: '"orientationchange"'
          }, {
            value: '"pagehide"'
          }, {
            value: '"pageshow"'
          }, {
            value: '"pause"'
          }, {
            value: '"play"'
          }, {
            value: '"playing"'
          }, {
            value: '"popstate"'
          }, {
            value: '"ratechange"'
          }, {
            value: '"readystatechange"'
          }, {
            value: '"reset"'
          }, {
            value: '"resize"'
          }, {
            value: '"scroll"'
          }, {
            value: '"seeked"'
          }, {
            value: '"seeking"'
          }, {
            value: '"stalled"'
          }, {
            value: '"storage"'
          }, {
            value: '"submit"'
          }, {
            value: '"suspend"'
          }, {
            value: '"timeupdate"'
          }, {
            value: '"unload"'
          }, {
            value: '"volumechange"'
          }, {
            value: '"vrdisplayactivate"'
          }, {
            value: '"vrdisplayblur"'
          }, {
            value: '"vrdisplayconnect"'
          }, {
            value: '"vrdisplaydeactivate"'
          }, {
            value: '"vrdisplaydisconnect"'
          }, {
            value: '"vrdisplayfocus"'
          }, {
            value: '"vrdisplaypointerrestricted"'
          }, {
            value: '"vrdisplaypointerunrestricted"'
          }, {
            value: '"vrdisplaypresentchange"'
          }, {
            value: '"waiting"'
          }, {
            value: '"animationcancel"'
          }, {
            value: '"animationend"'
          }, {
            value: '"animationiteration"'
          }, {
            value: '"animationstart"'
          }, {
            value: '"auxclick"'
          }, {
            value: '"cancel"'
          }, {
            value: '"close"'
          }, {
            value: '"cuechange"'
          }, {
            value: '"dragexit"'
          }, {
            value: '"focusin"'
          }, {
            value: '"focusout"'
          }, {
            value: '"gotpointercapture"'
          }, {
            value: '"lostpointercapture"'
          }, {
            value: '"pointercancel"'
          }, {
            value: '"pointerdown"'
          }, {
            value: '"pointerenter"'
          }, {
            value: '"pointerleave"'
          }, {
            value: '"pointermove"'
          }, {
            value: '"pointerout"'
          }, {
            value: '"pointerover"'
          }, {
            value: '"pointerup"'
          }, {
            value: '"securitypolicyviolation"'
          }, {
            value: '"selectionchange"'
          }, {
            value: '"selectstart"'
          }, {
            value: '"toggle"'
          }, {
            value: '"touchcancel"'
          }, {
            value: '"touchend"'
          }, {
            value: '"touchmove"'
          }, {
            value: '"touchstart"'
          }, {
            value: '"transitioncancel"'
          }, {
            value: '"transitionend"'
          }, {
            value: '"transitionrun"'
          }, {
            value: '"transitionstart"'
          }, {
            value: '"wheel"'
          }, {
            value: '"languagechange"'
          }, {
            value: '"messageerror"'
          }, {
            value: '"rejectionhandled"'
          }, {
            value: '"unhandledrejection"'
          }]
        }
      },
      handler: {
        defaultValue: null,
        description: '',
        name: 'handler',
        parent: {
          fileName: 'oui/src/services/window_event/window_event.ts',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/services/window_event/window_event.ts',
          name: 'Props'
        }],
        required: true,
        type: {
          name: '(this: Window, ev: WindowEventMap[E]) => any'
        }
      }
    },
    extendedInterfaces: ['Props']
  };
} catch (e) {}