/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, useMouseMove } from '../../services';
import { isNil } from '../../services/predicate';
import { OuiScreenReaderOnly } from '../accessibility';
import { OuiI18n } from '../i18n';
import { getEventPosition } from './utils';
export var OuiSaturation = forwardRef(({
  className,
  color = [1, 0, 0],
  'data-test-subj': dataTestSubj = 'ouiSaturation',
  hex,
  id,
  onChange,
  tabIndex = 0,
  ...rest
}, ref) => {
  const [indicator, setIndicator] = useState({
    left: 0,
    top: 0
  });
  const [lastColor, setlastColor] = useState([]);
  const boxRef = useRef(null);
  useEffect(() => {
    // Mimics `componentDidMount` and `componentDidUpdate`
    const [, s, v] = color;

    if (!isNil(boxRef.current) && lastColor.join() !== color.join()) {
      const {
        height,
        width
      } = boxRef.current.getBoundingClientRect();
      setIndicator({
        left: s * width,
        top: (1 - v) * height
      });
    }
  }, [color, lastColor]);

  const calculateColor = ({
    top,
    height,
    left,
    width
  }) => {
    const [h] = color;
    const s = left / width;
    const v = 1 - top / height;
    return [h, s, v];
  };

  const handleUpdate = box => {
    const {
      left,
      top
    } = box;
    setIndicator({
      left,
      top
    });
    const newColor = calculateColor(box);
    setlastColor(newColor);
    onChange(newColor);
  };

  const handleChange = location => {
    if (isNil(boxRef) || isNil(boxRef.current)) {
      return;
    }

    const box = getEventPosition(location, boxRef.current);
    handleUpdate(box);
  };

  const [handleMouseDown, handleInteraction] = useMouseMove(handleChange, boxRef.current);

  const handleKeyDown = event => {
    if (isNil(boxRef) || isNil(boxRef.current)) {
      return;
    }

    const {
      height,
      width
    } = boxRef.current.getBoundingClientRect();
    const {
      left,
      top
    } = indicator;
    const heightScale = height / 100;
    const widthScale = width / 100;
    let newLeft = left;
    let newTop = top;

    switch (event.key) {
      case keys.ARROW_DOWN:
        event.preventDefault();
        newTop = top < height ? top + heightScale : height;
        break;

      case keys.ARROW_LEFT:
        event.preventDefault();
        newLeft = left > 0 ? left - widthScale : 0;
        break;

      case keys.ARROW_UP:
        event.preventDefault();
        newTop = top > 0 ? top - heightScale : 0;
        break;

      case keys.ARROW_RIGHT:
        event.preventDefault();
        newLeft = left < width ? left + widthScale : width;
        break;

      default:
        return;
    }

    const newPosition = {
      left: newLeft,
      top: newTop
    };
    setIndicator(newPosition);
    const newColor = calculateColor({
      width,
      height,
      ...newPosition
    });
    onChange(newColor);
  };

  const classes = classNames('ouiSaturation', className);
  return <OuiI18n token="ouiSaturation.roleDescription" default="HSV color mode saturation and value selection">
        {roleDescription => // Unsure why this element causes errors as `tabIndex` and focus/interactivity (by extension) are accounted for.
    // eslint-disable-next-line jsx-a11y/aria-activedescendant-has-tabindex, jsx-a11y/no-noninteractive-element-interactions
    <div role="application" aria-roledescription={roleDescription} aria-describedby={`${id}-saturationDescription`} aria-activedescendant={`${id}-saturationIndicator`} onMouseDown={handleMouseDown} onTouchStart={handleInteraction} onTouchMove={handleInteraction} onKeyDown={handleKeyDown} ref={ref} tabIndex={tabIndex} className={classes} data-test-subj={dataTestSubj} style={{
      background: `hsl(${color[0]}, 100%, 50%)`
    }} {...rest}>
            <OuiScreenReaderOnly>
              <p>
                <OuiI18n token="ouiSaturation.screenReaderAnnouncement" default="Use the arrow keys to navigate the square color gradient. The coordinates resulting from each key press will be used to calculate HSV color mode 'saturation' and 'value' numbers, in the range of 0 to 1. Left and right decrease and increase (respectively) the 'saturation' value. Up and down decrease and increase (respectively) the 'value' value." />
              </p>
            </OuiScreenReaderOnly>
            <OuiScreenReaderOnly>
              <p aria-live="polite">{hex}</p>
            </OuiScreenReaderOnly>
            <div className="ouiSaturation__lightness" ref={boxRef}>
              <div className="ouiSaturation__saturation" />
            </div>
            <div id={`${id}-saturationIndicator`} className="ouiSaturation__indicator" style={{ ...indicator
      }} />
          </div>}
      </OuiI18n>;
});
OuiSaturation.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  color: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  hex: PropTypes.string
};
OuiSaturation.displayName = 'OuiSaturation';

try {
  OuiSaturation.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSaturation',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      color: {
        defaultValue: {
          value: '[1, 0, 0]'
        },
        description: '',
        name: 'color',
        parent: {
          fileName: 'oui/src/components/color_picker/saturation.tsx',
          name: 'HTMLDivElementOverrides'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/saturation.tsx',
          name: 'HTMLDivElementOverrides'
        }],
        required: false,
        type: {
          name: '[number, number, number]'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'oui/src/components/color_picker/saturation.tsx',
          name: 'HTMLDivElementOverrides'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/saturation.tsx',
          name: 'HTMLDivElementOverrides'
        }],
        required: true,
        type: {
          name: '(color: [number, number, number]) => void'
        }
      },
      hex: {
        defaultValue: null,
        description: '',
        name: 'hex',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/color_picker/saturation.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'HTMLDivElementOverrides', 'Attributes']
  };
} catch (e) {}