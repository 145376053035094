/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiDescriptionListTitle } from './description_list_title';
import { OuiDescriptionListDescription } from './description_list_description';
import { keysOf } from '../common';
var typesToClassNameMap = {
  row: 'ouiDescriptionList--row',
  inline: 'ouiDescriptionList--inline',
  column: 'ouiDescriptionList--column',
  responsiveColumn: 'ouiDescriptionList--responsiveColumn'
};
export var TYPES = keysOf(typesToClassNameMap);
var alignmentsToClassNameMap = {
  center: 'ouiDescriptionList--center',
  left: ''
};
export var ALIGNMENTS = keysOf(alignmentsToClassNameMap);
var textStylesToClassNameMap = {
  normal: '',
  reverse: 'ouiDescriptionList--reverse'
};
export var TEXT_STYLES = keysOf(textStylesToClassNameMap);
export var OuiDescriptionList = ({
  align = 'left',
  children,
  className,
  compressed = false,
  descriptionProps,
  listItems,
  textStyle = 'normal',
  titleProps,
  type = 'row',
  ...rest
}) => {
  const classes = classNames('ouiDescriptionList', type ? typesToClassNameMap[type] : undefined, align ? alignmentsToClassNameMap[align] : undefined, textStyle ? textStylesToClassNameMap[textStyle] : undefined, {
    'ouiDescriptionList--compressed': compressed
  }, className);
  let childrenOrListItems = null;

  if (listItems) {
    childrenOrListItems = listItems.map((item, index) => {
      return [<OuiDescriptionListTitle key={`title-${index}`} {...titleProps}>
          {item.title}
        </OuiDescriptionListTitle>, <OuiDescriptionListDescription key={`description-${index}`} {...descriptionProps}>
          {item.description}
        </OuiDescriptionListDescription>];
    });
  } else {
    childrenOrListItems = children;
  }

  return <dl className={classes} {...rest}>
      {childrenOrListItems}
    </dl>;
};
OuiDescriptionList.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired
  }).isRequired),

  /**
     * Text alignment
     */
  align: PropTypes.oneOf(["center", "left"]),

  /**
     * Smaller text and condensed spacing
     */
  compressed: PropTypes.bool,

  /**
     * How should the content be styled, by default
     * this will emphasize the title
     */
  textStyle: PropTypes.oneOf(["normal", "reverse"]),

  /**
     * How each item should be laid out
     */
  type: PropTypes.oneOf(["row", "inline", "column", "responsiveColumn"]),

  /**
     * Props object to be passed to `OuiDescriptionListTitle`
     */
  titleProps: PropTypes.any,

  /**
     * Props object to be passed to `OuiDescriptionListDescription`
     */
  descriptionProps: PropTypes.any
};

try {
  OuiDescriptionList.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiDescriptionList',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      listItems: {
        defaultValue: null,
        description: '',
        name: 'listItems',
        parent: {
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: '{ title: ReactNode; description: ReactNode; }[]'
        }
      },
      align: {
        defaultValue: {
          value: 'left'
        },
        description: 'Text alignment',
        name: 'align',
        parent: {
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "center"',
          value: [{
            value: '"left"'
          }, {
            value: '"center"'
          }]
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'Smaller text and condensed spacing',
        name: 'compressed',
        parent: {
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      textStyle: {
        defaultValue: {
          value: 'normal'
        },
        description: 'How should the content be styled, by default\n' + 'this will emphasize the title',
        name: 'textStyle',
        parent: {
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"reverse" | "normal"',
          value: [{
            value: '"reverse"'
          }, {
            value: '"normal"'
          }]
        }
      },
      type: {
        defaultValue: {
          value: 'row'
        },
        description: 'How each item should be laid out',
        name: 'type',
        parent: {
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inline" | "row" | "column" | "responsiveColumn"',
          value: [{
            value: '"inline"'
          }, {
            value: '"row"'
          }, {
            value: '"column"'
          }, {
            value: '"responsiveColumn"'
          }]
        }
      },
      titleProps: {
        defaultValue: null,
        description: 'Props object to be passed to `OuiDescriptionListTitle`',
        name: 'titleProps',
        parent: {
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLElement>'
        }
      },
      descriptionProps: {
        defaultValue: null,
        description: 'Props object to be passed to `OuiDescriptionListDescription`',
        name: 'descriptionProps',
        parent: {
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'oui/src/components/description_list/description_list.tsx',
          name: 'OuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiDescriptionListProps']
  };
} catch (e) {}