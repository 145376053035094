/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
var colorsToClassNameMap = {
  default: 'ouiTextColor--default',
  subdued: 'ouiTextColor--subdued',
  secondary: 'ouiTextColor--secondary',
  success: 'ouiTextColor--success',
  accent: 'ouiTextColor--accent',
  danger: 'ouiTextColor--danger',
  warning: 'ouiTextColor--warning',
  ghost: 'ouiTextColor--ghost'
};
export var COLORS = keysOf(colorsToClassNameMap);
export var OuiTextColor = ({
  children,
  color = 'default',
  className,
  component = 'span',
  ...rest
}) => {
  const classes = classNames('ouiTextColor', colorsToClassNameMap[color], className);
  const Component = component;
  return <Component className={classes} {...rest}>
      {children}
    </Component>;
};
OuiTextColor.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * **`secondary` color is DEPRECATED, use `success` instead**
       */
  color: PropTypes.oneOf(["default", "subdued", "secondary", "success", "accent", "danger", "warning", "ghost"]),

  /**
       * Determines the root element
       */
  component: PropTypes.oneOf(["div", "span"])
};

try {
  OuiTextColor.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTextColor',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      color: {
        defaultValue: {
          value: 'default'
        },
        description: '**`secondary` color is DEPRECATED, use `success` instead**',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/text/text_color.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"default" | "secondary" | "success" | "accent" | "warning" | "danger" | "subdued" | "ghost"',
          value: [{
            value: '"default"'
          }, {
            value: '"secondary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      component: {
        defaultValue: {
          value: 'span'
        },
        description: 'Determines the root element',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/text/text_color.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "span"',
          value: [{
            value: '"div"'
          }, {
            value: '"span"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}