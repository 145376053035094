function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

import PropTypes from "prop-types";

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return;
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */


import React, { Component } from 'react';
import classNames from 'classnames';
import { tabbable } from 'tabbable';
import { OuiFocusTrap } from '../focus_trap';
import { cascadingMenuKeys, getTransitionTimings, getWaitDuration, performOnFrame, htmlIdGenerator } from '../../services';
import { OuiScreenReaderOnly } from '../accessibility';
import { OuiPanel } from '../panel';
import { OuiPortal } from '../portal';
import { OuiMutationObserver } from '../observer/mutation_observer';
import { findPopoverPosition, getElementZIndex } from '../../services/popover';
import { OuiI18n } from '../i18n';
import { OuiOutsideClickDetector } from '../outside_click_detector';
var generateId = htmlIdGenerator();
var anchorPositionToPopoverPositionMap = {
  up: 'top',
  right: 'right',
  down: 'bottom',
  left: 'left'
};
export function getPopoverPositionFromAnchorPosition(anchorPosition) {
  // maps the anchor position to the matching popover position
  // e.g. "upLeft" -> "top", "downRight" -> "bottom"
  // extract the first positional word from anchorPosition:
  // starts at the beginning (" ^ ") of anchorPosition and
  // captures all of the characters (" (.*?) ") until the
  // first capital letter (" [A-Z] ") is encountered
  var _ref = anchorPosition.match(/^(.*?)[A-Z]/),
      _ref2 = _slicedToArray(_ref, 2),
      primaryPosition = _ref2[1];

  return anchorPositionToPopoverPositionMap[primaryPosition];
}
export function getPopoverAlignFromAnchorPosition(anchorPosition) {
  // maps the gravity to the matching popover position
  // e.g. "upLeft" -> "left", "rightDown" -> "bottom"
  // extract the second positional word from anchorPosition:
  // starts a capture group at the first capital letter
  // and includes everything after it
  var _ref3 = anchorPosition.match(/([A-Z].*)/),
      _ref4 = _slicedToArray(_ref3, 2),
      align = _ref4[1]; // this performs two tasks:
  // 1. normalizes the align position by lowercasing it
  // 2. `center` doesn't exist in the lookup map which converts it to `undefined` meaning no align


  return anchorPositionToPopoverPositionMap[align.toLowerCase()];
}
var anchorPositionToClassNameMap = {
  upCenter: 'ouiPopover--anchorUpCenter',
  upLeft: 'ouiPopover--anchorUpLeft',
  upRight: 'ouiPopover--anchorUpRight',
  downCenter: 'ouiPopover--anchorDownCenter',
  downLeft: 'ouiPopover--anchorDownLeft',
  downRight: 'ouiPopover--anchorDownRight',
  leftCenter: 'ouiPopover--anchorLeftCenter',
  leftUp: 'ouiPopover--anchorLeftUp',
  leftDown: 'ouiPopover--anchorLeftDown',
  rightCenter: 'ouiPopover--anchorRightCenter',
  rightUp: 'ouiPopover--anchorRightUp',
  rightDown: 'ouiPopover--anchorRightDown'
};
export var ANCHOR_POSITIONS = Object.keys(anchorPositionToClassNameMap);
var displayToClassNameMap = {
  inlineBlock: undefined,
  block: 'ouiPopover--displayBlock'
};
export var DISPLAY = Object.keys(displayToClassNameMap);
var DEFAULT_POPOVER_STYLES = {
  top: 50,
  left: 50
};

function getElementFromInitialFocus(initialFocus) {
  var initialFocusType = _typeof(initialFocus);

  if (initialFocusType === 'string') {
    return document.querySelector(initialFocus);
  }

  if (initialFocusType === 'function') {
    return initialFocus();
  }

  return initialFocus;
}

var returnFocusConfig = {
  preventScroll: true
};
export class OuiPopover extends Component {
  static defaultProps = {
    isOpen: false,
    ownFocus: true,
    anchorPosition: 'downCenter',
    panelPaddingSize: 'm',
    hasArrow: true,
    display: 'inlineBlock'
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.prevProps.isOpen && !nextProps.isOpen) {
      return {
        prevProps: {
          isOpen: nextProps.isOpen
        },
        isClosing: true,
        isOpening: false
      };
    }

    if (prevState.prevProps.isOpen !== nextProps.isOpen) {
      return {
        prevProps: {
          isOpen: nextProps.isOpen
        }
      };
    }

    return null;
  }

  button = null;
  panel = null;
  hasSetInitialFocus = false;

  constructor(props) {
    super(props);
    this.state = {
      prevProps: {
        isOpen: props.isOpen
      },
      suppressingPopover: props.isOpen,
      // only suppress if created with isOpen=true
      isClosing: false,
      isOpening: false,
      popoverStyles: DEFAULT_POPOVER_STYLES,
      arrowStyles: {},
      arrowPosition: null,
      openPosition: null,
      // once a stable position has been found, keep the contents on that side
      isOpenStable: false // wait for any initial opening transitions to finish before marking as stable

    };
  }

  closePopover = () => {
    if (this.props.isOpen) {
      this.props.closePopover();
    }
  };
  onEscapeKey = event => {
    if (this.props.isOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.closePopover();
    }
  };
  onKeyDown = event => {
    if (event.key === cascadingMenuKeys.ESCAPE) {
      this.onEscapeKey(event);
    }
  };
  onClickOutside = event => {
    // only close the popover if the event source isn't the anchor button
    // otherwise, it is up to the anchor to toggle the popover's open status
    if (this.button && this.button.contains(event.target) === false) {
      this.closePopover();
    }
  };

  updateFocus() {
    // Wait for the DOM to update.
    this.updateFocusAnimationFrame = window.requestAnimationFrame(() => {
      if (!this.props.ownFocus || !this.panel || this.props.initialFocus === false) {
        return;
      } // If we've already focused on something inside the panel, everything's fine.


      if (this.hasSetInitialFocus && this.panel.contains(document.activeElement)) {
        return;
      } // Otherwise let's focus the first tabbable item and expedite input from the user.


      let focusTarget;

      if (this.props.initialFocus != null) {
        focusTarget = getElementFromInitialFocus(this.props.initialFocus);
      } else {
        const tabbableItems = tabbable(this.panel);

        if (tabbableItems.length) {
          focusTarget = tabbableItems[0];
        }
      } // there's a race condition between the popover content becoming visible and this function call
      // if the element isn't visible yet (due to css styling) then it can't accept focus
      // so wait for another render and try again


      if (focusTarget == null) {
        // there isn't a focus target, one of two reasons:
        // #1 is the whole panel hidden? If so, schedule another check
        // #2 panel is visible but no tabbables exist, move focus to the panel
        const panelVisibility = window.getComputedStyle(this.panel).visibility;

        if (panelVisibility === 'hidden') {
          // #1
          this.updateFocus();
        } else {
          // #2
          focusTarget = this.panel;
        }
      } else {
        // found an element to focus, but is it visible?
        const visibility = window.getComputedStyle(focusTarget).visibility;

        if (visibility === 'hidden') {
          // not visible, check again next render frame
          this.updateFocus();
        }
      }

      if (focusTarget != null) {
        this.hasSetInitialFocus = true;
        focusTarget.focus();
      }
    });
  }

  onOpenPopover = () => {
    clearTimeout(this.closingTransitionTimeout);

    if (this.closingTransitionAnimationFrame) {
      cancelAnimationFrame(this.closingTransitionAnimationFrame);
    } // We need to set this state a beat after the render takes place, so that the CSS
    // transition can take effect.


    this.closingTransitionAnimationFrame = window.requestAnimationFrame(() => {
      this.setState({
        isOpening: true
      });
    }); // for each child element of `this.panel`, find any transition duration we should wait for before stabilizing

    const {
      durationMatch,
      delayMatch
    } = Array.prototype.slice.call(this.panel ? [this.panel, ...Array.from(this.panel.children)] : []).reduce(({
      durationMatch,
      delayMatch
    }, element) => {
      const transitionTimings = getTransitionTimings(element);
      return {
        durationMatch: Math.max(durationMatch, transitionTimings.durationMatch),
        delayMatch: Math.max(delayMatch, transitionTimings.delayMatch)
      };
    }, {
      durationMatch: 0,
      delayMatch: 0
    });
    clearTimeout(this.respositionTimeout);
    this.respositionTimeout = window.setTimeout(() => {
      this.setState({
        isOpenStable: true
      }, () => {
        this.positionPopoverFixed();
        this.updateFocus();
      });
    }, durationMatch + delayMatch);
  };

  componentDidMount() {
    if (this.state.suppressingPopover) {
      // component was created with isOpen=true; now that it's mounted
      // stop suppressing and start opening
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        suppressingPopover: false,
        isOpening: true
      }, () => {
        this.onOpenPopover();
      });
    }

    if (this.props.repositionOnScroll) {
      window.addEventListener('scroll', this.positionPopoverFixed);
    }
  }

  componentDidUpdate(prevProps) {
    // The popover is being opened.
    if (!prevProps.isOpen && this.props.isOpen) {
      this.onOpenPopover();
    } // update scroll listener


    if (prevProps.repositionOnScroll !== this.props.repositionOnScroll) {
      if (this.props.repositionOnScroll) {
        window.addEventListener('scroll', this.positionPopoverFixed);
      } else {
        window.removeEventListener('scroll', this.positionPopoverFixed);
      }
    } // The popover is being closed.


    if (prevProps.isOpen && !this.props.isOpen) {
      // If the user has just closed the popover, queue up the removal of the content after the
      // transition is complete.
      this.closingTransitionTimeout = window.setTimeout(() => {
        this.hasSetInitialFocus = false;
        this.setState({
          isClosing: false
        });
      }, 250);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.positionPopoverFixed);
    clearTimeout(this.respositionTimeout);
    clearTimeout(this.closingTransitionTimeout);
    cancelAnimationFrame(this.closingTransitionAnimationFrame);
    cancelAnimationFrame(this.updateFocusAnimationFrame);
  }

  onMutation = records => {
    const waitDuration = getWaitDuration(records);
    this.positionPopoverFixed();
    performOnFrame(waitDuration, this.positionPopoverFixed);
  };
  positionPopover = allowEnforcePosition => {
    if (this.button == null || this.panel == null) return;
    const {
      anchorPosition
    } = this.props;
    let position = getPopoverPositionFromAnchorPosition(anchorPosition);
    let forcePosition = undefined;

    if (allowEnforcePosition && this.state.isOpenStable && this.state.openPosition != null) {
      position = this.state.openPosition;
      forcePosition = true;
    }

    const {
      top,
      left,
      position: foundPosition,
      arrow,
      anchorBoundingBox
    } = findPopoverPosition({
      container: this.props.container,
      position,
      forcePosition,
      align: getPopoverAlignFromAnchorPosition(anchorPosition),
      anchor: this.button,
      popover: this.panel,
      offset: !this.props.attachToAnchor && this.props.hasArrow ? 16 + (this.props.offset || 0) : 8 + (this.props.offset || 0),
      arrowConfig: {
        arrowWidth: 24,
        arrowBuffer: 10
      },
      returnBoundingBox: this.props.attachToAnchor,
      buffer: this.props.buffer
    }); // the popover's z-index must inherit from the button
    // this keeps a button's popover under a flyout that would cover the button
    // but a popover triggered inside a flyout will appear over that flyout

    const {
      zIndex: zIndexProp
    } = this.props;
    const zIndex = zIndexProp == null ? getElementZIndex(this.button, this.panel) + 2000 : zIndexProp;
    const popoverStyles = { ...this.props.panelStyle,
      top,
      left: this.props.attachToAnchor && anchorBoundingBox ? anchorBoundingBox.left : left,
      zIndex
    };
    const willRenderArrow = !this.props.attachToAnchor && this.props.hasArrow;
    const arrowStyles = willRenderArrow ? arrow : undefined;
    const arrowPosition = foundPosition;
    this.setState({
      popoverStyles,
      arrowStyles,
      arrowPosition,
      openPosition: foundPosition
    });
  };
  positionPopoverFixed = () => {
    this.positionPopover(true);
  };
  positionPopoverFluid = () => {
    this.positionPopover(false);
  };
  panelRef = node => {
    this.panel = node;
    this.props.panelRef && this.props.panelRef(node);

    if (node == null) {
      // panel has unmounted, restore the state defaults
      this.setState({
        popoverStyles: DEFAULT_POPOVER_STYLES,
        arrowStyles: {},
        arrowPosition: null,
        openPosition: null,
        isOpenStable: false
      });
      window.removeEventListener('resize', this.positionPopoverFluid);
    } else {
      // panel is coming into existence
      this.positionPopoverFluid();
      window.addEventListener('resize', this.positionPopoverFluid);
    }
  };
  buttonRef = node => {
    this.button = node;
    this.props.buttonRef && this.props.buttonRef(node);
  };

  render() {
    const {
      anchorClassName,
      anchorPosition,
      button,
      buttonRef,
      insert,
      isOpen,
      ownFocus,
      children,
      className,
      closePopover,
      panelClassName,
      panelPaddingSize,
      panelProps,
      panelRef,
      panelStyle,
      popoverRef,
      hasArrow,
      arrowChildren,
      repositionOnScroll,
      zIndex,
      initialFocus,
      attachToAnchor,
      display,
      onTrapDeactivation,
      buffer,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      container,
      focusTrapProps,
      ...rest
    } = this.props;
    const descriptionId = generateId();
    const classes = classNames('ouiPopover', anchorPosition ? anchorPositionToClassNameMap[anchorPosition] : null, display ? displayToClassNameMap[display] : null, {
      'ouiPopover-isOpen': this.state.isOpening
    }, className);
    const anchorClasses = classNames('ouiPopover__anchor', anchorClassName);
    const panelClasses = classNames('ouiPopover__panel', `ouiPopover__panel--${this.state.arrowPosition}`, {
      'ouiPopover__panel-isOpen': this.state.isOpening
    }, {
      'ouiPopover__panel-noArrow': !hasArrow || attachToAnchor
    }, {
      'ouiPopover__panel-isAttached': attachToAnchor
    }, panelClassName, panelProps?.className);
    let panel;

    if (!this.state.suppressingPopover && (isOpen || this.state.isClosing)) {
      let tabIndex;
      let initialFocus;
      let ariaDescribedby;
      let ariaLive;

      if (ownFocus) {
        tabIndex = 0;
        ariaLive = 'off';

        initialFocus = () => this.panel;
      } else {
        ariaLive = 'assertive';
      }

      let focusTrapScreenReaderText;

      if (ownFocus) {
        ariaDescribedby = descriptionId;
        focusTrapScreenReaderText = <OuiScreenReaderOnly>
            <p id={descriptionId}>
              <OuiI18n token="ouiPopover.screenReaderAnnouncement" default="You are in a dialog. To close this dialog, hit escape." />
            </p>
          </OuiScreenReaderOnly>;
      }

      const arrowClassNames = classNames('ouiPopover__panelArrow', `ouiPopover__panelArrow--${this.state.arrowPosition}`);
      const returnFocus = this.state.isOpenStable ? returnFocusConfig : false;
      panel = <OuiPortal insert={insert}>
          <OuiFocusTrap clickOutsideDisables={true} {...focusTrapProps} returnFocus={returnFocus} // Ignore temporary state of indecisive focus
        initialFocus={initialFocus} onDeactivation={onTrapDeactivation} onClickOutside={this.onClickOutside} onEscapeKey={this.onEscapeKey} disabled={!ownFocus || !this.state.isOpenStable || this.state.isClosing}>
            <OuiPanel {...panelProps} panelRef={this.panelRef} className={panelClasses} hasShadow={false} paddingSize={panelPaddingSize} tabIndex={tabIndex} aria-live={ariaLive} role="dialog" aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} aria-modal="true" aria-describedby={ariaDescribedby} style={{ ...this.state.popoverStyles,
            // Adding `will-change` to reduce risk of a blurry animation in Chrome 86+
            willChange: !this.state.isOpenStable ? 'transform, opacity' : undefined
          }}>
              <div className={arrowClassNames} style={this.state.arrowStyles}>
                {arrowChildren}
              </div>
              {focusTrapScreenReaderText}
              <OuiMutationObserver observerOptions={{
              attributes: true,
              // element attribute changes
              childList: true,
              // added/removed elements
              characterData: true,
              // text changes
              subtree: true // watch all child elements

            }} onMutation={this.onMutation}>
                {mutationRef => <div ref={mutationRef}>{children}</div>}
              </OuiMutationObserver>
            </OuiPanel>
          </OuiFocusTrap>
        </OuiPortal>;
    } // react-focus-on and relataed do not register outside click detection
    // when disabled, so we still need to conditionally check for that ourselves


    if (ownFocus) {
      return <div className={classes} ref={popoverRef} {...rest}>
          <div className={anchorClasses} ref={this.buttonRef}>
            {button instanceof HTMLElement ? null : button}
          </div>
          {panel}
        </div>;
    } else {
      return <OuiOutsideClickDetector onOutsideClick={this.closePopover}>
          <div className={classes} ref={popoverRef} onKeyDown={this.onKeyDown} {...rest}>
            <div className={anchorClasses} ref={this.buttonRef}>
              {button instanceof HTMLElement ? null : button}
            </div>
            {panel}
          </div>
        </OuiOutsideClickDetector>;
    }
  }

}
OuiPopover.propTypes = {
  className: PropTypes.string,

  /**
     * Provide a name to the popover panel
     */
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Class name passed to the direct parent of the button
     */
  anchorClassName: PropTypes.string,

  /**
     * Alignment of the popover and arrow relative to the button
     */
  anchorPosition: PropTypes.oneOf(["upCenter", "upLeft", "upRight", "downCenter", "downLeft", "downRight", "leftCenter", "leftUp", "leftDown", "rightCenter", "rightUp", "rightDown"]),

  /**
     * Style and position alteration for arrow-less, left-aligned
     * attachment. Intended for use with inputs as anchors, e.g.
     * OuiInputPopover
     */
  attachToAnchor: PropTypes.bool,

  /**
     * Triggering element for which to align the popover to
     */
  button: PropTypes.any.isRequired,
  buttonRef: PropTypes.any,

  /**
     * Callback to handle hiding of the popover
     */
  closePopover: PropTypes.func.isRequired,

  /**
     * Restrict the popover's position within this element
     */
  container: PropTypes.any,

  /**
     * CSS display type for both the popover and anchor
     */
  display: PropTypes.oneOf(["inlineBlock", "block"]),

  /**
     * Object of props passed to OuiFocusTrap
     */
  focusTrapProps: PropTypes.any,

  /**
     * Show arrow indicating to originating button
     */
  hasArrow: PropTypes.bool,

  /**
     * Specifies what element should initially have focus; Can be a DOM
     * node, or a selector string (which will be passed to
     * document.querySelector() to find the DOM node), or a function that
     * returns a DOM node
     * Set to `false` to prevent initial auto-focus. Use only
     * when your app handles setting initial focus state.
     */
  initialFocus: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.string.isRequired, PropTypes.func.isRequired]).isRequired, PropTypes.oneOf([false])]),

  /**
     * Passed directly to OuiPortal for DOM positioning. Both properties are
     * required if prop is specified
     */
  insert: PropTypes.shape({
    sibling: PropTypes.any.isRequired,
    position: PropTypes.oneOf(["before", "after"]).isRequired
  }),

  /**
     * Visibility state of the popover
     */
  isOpen: PropTypes.bool,

  /**
     * Traps tab focus within the popover contents
     */
  ownFocus: PropTypes.bool,

  /**
     * Custom class added to the OuiPanel containing the popover contents
     */
  panelClassName: PropTypes.string,

  /**
     * OuiPanel padding on all sides
     */
  panelPaddingSize: PropTypes.any,

  /**
     * Standard DOM `style` attribute. Passed to the OuiPanel
     */
  panelStyle: PropTypes.any,

  /**
     * Object of props passed to OuiPanel
     */
  panelProps: PropTypes.any,
  panelRef: PropTypes.any,
  popoverRef: PropTypes.any,

  /**
     * When `true`, the popover's position is re-calculated when the user
     * scrolls, this supports having fixed-position popover anchors
     */
  repositionOnScroll: PropTypes.bool,

  /**
     * By default, popover content inherits the z-index of the anchor
     * component; pass `zIndex` to override
     */
  zIndex: PropTypes.number,

  /**
     * Function callback for when the focus trap is deactivated
     */
  onTrapDeactivation: PropTypes.any,

  /**
     * Distance away from the anchor that the popover will render
     */
  offset: PropTypes.number,

  /**
     * Minimum distance between the popover and the bounding container;
     * Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`
     * Default is 16
     */
  buffer: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.any.isRequired]),

  /**
     * Element to pass as the child element of the arrow;
     * Use case is typically limited to an accompanying `OuiBeacon`
     */
  arrowChildren: PropTypes.node,

  /**
     * Alternative option to `aria-label` that takes an `id`.
     * Usually takes the `id` of the popover title
     */
  "aria-labelledby": PropTypes.string
};

try {
  getPopoverPositionFromAnchorPosition.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'getPopoverPositionFromAnchorPosition',
    methods: [],
    props: {},
    extendedInterfaces: ['String', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiPopoverProps']
  };
} catch (e) {}

try {
  getPopoverAlignFromAnchorPosition.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'getPopoverAlignFromAnchorPosition',
    methods: [],
    props: {},
    extendedInterfaces: ['String', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiPopoverProps']
  };
} catch (e) {}

try {
  OuiPopover.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPopover',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + 'Provide a name to the popover panel\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      anchorClassName: {
        defaultValue: null,
        description: 'Class name passed to the direct parent of the button',
        name: 'anchorClassName',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      anchorPosition: {
        defaultValue: {
          value: 'downCenter'
        },
        description: 'Alignment of the popover and arrow relative to the button',
        name: 'anchorPosition',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'PopoverAnchorPosition',
          value: [{
            value: '"upCenter"'
          }, {
            value: '"upLeft"'
          }, {
            value: '"upRight"'
          }, {
            value: '"downCenter"'
          }, {
            value: '"downLeft"'
          }, {
            value: '"downRight"'
          }, {
            value: '"leftCenter"'
          }, {
            value: '"leftUp"'
          }, {
            value: '"leftDown"'
          }, {
            value: '"rightCenter"'
          }, {
            value: '"rightUp"'
          }, {
            value: '"rightDown"'
          }]
        }
      },
      attachToAnchor: {
        defaultValue: null,
        description: 'Style and position alteration for arrow-less, left-aligned\n' + 'attachment. Intended for use with inputs as anchors, e.g.\n' + 'OuiInputPopover',
        name: 'attachToAnchor',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      button: {
        defaultValue: null,
        description: 'Triggering element for which to align the popover to',
        name: 'button',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLDivElement) => void'
        }
      },
      closePopover: {
        defaultValue: null,
        description: 'Callback to handle hiding of the popover',
        name: 'closePopover',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      container: {
        defaultValue: null,
        description: "Restrict the popover's position within this element",
        name: 'container',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'HTMLElement'
        }
      },
      display: {
        defaultValue: {
          value: 'inlineBlock'
        },
        description: 'CSS display type for both the popover and anchor',
        name: 'display',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inlineBlock" | "block"',
          value: [{
            value: '"inlineBlock"'
          }, {
            value: '"block"'
          }]
        }
      },
      focusTrapProps: {
        defaultValue: null,
        description: 'Object of props passed to OuiFocusTrap',
        name: 'focusTrapProps',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Pick<OuiFocusTrapProps, "clickOutsideDisables" | "noIsolation" | "scrollLock">'
        }
      },
      hasArrow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Show arrow indicating to originating button',
        name: 'hasArrow',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus; Can be a DOM\n' + 'node, or a selector string (which will be passed to\n' + 'document.querySelector() to find the DOM node), or a function that\n' + 'returns a DOM node\n' + 'Set to `false` to prevent initial auto-focus. Use only\n' + 'when your app handles setting initial focus state.',
        name: 'initialFocus',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string | false | HTMLElement | (() => HTMLElement)'
        }
      },
      insert: {
        defaultValue: null,
        description: 'Passed directly to OuiPortal for DOM positioning. Both properties are\n' + 'required if prop is specified',
        name: 'insert',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      isOpen: {
        defaultValue: {
          value: 'false'
        },
        description: 'Visibility state of the popover',
        name: 'isOpen',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ownFocus: {
        defaultValue: {
          value: 'true'
        },
        description: 'Traps tab focus within the popover contents',
        name: 'ownFocus',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelClassName: {
        defaultValue: null,
        description: 'Custom class added to the OuiPanel containing the popover contents',
        name: 'panelClassName',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      panelPaddingSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'OuiPanel padding on all sides',
        name: 'panelPaddingSize',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      panelStyle: {
        defaultValue: null,
        description: 'Standard DOM `style` attribute. Passed to the OuiPanel',
        name: 'panelStyle',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      panelProps: {
        defaultValue: null,
        description: 'Object of props passed to OuiPanel',
        name: 'panelProps',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Pick<(DisambiguateSet<_OuiPanelButtonlike, _OuiPanelDivlike> & _OuiPanelDivlike) | (DisambiguateSet<_OuiPanelDivlike, _OuiPanelButtonlike> & _OuiPanelButtonlike), "value" | ... 270 more ... | "borderRadius">'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLElement) => void'
        }
      },
      popoverRef: {
        defaultValue: null,
        description: '',
        name: 'popoverRef',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the popover's position is re-calculated when the user\n" + 'scrolls, this supports having fixed-position popover anchors',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      zIndex: {
        defaultValue: null,
        description: 'By default, popover content inherits the z-index of the anchor\n' + 'component; pass `zIndex` to override',
        name: 'zIndex',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      onTrapDeactivation: {
        defaultValue: null,
        description: 'Function callback for when the focus trap is deactivated',
        name: 'onTrapDeactivation',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      offset: {
        defaultValue: null,
        description: 'Distance away from the anchor that the popover will render',
        name: 'offset',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      buffer: {
        defaultValue: null,
        description: 'Minimum distance between the popover and the bounding container;\n' + 'Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`\n' + 'Default is 16',
        name: 'buffer',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number | [number, number, number, number]'
        }
      },
      arrowChildren: {
        defaultValue: null,
        description: 'Element to pass as the child element of the arrow;\n' + 'Use case is typically limited to an accompanying `OuiBeacon`',
        name: 'arrowChildren',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['String', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiPopoverProps']
  };
} catch (e) {}