/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
var gutterSizeToClassNameMap = {
  none: null,
  xs: 'ouiFlexGroup--gutterExtraSmall',
  s: 'ouiFlexGroup--gutterSmall',
  m: 'ouiFlexGroup--gutterMedium',
  l: 'ouiFlexGroup--gutterLarge',
  xl: 'ouiFlexGroup--gutterExtraLarge'
};
export var GUTTER_SIZES = keysOf(gutterSizeToClassNameMap);
var alignItemsToClassNameMap = {
  stretch: null,
  flexStart: 'ouiFlexGroup--alignItemsFlexStart',
  flexEnd: 'ouiFlexGroup--alignItemsFlexEnd',
  center: 'ouiFlexGroup--alignItemsCenter',
  baseline: 'ouiFlexGroup--alignItemsBaseline'
};
export var ALIGN_ITEMS = keysOf(alignItemsToClassNameMap);
var justifyContentToClassNameMap = {
  flexStart: null,
  flexEnd: 'ouiFlexGroup--justifyContentFlexEnd',
  center: 'ouiFlexGroup--justifyContentCenter',
  spaceBetween: 'ouiFlexGroup--justifyContentSpaceBetween',
  spaceAround: 'ouiFlexGroup--justifyContentSpaceAround',
  spaceEvenly: 'ouiFlexGroup--justifyContentSpaceEvenly'
};
export var JUSTIFY_CONTENTS = keysOf(justifyContentToClassNameMap);
var directionToClassNameMap = {
  row: 'ouiFlexGroup--directionRow',
  rowReverse: 'ouiFlexGroup--directionRowReverse',
  column: 'ouiFlexGroup--directionColumn',
  columnReverse: 'ouiFlexGroup--directionColumnReverse'
};
export var DIRECTIONS = keysOf(directionToClassNameMap);

var isValidElement = function isValidElement(component) {
  return ['div', 'span'].includes(component);
};

export var OuiFlexGroup = forwardRef(({
  children,
  className,
  gutterSize = 'l',
  alignItems = 'stretch',
  responsive = true,
  justifyContent = 'flexStart',
  direction = 'row',
  wrap = false,
  component = 'div',
  ...rest
}, ref) => {
  const classes = classNames('ouiFlexGroup', gutterSizeToClassNameMap[gutterSize], alignItemsToClassNameMap[alignItems], justifyContentToClassNameMap[justifyContent], directionToClassNameMap[direction], {
    'ouiFlexGroup--responsive': responsive,
    'ouiFlexGroup--wrap': wrap
  }, className);

  if (!isValidElement(component)) {
    throw new Error(`${component} is not a valid element type. Use \`div\` or \`span\`.`);
  }

  return component === 'span' ? <span className={classes} ref={ref} {...rest}>
        {children}
      </span> : <div className={classes} ref={ref} {...rest}>
        {children}
      </div>;
});
OuiFlexGroup.propTypes = {
  alignItems: PropTypes.oneOf(["stretch", "flexStart", "flexEnd", "center", "baseline"]),
  component: PropTypes.oneOf(["div", "span"]),
  direction: PropTypes.oneOf(["row", "rowReverse", "column", "columnReverse"]),
  gutterSize: PropTypes.oneOf(["none", "xs", "s", "m", "l", "xl"]),
  justifyContent: PropTypes.oneOf(["flexStart", "flexEnd", "center", "spaceBetween", "spaceAround", "spaceEvenly"]),
  responsive: PropTypes.bool,
  wrap: PropTypes.bool,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};
OuiFlexGroup.displayName = 'OuiFlexGroup';

try {
  OuiFlexGroup.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFlexGroup',
    methods: [],
    props: {
      alignItems: {
        defaultValue: {
          value: 'stretch'
        },
        description: '',
        name: 'alignItems',
        parent: {
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        },
        declarations: [{
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "baseline" | "stretch" | "flexStart" | "flexEnd"',
          value: [{
            value: '"center"'
          }, {
            value: '"baseline"'
          }, {
            value: '"stretch"'
          }, {
            value: '"flexStart"'
          }, {
            value: '"flexEnd"'
          }]
        }
      },
      component: {
        defaultValue: {
          value: 'div'
        },
        description: '',
        name: 'component',
        parent: {
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        },
        declarations: [{
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "span"',
          value: [{
            value: '"div"'
          }, {
            value: '"span"'
          }]
        }
      },
      direction: {
        defaultValue: {
          value: 'row'
        },
        description: '',
        name: 'direction',
        parent: {
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        },
        declarations: [{
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column" | "rowReverse" | "columnReverse"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }, {
            value: '"rowReverse"'
          }, {
            value: '"columnReverse"'
          }]
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'l'
        },
        description: '',
        name: 'gutterSize',
        parent: {
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        },
        declarations: [{
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m" | "l" | "xl" | "none"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"none"'
          }]
        }
      },
      justifyContent: {
        defaultValue: {
          value: 'flexStart'
        },
        description: '',
        name: 'justifyContent',
        parent: {
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        },
        declarations: [{
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "flexStart" | "flexEnd" | "spaceBetween" | "spaceAround" | "spaceEvenly"',
          value: [{
            value: '"center"'
          }, {
            value: '"flexStart"'
          }, {
            value: '"flexEnd"'
          }, {
            value: '"spaceBetween"'
          }, {
            value: '"spaceAround"'
          }, {
            value: '"spaceEvenly"'
          }]
        }
      },
      responsive: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'responsive',
        parent: {
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        },
        declarations: [{
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      wrap: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'wrap',
        parent: {
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        },
        declarations: [{
          fileName: 'oui/src/components/flex/flex_group.tsx',
          name: 'OuiFlexGroupProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement | HTMLSpanElement>'
        }
      }
    },
    extendedInterfaces: ['OuiFlexGroupProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}