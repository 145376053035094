/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { cloneElement, Component, Children } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
import { get } from '../../../services/objects';
import { OuiFormHelpText } from '../form_help_text';
import { OuiFormErrorText } from '../form_error_text';
import { OuiFormLabel } from '../form_label';
import { htmlIdGenerator } from '../../../services/accessibility';
var displayToClassNameMap = {
  row: null,
  rowCompressed: 'ouiFormRow--compressed',
  columnCompressed: 'ouiFormRow--compressed ouiFormRow--horizontal',
  center: null,
  centerCompressed: 'ouiFormRow--compressed',
  columnCompressedSwitch: 'ouiFormRow--compressed ouiFormRow--horizontal ouiFormRow--hasSwitch'
};
export var DISPLAYS = keysOf(displayToClassNameMap);
export class OuiFormRow extends Component {
  static defaultProps = {
    display: 'row',
    hasEmptyLabelSpace: false,
    fullWidth: false,
    describedByIds: [],
    labelType: 'label',
    hasChildLabel: true
  };
  state = {
    isFocused: false,
    id: this.props.id || htmlIdGenerator()()
  };
  onFocus = (...args) => {
    // Doing this to allow onFocus to be called correctly from the child input element as this component overrides it
    const onChildFocus = get(this.props, 'children.props.onFocus');

    if (onChildFocus) {
      onChildFocus(...args);
    }

    this.setState(({
      isFocused
    }) => {
      if (!isFocused) {
        return {
          isFocused: true
        };
      } else {
        return null;
      }
    });
  };
  onBlur = (...args) => {
    // Doing this to allow onBlur to be called correctly from the child input element as this component overrides it
    const onChildBlur = get(this.props, 'children.props.onBlur');

    if (onChildBlur) {
      onChildBlur(...args);
    }

    this.setState({
      isFocused: false
    });
  };

  render() {
    const {
      children,
      helpText,
      isInvalid,
      error,
      label,
      labelType,
      labelAppend,
      hasEmptyLabelSpace,
      fullWidth,
      className,
      describedByIds,
      display,
      hasChildLabel,
      id: propsId,
      ...rest
    } = this.props;
    const {
      id
    } = this.state;
    const classes = classNames('ouiFormRow', {
      'ouiFormRow--hasEmptyLabelSpace': hasEmptyLabelSpace,
      'ouiFormRow--fullWidth': fullWidth
    }, displayToClassNameMap[display], // Safe use of ! as default prop is 'row'
    className);
    let optionalHelpTexts;

    if (helpText) {
      const helpTexts = Array.isArray(helpText) ? helpText : [helpText];
      optionalHelpTexts = helpTexts.map((helpText, i) => {
        const key = typeof helpText === 'string' ? helpText : i;
        return <OuiFormHelpText key={key} id={`${id}-help-${i}`} className="ouiFormRow__text">
            {helpText}
          </OuiFormHelpText>;
      });
    }

    let optionalErrors;

    if (error && isInvalid) {
      const errorTexts = Array.isArray(error) ? error : [error];
      optionalErrors = errorTexts.map((error, i) => {
        const key = typeof error === 'string' ? error : i;
        return <OuiFormErrorText key={key} id={`${id}-error-${i}`} className="ouiFormRow__text">
            {error}
          </OuiFormErrorText>;
      });
    }

    let optionalLabel;
    const isLegend = label && labelType === 'legend' ? true : false;

    if (label || labelAppend) {
      let labelProps = {};

      if (isLegend) {
        labelProps = {
          type: labelType
        };
      } else {
        labelProps = {
          htmlFor: hasChildLabel ? id : undefined,
          isFocused: this.state.isFocused,
          type: labelType
        };
      }

      optionalLabel = <div className="ouiFormRow__labelWrapper">
          <OuiFormLabel className="ouiFormRow__label" isInvalid={isInvalid} aria-invalid={isInvalid} {...labelProps}>
            {label}
          </OuiFormLabel>
          {labelAppend && ' '}
          {labelAppend}
        </div>;
    }

    const optionalProps = {};
    /**
     * Safe use of ! as default prop is []
     */

    const describingIds = [...describedByIds];

    if (optionalHelpTexts) {
      optionalHelpTexts.forEach(optionalHelpText => describingIds.push(optionalHelpText.props.id));
    }

    if (optionalErrors) {
      optionalErrors.forEach(error => describingIds.push(error.props.id));
    }

    if (describingIds.length > 0) {
      optionalProps['aria-describedby'] = describingIds.join(' ');
    }

    const field = cloneElement(Children.only(children), {
      id,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      ...optionalProps
    });
    const fieldWrapperClasses = classNames('ouiFormRow__fieldWrapper', {
      ouiFormRow__fieldWrapperDisplayOnly:
      /**
       * Safe use of ! as default prop is 'row'
       */
      display.startsWith('center')
    });
    const sharedProps = {
      className: classes,
      id: `${id}-row`
    };
    const contents = <React.Fragment>
        {optionalLabel}
        <div className={fieldWrapperClasses}>
          {field}
          {optionalErrors}
          {optionalHelpTexts}
        </div>
      </React.Fragment>;
    return labelType === 'legend' ? <fieldset {...sharedProps} {...rest}>
        {contents}
      </fieldset> : <div {...sharedProps} {...rest}>
        {contents}
      </div>;
  }

}
OuiFormRow.propTypes = {
  /**
     * Defaults to rendering a `<label>` but if passed `'legend'` for labelType,
     * will render both a `<legend>` and the surrounding container as a `<fieldset>`
     */
  labelType: PropTypes.oneOfType([PropTypes.oneOf(["label"]), PropTypes.oneOf(["legend"])]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * When `rowCompressed`, just tightens up the spacing;
     * Set to `columnCompressed` if compressed
     * and horizontal layout is needed.
     * Set to `center` or `centerCompressed` to align non-input
     * content better with inline rows.
     * Set to `columnCompressedSwitch` if the form control being passed
     * as the child is a switch.
     */

  /**
     * When `rowCompressed`, just tightens up the spacing;
     * Set to `columnCompressed` if compressed
     * and horizontal layout is needed.
     * Set to `center` or `centerCompressed` to align non-input
     * content better with inline rows.
     * Set to `columnCompressedSwitch` if the form control being passed
     * as the child is a switch.
     */
  display: PropTypes.oneOf(["row", "rowCompressed", "columnCompressed", "center", "centerCompressed", "columnCompressedSwitch"]),
  hasEmptyLabelSpace: PropTypes.bool,
  fullWidth: PropTypes.bool,

  /**
     * IDs of additional elements that should be part of children's `aria-describedby`
     */

  /**
     * IDs of additional elements that should be part of children's `aria-describedby`
     */
  describedByIds: PropTypes.arrayOf(PropTypes.string.isRequired),

  /**
     * Escape hatch to not render duplicate labels if the child also renders a label
     */

  /**
     * Escape hatch to not render duplicate labels if the child also renders a label
     */
  hasChildLabel: PropTypes.bool,

  /**
     * ReactElement to render as this component's content
     */

  /**
     * ReactElement to render as this component's content
     */
  children: PropTypes.element.isRequired,
  label: PropTypes.node,

  /**
     * Adds an extra node to the right of the form label without
     * being contained inside the form label. Good for things
     * like documentation links.
     */

  /**
     * Adds an extra node to the right of the form label without
     * being contained inside the form label. Good for things
     * like documentation links.
     */
  labelAppend: PropTypes.any,
  id: PropTypes.string,
  isInvalid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired]),

  /**
     *  Adds a single node/string or an array of nodes/strings below the input
     */

  /**
     *  Adds a single node/string or an array of nodes/strings below the input
     */
  helpText: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired])
};

try {
  OuiFormRow.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFormRow',
    methods: [],
    props: {
      labelType: {
        defaultValue: {
          value: 'label'
        },
        description: "Defaults to rendering a `<label>` but if passed `'legend'` for labelType,\n" + 'will render both a `<legend>` and the surrounding container as a `<fieldset>`',
        name: 'labelType',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"label" | "legend"',
          value: [{
            value: '"label"'
          }, {
            value: '"legend"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      display: {
        defaultValue: {
          value: 'row'
        },
        description: 'When `rowCompressed`, just tightens up the spacing;\n' + 'Set to `columnCompressed` if compressed\n' + 'and horizontal layout is needed.\n' + 'Set to `center` or `centerCompressed` to align non-input\n' + 'content better with inline rows.\n' + 'Set to `columnCompressedSwitch` if the form control being passed\n' + 'as the child is a switch.',
        name: 'display',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "row" | "rowCompressed" | "columnCompressed" | "centerCompressed" | "columnCompressedSwitch"',
          value: [{
            value: '"center"'
          }, {
            value: '"row"'
          }, {
            value: '"rowCompressed"'
          }, {
            value: '"columnCompressed"'
          }, {
            value: '"centerCompressed"'
          }, {
            value: '"columnCompressedSwitch"'
          }]
        }
      },
      hasEmptyLabelSpace: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'hasEmptyLabelSpace',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      describedByIds: {
        defaultValue: {
          value: '[]'
        },
        description: "IDs of additional elements that should be part of children's `aria-describedby`",
        name: 'describedByIds',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      hasChildLabel: {
        defaultValue: {
          value: 'true'
        },
        description: 'Escape hatch to not render duplicate labels if the child also renders a label',
        name: 'hasChildLabel',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactElement to render as this component's content",
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      labelAppend: {
        defaultValue: null,
        description: 'Adds an extra node to the right of the form label without\n' + 'being contained inside the form label. Good for things\n' + 'like documentation links.',
        name: 'labelAppend',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      error: {
        defaultValue: null,
        description: '',
        name: 'error',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number | boolean | {} | ReactElement<any, string | ((props: any) => ReactElement<any, string | ... | (new (props: any) => Component<any, any, any>)>) | (new (props: any) => Component<...>)> | ReactNodeArray | ReactPortal | ReactNode[]'
        }
      },
      helpText: {
        defaultValue: null,
        description: 'Adds a single node/string or an array of nodes/strings below the input',
        name: 'helpText',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number | boolean | {} | ReactElement<any, string | ((props: any) => ReactElement<any, string | ... | (new (props: any) => Component<any, any, any>)>) | (new (props: any) => Component<...>)> | ReactNodeArray | ReactPortal | ReactNode[]'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}