/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import classNames from 'classnames';
import PropTypes from "prop-types";
import React from 'react';
import { OuiScreenReaderOnly } from '../accessibility';
import { keysOf } from '../common';
import { OuiIcon } from '../icon';
import { useI18nCompleteStep, useI18nDisabledStep, useI18nErrorsStep, useI18nIncompleteStep, useI18nStep, useI18nWarningStep, useI18nLoadingStep } from './step_strings';
import { OuiLoadingSpinner } from '../loading';
var statusToClassNameMap = {
  incomplete: 'ouiStepNumber--incomplete',
  disabled: 'ouiStepNumber--disabled',
  loading: 'ouiStepNumber--loading',
  warning: 'ouiStepNumber--warning',
  danger: 'ouiStepNumber--danger',
  complete: 'ouiStepNumber--complete'
};
export var STATUS = keysOf(statusToClassNameMap);
export var OuiStepNumber = ({
  className,
  status,
  number,
  isHollow,
  titleSize,
  ...rest
}) => {
  const stepAriaLabel = useI18nStep({
    number
  });
  const completeAriaLabel = useI18nCompleteStep({
    number
  });
  const warningAriaLabel = useI18nWarningStep({
    number
  });
  const errorsAriaLabel = useI18nErrorsStep({
    number
  });
  const incompleteAriaLabel = useI18nIncompleteStep({
    number
  });
  const disabledAriaLabel = useI18nDisabledStep({
    number
  });
  const loadingAriaLabel = useI18nLoadingStep({
    number
  });
  const classes = classNames('ouiStepNumber', status ? statusToClassNameMap[status] : undefined, {
    'ouiStepNumber-isHollow': isHollow
  }, className);
  const iconSize = titleSize === 'xs' ? 's' : 'm';
  let screenReaderText = stepAriaLabel;
  if (status === 'incomplete') screenReaderText = incompleteAriaLabel;else if (status === 'disabled') screenReaderText = disabledAriaLabel;else if (status === 'loading') screenReaderText = loadingAriaLabel;
  let numberOrIcon = <>
      <OuiScreenReaderOnly>
        <span>{screenReaderText}</span>
      </OuiScreenReaderOnly>
      <span className="ouiStepNumber__number" aria-hidden="true">
        {number}
      </span>
    </>;

  if (status === 'complete') {
    numberOrIcon = <OuiIcon type="check" className="ouiStepNumber__icon" size={iconSize} aria-label={completeAriaLabel} />;
  } else if (status === 'warning') {
    numberOrIcon = <OuiIcon type="alert" className="ouiStepNumber__icon" size={iconSize} aria-label={warningAriaLabel} />;
  } else if (status === 'danger') {
    numberOrIcon = <OuiIcon type="cross" className="ouiStepNumber__icon" size={iconSize} aria-label={errorsAriaLabel} />;
  } else if (status === 'loading') {
    numberOrIcon = <>
        <OuiScreenReaderOnly>
          <span>{screenReaderText}</span>
        </OuiScreenReaderOnly>
        <OuiLoadingSpinner className="ouiStepNumber__loader" size={iconSize === 's' ? 'l' : 'xl'} />
      </>;
  }

  return <span className={classes} {...rest}>
      {numberOrIcon}
    </span>;
};
OuiStepNumber.propTypes = {
  /**
     * May replace the number provided in props.number with alternate styling
     */
  status: PropTypes.any,
  number: PropTypes.number,

  /**
     * Uses a border and removes the step number.
     */
  isHollow: PropTypes.bool,

  /**
     * Title sizing equivalent to OuiTitle, but only `m`, `s` and `xs`. Defaults to `s`
     */
  titleSize: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiStepNumber.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiStepNumber',
    methods: [],
    props: {
      status: {
        defaultValue: null,
        description: 'May replace the number provided in props.number with alternate styling',
        name: 'status',
        parent: {
          fileName: 'oui/src/components/steps/step_number.tsx',
          name: 'OuiStepNumberProps'
        },
        declarations: [{
          fileName: 'oui/src/components/steps/step_number.tsx',
          name: 'OuiStepNumberProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"warning" | "danger" | "disabled" | "loading" | "complete" | "incomplete"',
          value: [{
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"disabled"'
          }, {
            value: '"loading"'
          }, {
            value: '"complete"'
          }, {
            value: '"incomplete"'
          }]
        }
      },
      number: {
        defaultValue: null,
        description: '',
        name: 'number',
        parent: {
          fileName: 'oui/src/components/steps/step_number.tsx',
          name: 'OuiStepNumberProps'
        },
        declarations: [{
          fileName: 'oui/src/components/steps/step_number.tsx',
          name: 'OuiStepNumberProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      isHollow: {
        defaultValue: null,
        description: 'Uses a border and removes the step number.',
        name: 'isHollow',
        parent: {
          fileName: 'oui/src/components/steps/step_number.tsx',
          name: 'OuiStepNumberProps'
        },
        declarations: [{
          fileName: 'oui/src/components/steps/step_number.tsx',
          name: 'OuiStepNumberProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      titleSize: {
        defaultValue: null,
        description: 'Title sizing equivalent to OuiTitle, but only `m`, `s` and `xs`. Defaults to `s`',
        name: 'titleSize',
        parent: {
          fileName: 'oui/src/components/steps/step_number.tsx',
          name: 'OuiStepNumberProps'
        },
        declarations: [{
          fileName: 'oui/src/components/steps/step_number.tsx',
          name: 'OuiStepNumberProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiStepNumberProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}