/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiFieldSearch } from '../../form';
import { getMatchingOptions } from '../matching_options';
export class OuiSelectableSearch extends Component {
  static defaultProps = {
    defaultValue: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      searchValue: props.defaultValue
    };
  }

  componentDidMount() {
    const {
      searchValue
    } = this.state;
    const matchingOptions = getMatchingOptions(this.props.options, searchValue, this.props.isPreFiltered);
    this.props.onChange(matchingOptions, searchValue);
  }

  onSearchChange = value => {
    if (value !== this.state.searchValue) {
      this.setState({
        searchValue: value
      }, () => {
        const matchingOptions = getMatchingOptions(this.props.options, value, this.props.isPreFiltered);
        this.props.onChange(matchingOptions, value);
      });
    }
  };

  render() {
    const {
      className,
      onChange,
      options,
      defaultValue,
      listId,
      placeholder,
      isPreFiltered,
      ...rest
    } = this.props;
    const classes = classNames('ouiSelectableSearch', className);
    const ariaPropsIfListIsPresent = listId ? {
      role: 'combobox',
      'aria-autocomplete': 'list',
      'aria-expanded': true,
      'aria-controls': listId,
      'aria-owns': listId // legacy attribute but shims support for nearly everything atm

    } : undefined;
    return <OuiFieldSearch className={classes} placeholder={placeholder} onSearch={this.onSearchChange} incremental defaultValue={defaultValue} fullWidth autoComplete="off" aria-haspopup="listbox" {...ariaPropsIfListIsPresent} {...rest} />;
  }

}
OuiSelectableSearch.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Passes back (matchingOptions, searchValue)
       */
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Optional `boolean`.
       * Set to `true` to indicate object is just a grouping label, not a selectable item
       */
    isGroupLabel: PropTypes.oneOfType([PropTypes.oneOf([true]).isRequired, PropTypes.oneOf([false])]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Visible label of option.
       * Must be unique across items if `key` is not supplied
       */
    label: PropTypes.string,

    /**
       * Optionally change the searchable term by passing a different string other than the `label`.
       * Best used when creating a custom `optionRender` to separate the label from metadata but allowing to search on both
       */
    searchableLabel: PropTypes.string,

    /**
       * Must be unique across items.
       * Will be used to match options instead of `label`
       */
    key: PropTypes.string,

    /**
       * Leave `undefined` to indicate not selected,
       * 'on' to indicate inclusion and
       * 'off' to indicate exclusion
       */
    checked: PropTypes.oneOf(["on", "off", undefined]),
    disabled: PropTypes.bool,

    /**
       * Node to add between the selection icon and the label
       */
    prepend: PropTypes.node,

    /**
       * Node to add to the far right of the item
       */
    append: PropTypes.node,
    ref: PropTypes.func
  }).isRequired).isRequired,
  defaultValue: PropTypes.string.isRequired,

  /**
       * The id of the visible list to create the appropriate aria controls
       */
  listId: PropTypes.string,
  isPreFiltered: PropTypes.bool.isRequired
};

try {
  OuiSelectableSearch.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSelectableSearch',
    methods: [],
    props: {
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      placeholder: {
        defaultValue: null,
        description: '',
        name: 'placeholder',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(node: HTMLInputElement) => void'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      onSearch: {
        defaultValue: null,
        description: 'Called when the user presses [Enter] OR on change if the incremental prop is `true`.\n' + "If you don't need the on[Enter] functionality, prefer using onChange",
        name: 'onSearch',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(value: string) => void'
        }
      },
      incremental: {
        defaultValue: null,
        description: 'When `true` the search will be executed (that is, the `onSearch` will be called) as the\n' + 'user types.',
        name: 'incremental',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isClearable: {
        defaultValue: null,
        description: 'Shows a button that quickly clears any input',
        name: 'isClearable',
        parent: {
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'Passes back (matchingOptions, searchValue)',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/selectable/selectable_search/selectable_search.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(matchingOptions: ExclusiveUnion<OuiSelectableGroupLabelOption<T>, OuiSelectableLIOption<T>>[], searchValue: string) => void'
        }
      },
      options: {
        defaultValue: null,
        description: '',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/selectable/selectable_search/selectable_search.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ExclusiveUnion<OuiSelectableGroupLabelOption<T>, OuiSelectableLIOption<T>>[]'
        }
      },
      listId: {
        defaultValue: null,
        description: 'The id of the visible list to create the appropriate aria controls',
        name: 'listId',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/selectable/selectable_search/selectable_search.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isPreFiltered: {
        defaultValue: null,
        description: '',
        name: 'isPreFiltered',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/selectable/selectable_search/selectable_search.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiFieldSearchProps', 'HTMLAttributes', 'InputHTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}