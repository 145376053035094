/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiRangeSlider = forwardRef(({
  className,
  disabled,
  id,
  max,
  min,
  name,
  step,
  onChange,
  tabIndex,
  value,
  style,
  showTicks,
  showRange,
  hasFocus,
  compressed,
  ...rest
}, ref) => {
  const classes = classNames('ouiRangeSlider', {
    'ouiRangeSlider--hasTicks': showTicks,
    'ouiRangeSlider--hasFocus': hasFocus,
    'ouiRangeSlider--hasRange': showRange,
    'ouiRangeSlider--compressed': compressed
  }, className);
  return <input ref={ref} type="range" id={id} name={name} className={classes} min={min} max={max} step={step} value={value} disabled={disabled} onChange={onChange} style={style} tabIndex={tabIndex} {...rest} />;
});
OuiRangeSlider.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  compressed: PropTypes.bool,
  hasFocus: PropTypes.bool,
  showRange: PropTypes.bool,
  showTicks: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  onChange: PropTypes.any
};
OuiRangeSlider.displayName = 'OuiRangeSlider';

try {
  OuiRangeSlider.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeSlider',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/range/range_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasFocus: {
        defaultValue: null,
        description: '',
        name: 'hasFocus',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/range/range_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showRange: {
        defaultValue: null,
        description: '',
        name: 'showRange',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/range/range_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: '',
        name: 'showTicks',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/range/range_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLInputElement>'
        }
      }
    },
    extendedInterfaces: ['InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'Attributes']
  };
} catch (e) {}