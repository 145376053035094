/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiFormControlLayout } from '../form_control_layout';
import { OuiValidatableControl } from '../validatable_control';
export var OuiSelect = ({
  className,
  options = [],
  id,
  name,
  inputRef,
  isInvalid,
  fullWidth = false,
  isLoading = false,
  hasNoInitialSelection = false,
  defaultValue,
  compressed = false,
  value: _value,
  prepend,
  append,
  onMouseUp,
  ...rest
}) => {
  // if this is injecting an empty option for `hasNoInitialSelection` then
  // value needs to fallback to an empty string to interact properly with `defaultValue`
  const value = hasNoInitialSelection ? _value ?? '' : _value;

  const handleMouseUp = e => {
    // Normalizes cross-browser mouse eventing by preventing propagation,
    // notably for use in conjunction with OuiOutsideClickDetector.
    // See https://github.com/elastic/eui/pull/1926 for full discussion on
    // rationale and alternatives should this intervention become problematic.
    e.nativeEvent.stopImmediatePropagation();
    if (onMouseUp) onMouseUp(e);
  };

  const classes = classNames('ouiSelect', {
    'ouiSelect--fullWidth': fullWidth,
    'ouiSelect--compressed': compressed,
    'ouiSelect--inGroup': prepend || append,
    'ouiSelect-isLoading': isLoading
  }, className);
  let emptyOptionNode;

  if (hasNoInitialSelection) {
    emptyOptionNode = <option value="" disabled hidden style={{
      display: 'none'
    }}>
        &nbsp;
      </option>;
  } // React HTML input can not have both value and defaultValue properties.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values


  let selectDefaultValue;

  if (value == null) {
    selectDefaultValue = defaultValue || '';
  }

  const icon = {
    type: 'arrowDown',
    side: 'right'
  };
  return <OuiFormControlLayout icon={icon} fullWidth={fullWidth} isLoading={isLoading} compressed={compressed} prepend={prepend} append={append} inputId={id}>
      <OuiValidatableControl isInvalid={isInvalid}>
        <select id={id} name={name} className={classes} ref={inputRef} defaultValue={selectDefaultValue} value={value} onMouseUp={handleMouseUp} {...rest}>
          {emptyOptionNode}
          {options.map((option, index) => {
          const {
            text,
            ...rest
          } = option;
          return <option {...rest} key={index}>
                {text}
              </option>;
        })}
        </select>
      </OuiValidatableControl>
    </OuiFormControlLayout>;
};
OuiSelect.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node.isRequired
  }).isRequired),
  isInvalid: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,

  /**
       * Simulates no selection by creating an empty, selected, hidden first option
       */
  hasNoInitialSelection: PropTypes.bool,
  inputRef: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),

  /**
       * when `true` creates a shorter height input
       */
  compressed: PropTypes.bool,

  /**
       * Creates an input group with element(s) coming before select.
       * `string` | `ReactElement` or an array of these
       */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

  /**
       * Creates an input group with element(s) coming after select.
       * `string` | `ReactElement` or an array of these
       */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired])
};

try {
  OuiSelect.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSelect',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      options: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiSelectOption[]'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasNoInitialSelection: {
        defaultValue: {
          value: 'false'
        },
        description: 'Simulates no selection by creating an empty, selected, hidden first option',
        name: 'hasNoInitialSelection',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLSelectElement>'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before select.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after select.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'SelectHTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}