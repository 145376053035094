/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiRadio, OuiCheckbox } from '../../form';
import { OuiSplitPanel } from '../../panel';
export var OuiCheckableCard = ({
  children,
  className,
  checkableType = 'radio',
  label,
  checked,
  disabled,
  hasShadow,
  hasBorder = true,
  ...rest
}) => {
  const {
    id
  } = rest;
  const labelEl = useRef(null);
  const classes = classNames('ouiCheckableCard', {
    'ouiCheckableCard-isChecked': checked,
    'ouiCheckableCard-isDisabled': disabled
  }, className);
  let checkableElement;

  if (checkableType === 'radio') {
    checkableElement = <OuiRadio checked={checked} disabled={disabled} {...rest} />;
  } else {
    checkableElement = <OuiCheckbox checked={checked} disabled={disabled} {...rest} />;
  }

  const labelClasses = classNames('ouiCheckableCard__label', {
    'ouiCheckableCard__label-isDisabled': disabled
  });

  const onChangeAffordance = () => {
    if (labelEl.current) {
      labelEl.current.click();
    }
  };

  return <OuiSplitPanel.Outer responsive={false} hasShadow={hasShadow} hasBorder={hasBorder} direction="row" className={classes}>
      <OuiSplitPanel.Inner // Bubbles up the change event when clicking on the whole div for extra affordance
    onClick={disabled ? undefined : onChangeAffordance} color={checked ? 'primary' : 'subdued'} grow={false}>
        {checkableElement}
      </OuiSplitPanel.Inner>
      <OuiSplitPanel.Inner>
        <label ref={labelEl} className={labelClasses} htmlFor={id} aria-describedby={children ? `${id}-details` : undefined}>
          {label}
        </label>
        {children && <div id={`${id}-details`} className="ouiCheckableCard__children">
            {children}
          </div>}
      </OuiSplitPanel.Inner>
    </OuiSplitPanel.Outer>;
};
OuiCheckableCard.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  hasShadow: PropTypes.any,
  hasBorder: PropTypes.any
};

try {
  OuiCheckableCard.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiCheckableCard',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'oui/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }, {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: true,
        type: {
          name: '(event: ChangeEvent<HTMLInputElement>) => void'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'oui/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }, {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: {
          fileName: 'oui/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }, {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      labelProps: {
        defaultValue: null,
        description: 'Object of props passed to the <label/>',
        name: 'labelProps',
        parent: {
          fileName: 'oui/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }, {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & LabelHTMLAttributes<HTMLLabelElement>'
        }
      },
      checkableType: {
        defaultValue: {
          value: 'radio'
        },
        description: 'Whether the control is a radio button or checkbox',
        name: 'checkableType',
        parent: {
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardAsCheckboxProps'
        },
        declarations: [{
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardAsCheckboxProps'
        }, {
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardAsRadioProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"checkbox" | "radio"',
          value: [{
            value: '"checkbox"'
          }, {
            value: '"radio"'
          }]
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardBaseProps'
        },
        declarations: [{
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardBaseProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: {
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardBaseProps'
        },
        declarations: [{
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardBaseProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      hasShadow: {
        defaultValue: null,
        description: '',
        name: 'hasShadow',
        parent: {
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardBaseProps'
        },
        declarations: [{
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardBaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasBorder: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'hasBorder',
        parent: {
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardBaseProps'
        },
        declarations: [{
          fileName: 'oui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'OuiCheckableCardBaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiCheckboxProps', 'CommonProps', 'OuiCheckableCardAsCheckboxProps', 'OuiCheckableCardBaseProps']
  };
} catch (e) {}