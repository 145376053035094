/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { useOuiTour } from './useOuiTour';
import PropTypes from "prop-types";
export var OuiTour = ({
  children,
  steps,
  initialState
}) => {
  const [stepProps, actions, state] = useOuiTour(steps, initialState);
  return children(stepProps, actions, state);
};
OuiTour.propTypes = {
  children: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  initialState: PropTypes.shape({
    currentTourStep: PropTypes.number.isRequired,
    isTourActive: PropTypes.bool.isRequired,
    tourPopoverWidth: PropTypes.number.isRequired,
    tourSubtitle: PropTypes.string.isRequired
  }).isRequired
};

try {
  OuiTour.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTour',
    methods: [],
    props: {
      steps: {
        defaultValue: null,
        description: '',
        name: 'steps',
        parent: {
          fileName: 'oui/src/components/tour/tour.tsx',
          name: 'OuiTourProps'
        },
        declarations: [{
          fileName: 'oui/src/components/tour/tour.tsx',
          name: 'OuiTourProps'
        }],
        required: true,
        type: {
          name: 'OuiStatelessTourStep[]'
        }
      },
      initialState: {
        defaultValue: null,
        description: '',
        name: 'initialState',
        parent: {
          fileName: 'oui/src/components/tour/tour.tsx',
          name: 'OuiTourProps'
        },
        declarations: [{
          fileName: 'oui/src/components/tour/tour.tsx',
          name: 'OuiTourProps'
        }],
        required: true,
        type: {
          name: 'OuiTourState'
        }
      }
    },
    extendedInterfaces: ['OuiTourProps']
  };
} catch (e) {}