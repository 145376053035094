/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiRadio = ({
  className,
  id,
  name,
  checked,
  label,
  value,
  onChange,
  disabled,
  compressed,
  autoFocus,
  labelProps,
  ...rest
}) => {
  const classes = classNames('ouiRadio', {
    'ouiRadio--noLabel': !label,
    'ouiRadio--compressed': compressed
  }, className);
  const labelClasses = classNames('ouiRadio__label', labelProps?.className);
  let optionalLabel;

  if (label) {
    optionalLabel = <label {...labelProps} className={labelClasses} htmlFor={id}>
        {label}
      </label>;
  }

  return <div className={classes} {...rest}>
      <input className="ouiRadio__input" type="radio" id={id} name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} autoFocus={autoFocus} />
      <div className="ouiRadio__circle" />

      {optionalLabel}
    </div>;
};
OuiRadio.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  autoFocus: PropTypes.bool,

  /**
     * When `true` creates a shorter height radio row
     */

  /**
     * When `true` creates a shorter height radio row
     */

  /**
     * When `true` creates a shorter height radio row
     */
  compressed: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.any.isRequired,

  /**
     * Object of props passed to the <label/>
     */

  /**
     * Object of props passed to the <label/>
     */

  /**
     * Object of props passed to the <label/>
     */
  labelProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  }),
  label: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.string.isRequired])
};

try {
  OuiRadio.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRadio',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'idWithLabel'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'idWithLabel'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'withId'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'withId'
        }, {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'idWithLabel'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      autoFocus: {
        defaultValue: null,
        description: '',
        name: 'autoFocus',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'When `true` creates a shorter height radio row',
        name: 'compressed',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: true,
        type: {
          name: '(event: ChangeEvent<HTMLInputElement>) => void'
        }
      },
      labelProps: {
        defaultValue: null,
        description: 'Object of props passed to the <label/>',
        name: 'labelProps',
        parent: {
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & LabelHTMLAttributes<HTMLLabelElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'idWithLabel', 'withId', 'RadioProps']
  };
} catch (e) {}