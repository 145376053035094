/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { LEFT_ALIGNMENT, RIGHT_ALIGNMENT, CENTER_ALIGNMENT } from '../../services';
import { resolveWidthAsStyle } from './utils';
import { useIsWithinBreakpoints } from '../../services/hooks/useIsWithinBreakpoints';
export var OuiTableRowCell = ({
  align = LEFT_ALIGNMENT,
  children,
  className,
  truncateText,
  setScopeRow,
  showOnHover,
  textOnly = true,
  hasActions,
  isExpander,
  mobileOptions = {
    show: true
  },
  // Soon to be deprecated for {...mobileOptions}
  header,
  hideForMobile,
  isMobileHeader,
  isMobileFullWidth,
  style,
  width,
  ...rest
}) => {
  const cellClasses = classNames('ouiTableRowCell', {
    'ouiTableRowCell--hasActions': hasActions,
    'ouiTableRowCell--isExpander': isExpander,
    'ouiTableRowCell--hideForDesktop': mobileOptions.only || isMobileHeader,
    'ouiTableRowCell--enlargeForMobile': mobileOptions.enlarge || isMobileHeader,
    'ouiTableRowCell--isMobileFullWidth': mobileOptions.fullWidth || isMobileFullWidth || isMobileHeader
  });
  const contentClasses = classNames('ouiTableCellContent', className, {
    'ouiTableCellContent--alignRight': align === RIGHT_ALIGNMENT,
    'ouiTableCellContent--alignCenter': align === CENTER_ALIGNMENT,
    'ouiTableCellContent--showOnHover': showOnHover,
    'ouiTableCellContent--truncateText': truncateText,
    // We're doing this rigamarole instead of creating `ouiTableCellContent--textOnly` for BWC
    // purposes for the time-being.
    'ouiTableCellContent--overflowingContent': textOnly !== true
  });
  const mobileContentClasses = classNames('ouiTableCellContent', className, {
    'ouiTableCellContent--alignRight': mobileOptions.align === RIGHT_ALIGNMENT || align === RIGHT_ALIGNMENT,
    'ouiTableCellContent--alignCenter': mobileOptions.align === CENTER_ALIGNMENT || align === RIGHT_ALIGNMENT,
    'ouiTableCellContent--showOnHover': mobileOptions.showOnHover || showOnHover,
    'ouiTableCellContent--truncateText': mobileOptions.truncateText || truncateText,
    // We're doing this rigamarole instead of creating `ouiTableCellContent--textOnly` for BWC
    // purposes for the time-being.
    'ouiTableCellContent--overflowingContent': mobileOptions.textOnly !== true || textOnly !== true
  });
  const childClasses = classNames({
    ouiTableCellContent__text: textOnly === true,
    ouiTableCellContent__hoverItem: showOnHover
  });
  const widthValue = useIsWithinBreakpoints(['xs', 's', 'm']) && mobileOptions.width ? mobileOptions.width : width;
  const styleObj = resolveWidthAsStyle(style, widthValue);

  function modifyChildren(children) {
    let modifiedChildren = children;

    if (textOnly === true) {
      modifiedChildren = <span className={childClasses}>{children}</span>;
    } else if (React.isValidElement(children)) {
      modifiedChildren = React.Children.map(children, child => React.cloneElement(child, {
        className: classNames(child.props.className, childClasses)
      }));
    }

    return modifiedChildren;
  }

  const childrenNode = modifyChildren(children);
  const hideForMobileClasses = 'ouiTableRowCell--hideForMobile';
  const showForMobileClasses = 'ouiTableRowCell--hideForDesktop';
  const Element = setScopeRow ? 'th' : 'td';
  const sharedProps = {
    scope: setScopeRow ? 'row' : undefined,
    style: styleObj,
    ...rest
  };

  if (mobileOptions.show === false || hideForMobile) {
    return <Element className={`${cellClasses} ${hideForMobileClasses}`} {...sharedProps}>
        <div className={contentClasses}>{childrenNode}</div>
      </Element>;
  } else {
    return <Element className={cellClasses} {...sharedProps}>
        {
        /* Mobile-only header */
      }
        {(mobileOptions.header || header) && !isMobileHeader && <div className={`ouiTableRowCell__mobileHeader ${showForMobileClasses}`}>
            {mobileOptions.header || header}
          </div>}

        {
        /* Content depending on mobile render existing */
      }
        {mobileOptions.render ? <Fragment>
            <div className={`${mobileContentClasses} ${showForMobileClasses}`}>
              {modifyChildren(mobileOptions.render)}
            </div>
            <div className={`${contentClasses} ${hideForMobileClasses}`}>
              {childrenNode}
            </div>
          </Fragment> : <div className={contentClasses}>{childrenNode}</div>}
      </Element>;
  }
};
OuiTableRowCell.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  align: PropTypes.oneOf(["left", "right", "center"]),

  /**
     * Don't allow line breaks within cells
     */
  showOnHover: PropTypes.bool,

  /**
     * Setting `textOnly` to `false` will break words unnecessarily on FF and
     * IE.  To combat this problem on FF, wrap contents with the css utility
     * `.oui-textBreakWord`.
     */
  textOnly: PropTypes.bool,

  /**
     * _Should only be used for action cells_
     */
  truncateText: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),

  /**
     * Indicates if the column is dedicated to icon-only actions (currently
     * affects mobile only)
     */
  hasActions: PropTypes.bool,

  /**
     * _DEPRECATED: use `mobileOptions.header`_
     * The column's header title for use in mobile view (will be added as a
     * data-attr)
     */
  header: PropTypes.string,

  /**
     * _DEPRECATED: use `mobileOptions.show = false`_
     * Indicates if the column should not show for mobile users (typically
     * hidden because a custom mobile header utilizes the column's contents)
     */
  hideForMobile: PropTypes.bool,

  /**
     * Indicates if the column is dedicated as the expandable row toggle
     */
  isExpander: PropTypes.bool,

  /**
     * _DEPRECATED: use `mobileOptions.fullWidth`_
     * Allocates 100% of the width of the container in mobile view
     * (typically cells are contained to 50%)
     */
  isMobileFullWidth: PropTypes.bool,

  /**
     * _DEPRECATED: use `mobileOptions.only = true & mobileOptions.header = * false`_
     * Indicates if the column was created to be the row's heading in mobile
     * view.  It won't display column's header inline and it the column will
     * be hidden at larger screens)
     */
  isMobileHeader: PropTypes.bool,

  /**
     * Mobile options for displaying differently at small screens
     */
  mobileOptions: PropTypes.shape({
    /**
       * If false, will not render the cell at all for mobile
       */
    show: PropTypes.bool,

    /**
       * Only show for mobile? If true, will not render the column at all for desktop
       */
    only: PropTypes.bool,

    /**
       * Custom render/children if different from desktop
       */
    render: PropTypes.node,

    /**
       * The column's header for use in mobile view (automatically passed down
       * when using `OuiBasicTable`).
       * Or pass `false` to not show a header at all.
       */
    header: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.bool.isRequired]),

    /**
       * Increase text size compared to rest of cells
       */
    enlarge: PropTypes.bool,

    /**
       * Allocates 100% of the width of the container in mobile view
       * (typically cells are contained to 50%)
       */
    fullWidth: PropTypes.bool,

    /**
       * Applies the value to the width of the cell in mobile view (typically 50%)
       */
    width: PropTypes.oneOfType([PropTypes.any, PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])]),

    /**
       * Horizontal alignment of the text in the cell
       */
    align: PropTypes.oneOf(["left", "right", "center"]),

    /**
       * Don't allow line breaks within cells
       */
    showOnHover: PropTypes.bool,

    /**
       * Setting `textOnly` to `false` will break words unnecessarily on FF and
       * IE.  To combat this problem on FF, wrap contents with the css utility
       * `.oui-textBreakWord`.
       */
    textOnly: PropTypes.bool,

    /**
       * _Should only be used for action cells_
       */
    truncateText: PropTypes.bool
  }),

  /**
     * Indicates whether the cell should be marked as the heading for its row
     */
  setScopeRow: PropTypes.bool
};

try {
  OuiTableRowCell.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTableRowCell',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      showOnHover: {
        defaultValue: null,
        description: "Don't allow line breaks within cells",
        name: 'showOnHover',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellSharedPropsShape'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellSharedPropsShape'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      textOnly: {
        defaultValue: {
          value: 'true'
        },
        description: 'Setting `textOnly` to `false` will break words unnecessarily on FF and\n' + 'IE.  To combat this problem on FF, wrap contents with the css utility\n' + '`.oui-textBreakWord`.',
        name: 'textOnly',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellSharedPropsShape'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellSharedPropsShape'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      truncateText: {
        defaultValue: null,
        description: '_Should only be used for action cells_',
        name: 'truncateText',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellSharedPropsShape'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellSharedPropsShape'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      width: {
        defaultValue: null,
        description: '',
        name: 'width',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellSharedPropsShape'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellSharedPropsShape'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      hasActions: {
        defaultValue: null,
        description: 'Indicates if the column is dedicated to icon-only actions (currently\n' + 'affects mobile only)',
        name: 'hasActions',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      header: {
        defaultValue: null,
        description: '_DEPRECATED: use `mobileOptions.header`_\n' + "The column's header title for use in mobile view (will be added as a\n" + 'data-attr)',
        name: 'header',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      hideForMobile: {
        defaultValue: null,
        description: '_DEPRECATED: use `mobileOptions.show = false`_\n' + 'Indicates if the column should not show for mobile users (typically\n' + "hidden because a custom mobile header utilizes the column's contents)",
        name: 'hideForMobile',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isExpander: {
        defaultValue: null,
        description: 'Indicates if the column is dedicated as the expandable row toggle',
        name: 'isExpander',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isMobileFullWidth: {
        defaultValue: null,
        description: '_DEPRECATED: use `mobileOptions.fullWidth`_\n' + 'Allocates 100% of the width of the container in mobile view\n' + '(typically cells are contained to 50%)',
        name: 'isMobileFullWidth',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isMobileHeader: {
        defaultValue: null,
        description: '_DEPRECATED: use `mobileOptions.only = true & mobileOptions.header = * false`_\n' + "Indicates if the column was created to be the row's heading in mobile\n" + "view.  It won't display column's header inline and it the column will\n" + 'be hidden at larger screens)',
        name: 'isMobileHeader',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      mobileOptions: {
        defaultValue: {
          value: '{\n    show: true,\n  }'
        },
        description: 'Mobile options for displaying differently at small screens',
        name: 'mobileOptions',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'OuiTableRowCellMobileOptionsShape & OuiTableRowCellSharedPropsShape'
        }
      },
      setScopeRow: {
        defaultValue: null,
        description: 'Indicates whether the cell should be marked as the heading for its row',
        name: 'setScopeRow',
        parent: {
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'oui/src/components/table/table_row_cell.tsx',
          name: 'OuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'TdHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiTableRowCellSharedPropsShape', 'OuiTableRowCellProps']
  };
} catch (e) {}