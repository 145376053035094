/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiPopover } from '../../../popover';
import { formatTimeString } from '../pretty_duration';
import { OuiDatePopoverContent } from './date_popover_content';
export var OuiDatePopoverButton = props => {
  const {
    position,
    isDisabled,
    isInvalid,
    needsUpdating,
    value,
    buttonProps,
    roundUp,
    onChange,
    locale,
    dateFormat,
    utcOffset,
    timeFormat,
    isOpen,
    onPopoverToggle,
    onPopoverClose,
    ...rest
  } = props;
  const classes = classNames(['ouiDatePopoverButton', `ouiDatePopoverButton--${position}`, {
    'ouiDatePopoverButton-isSelected': isOpen,
    'ouiDatePopoverButton-isInvalid': isInvalid,
    'ouiDatePopoverButton-needsUpdating': needsUpdating,
    'ouiDatePopoverButton-disabled': isDisabled
  }]);
  let title = value;

  if (isInvalid) {
    title = `Invalid date: ${title}`;
  } else if (needsUpdating) {
    title = `Update needed: ${title}`;
  }

  const button = <button onClick={onPopoverToggle} className={classes} title={title} disabled={isDisabled} data-test-subj={`superDatePicker${position}DatePopoverButton`} {...buttonProps}>
      {formatTimeString(value, dateFormat, roundUp, locale)}
    </button>;
  return <OuiPopover button={button} isOpen={isOpen} closePopover={onPopoverClose} anchorPosition={position === 'start' ? 'downLeft' : 'downRight'} display="block" panelPaddingSize="none" {...rest}>
      <OuiDatePopoverContent value={value} roundUp={roundUp} onChange={onChange} dateFormat={dateFormat} timeFormat={timeFormat} locale={locale} position={position} utcOffset={utcOffset} />
    </OuiPopover>;
};
OuiDatePopoverButton.propTypes = {
  className: PropTypes.string,
  buttonProps: PropTypes.any,
  dateFormat: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  needsUpdating: PropTypes.bool,
  locale: PropTypes.any,
  onChange: PropTypes.any.isRequired,
  onPopoverClose: PropTypes.any.isRequired,
  onPopoverToggle: PropTypes.func.isRequired,
  position: PropTypes.oneOf(["start", "end"]).isRequired,
  roundUp: PropTypes.bool,
  timeFormat: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  utcOffset: PropTypes.number
};
OuiDatePopoverButton.displayName = 'OuiDatePopoverButton';

try {
  OuiDatePopoverButton.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiDatePopoverButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonProps: {
        defaultValue: null,
        description: '',
        name: 'buttonProps',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'ButtonHTMLAttributes<HTMLButtonElement>'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: '',
        name: 'dateFormat',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isOpen: {
        defaultValue: null,
        description: '',
        name: 'isOpen',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      needsUpdating: {
        defaultValue: null,
        description: '',
        name: 'needsUpdating',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      locale: {
        defaultValue: null,
        description: '',
        name: 'locale',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'LocaleSpecifier'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: '(date: string, event?: any) => void'
        }
      },
      onPopoverClose: {
        defaultValue: null,
        description: '',
        name: 'onPopoverClose',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      onPopoverToggle: {
        defaultValue: null,
        description: '',
        name: 'onPopoverToggle',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: '(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      position: {
        defaultValue: null,
        description: '',
        name: 'position',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"end" | "start"',
          value: [{
            value: '"end"'
          }, {
            value: '"start"'
          }]
        }
      },
      roundUp: {
        defaultValue: null,
        description: '',
        name: 'roundUp',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      timeFormat: {
        defaultValue: null,
        description: '',
        name: 'timeFormat',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      utcOffset: {
        defaultValue: null,
        description: '',
        name: 'utcOffset',
        parent: {
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'OuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      }
    },
    extendedInterfaces: ['OuiDatePopoverButtonProps']
  };
} catch (e) {}