/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { cloneElement, Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
import { OuiIcon } from '../icon';
import { OuiToolTip } from '../tool_tip';
import { getSecureRelForTarget } from '../../services';
import { validateHref } from '../../services/security/href_validator';
var sizeToClassNameMap = {
  s: 'ouiContextMenuItem--small',
  m: null
};
export var SIZES = keysOf(sizeToClassNameMap);
var layoutAlignToClassNames = {
  center: null,
  top: 'ouiContextMenu__itemLayout--top',
  bottom: 'ouiContextMenu__itemLayout--bottom'
};
export var LAYOUT_ALIGN = keysOf(layoutAlignToClassNames);
export class OuiContextMenuItem extends Component {
  render() {
    const {
      children,
      className,
      hasPanel,
      icon,
      buttonRef,
      disabled: _disabled,
      layoutAlign = 'center',
      toolTipTitle,
      toolTipContent,
      toolTipPosition = 'right',
      href,
      target,
      rel,
      size,
      ...rest
    } = this.props;
    let iconInstance;
    const isHrefValid = !href || validateHref(href);
    const disabled = _disabled || !isHrefValid;

    if (icon) {
      switch (typeof icon) {
        case 'string':
          iconInstance = <OuiIcon type={icon} size="m" className="ouiContextMenu__icon" color="inherit" // forces the icon to inherit its parent color
          />;
          break;

        default:
          // Assume it's already an instance of an icon.
          iconInstance = cloneElement(icon, {
            className: 'ouiContextMenu__icon'
          });
      }
    }

    let arrow;

    if (hasPanel) {
      arrow = <OuiIcon type="arrowRight" size="m" className="ouiContextMenu__arrow" />;
    }

    const classes = classNames('ouiContextMenuItem', size && sizeToClassNameMap[size], className, {
      'ouiContextMenuItem-isDisabled': disabled
    });
    const layoutClasses = classNames('ouiContextMenu__itemLayout', layoutAlignToClassNames[layoutAlign]);
    const buttonInner = <span className={layoutClasses}>
        {iconInstance}
        <span className="ouiContextMenuItem__text">{children}</span>
        {arrow}
      </span>;
    let button; // <a> elements don't respect the `disabled` attribute. So if we're disabled, we'll just pretend
    // this is a button and piggyback off its disabled styles.

    if (href && !disabled) {
      const secureRel = getSecureRelForTarget({
        href,
        target,
        rel
      });
      button = <a className={classes} href={href} target={target} rel={secureRel} ref={buttonRef} {...rest}>
          {buttonInner}
        </a>;
    } else {
      button = <button disabled={disabled} className={classes} type="button" ref={buttonRef} {...rest}>
          {buttonInner}
        </button>;
    }

    if (toolTipContent) {
      return <OuiToolTip title={toolTipTitle ? toolTipTitle : null} content={toolTipContent} anchorClassName="oui-displayBlock" position={toolTipPosition}>
          {button}
        </OuiToolTip>;
    } else {
      return button;
    }
  }

}
OuiContextMenuItem.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired, PropTypes.any.isRequired]),
  hasPanel: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttonRef: PropTypes.any,

  /**
     * Required if using a tooltip. Add an optional tooltip on hover
     */
  toolTipContent: PropTypes.node,

  /**
     * Optional title for the tooltip
     */
  toolTipTitle: PropTypes.node,

  /**
     * Dictates the position of the tooltip.
     */
  toolTipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,

  /**
     * How to align icon with content of button
     */
  layoutAlign: PropTypes.oneOf(["center", "top", "bottom"]),

  /**
     * Reduce the size to `s` when in need of a more compressed menu
     */
  size: PropTypes.oneOf(["s", "m"])
};

try {
  OuiContextMenuItem.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiContextMenuItem',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'OuiContextMenuItemIcon'
        }
      },
      hasPanel: {
        defaultValue: null,
        description: '',
        name: 'hasPanel',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: '(event: any) => void'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement>'
        }
      },
      toolTipContent: {
        defaultValue: null,
        description: 'Required if using a tooltip. Add an optional tooltip on hover',
        name: 'toolTipContent',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      toolTipTitle: {
        defaultValue: null,
        description: 'Optional title for the tooltip',
        name: 'toolTipTitle',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      toolTipPosition: {
        defaultValue: null,
        description: 'Dictates the position of the tooltip.',
        name: 'toolTipPosition',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiPopoverPosition',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      rel: {
        defaultValue: null,
        description: '',
        name: 'rel',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      layoutAlign: {
        defaultValue: null,
        description: 'How to align icon with content of button',
        name: 'layoutAlign',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiContextMenuItemLayoutAlignment',
          value: [{
            value: '"center"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      },
      size: {
        defaultValue: null,
        description: 'Reduce the size to `s` when in need of a more compressed menu',
        name: 'size',
        parent: {
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/context_menu/context_menu_item.tsx',
          name: 'OuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiContextMenuItemProps']
  };
} catch (e) {}