/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import { useOuiI18n } from '../i18n';
import classNames from 'classnames';
import { OuiIcon } from '../icon';
export var OuiNotificationEventReadIcon = ({
  id,
  isRead,
  eventName,
  ...rest
}) => {
  const classesReadState = classNames('ouiNotificationEventReadIcon', {
    'ouiNotificationEventReadIcon--isRead': isRead
  });
  const readAria = useOuiI18n('ouiNotificationEventReadIcon.readAria', '{eventName} is read', {
    eventName
  });
  const unreadAria = useOuiI18n('ouiNotificationEventReadIcon.unreadAria', '{eventName} is unread', {
    eventName
  });
  const readTitle = useOuiI18n('ouiNotificationEventReadIcon.read', 'Read');
  const unreadTitle = useOuiI18n('ouiNotificationEventReadIcon.unread', 'Unread');
  const iconAriaLabel = isRead ? readAria : unreadAria;
  const iconTitle = isRead ? readTitle : unreadTitle;
  return <div className={classesReadState}>
      <OuiIcon type="dot" aria-label={iconAriaLabel} title={iconTitle} color="primary" data-test-subj={`${id}-notificationEventReadIcon`} {...rest} />
    </div>;
};
OuiNotificationEventReadIcon.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Descriptive title for naming the icon based on its use
       */
  title: PropTypes.string,

  /**
       * A unique identifier for the title element
       */
  titleId: PropTypes.string,

  /**
       * Its value should be one or more element IDs
       */
  "aria-labelledby": PropTypes.string,

  /**
       * Callback when the icon has been loaded & rendered
       */
  onIconLoad: PropTypes.func,
  id: PropTypes.string.isRequired,

  /**
     * Shows an indicator of the read state of the event
     */
  isRead: PropTypes.bool.isRequired,

  /**
     * A unique, human-friendly name for the event to be used in aria attributes (e.g. "alert-critical-01", "cloud-no-severity-12", etc..).
     */
  eventName: PropTypes.string.isRequired
};

try {
  OuiNotificationEventReadIcon.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiNotificationEventReadIcon',
    methods: [],
    props: {
      title: {
        defaultValue: null,
        description: 'Descriptive title for naming the icon based on its use',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'SVGAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      titleId: {
        defaultValue: null,
        description: 'A unique identifier for the title element',
        name: 'titleId',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onIconLoad: {
        defaultValue: null,
        description: 'Callback when the icon has been loaded & rendered',
        name: 'onIconLoad',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      isRead: {
        defaultValue: null,
        description: 'Shows an indicator of the read state of the event',
        name: 'isRead',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/notification/notification_event_read_icon.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      eventName: {
        defaultValue: null,
        description: 'A unique, human-friendly name for the event to be used in aria attributes (e.g. "alert-critical-01", "cloud-no-severity-12", etc..).',
        name: 'eventName',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/notification/notification_event_read_icon.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['SVGAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}