/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { getPositionFromStop, getStopFromMouseLocation, isColorInvalid, isStopInvalid } from './utils';
import { getChromaColor } from '../utils';
import { keys, useMouseMove } from '../../../services';
import { OuiButtonIcon } from '../../button';
import { OuiColorPicker } from '../color_picker';
import { OuiFlexGroup, OuiFlexItem } from '../../flex';
import { OuiFieldNumber, OuiFormRow } from '../../form';
import { OuiI18n } from '../../i18n';
import { OuiPopover } from '../../popover';
import { OuiScreenReaderOnly } from '../../accessibility';
import { OuiSpacer } from '../../spacer';
import { OuiRangeThumb } from '../../form/range/range_thumb';
export var OuiColorStopThumb = ({
  className,
  stop,
  color,
  onChange,
  onFocus,
  onRemove,
  globalMin,
  globalMax,
  localMin,
  localMax,
  min,
  max,
  isRangeMin = false,
  isRangeMax = false,
  parentRef,
  colorPickerMode,
  colorPickerShowAlpha,
  colorPickerSwatches,
  disabled,
  readOnly,
  isPopoverOpen,
  openPopover,
  closePopover,
  'data-index': dataIndex,
  'aria-valuetext': ariaValueText,
  valueInputProps = {}
}) => {
  const background = useMemo(() => {
    const chromaColor = getChromaColor(color, colorPickerShowAlpha);
    return chromaColor ? chromaColor.css() : undefined;
  }, [color, colorPickerShowAlpha]);
  const [hasFocus, setHasFocus] = useState(isPopoverOpen);
  const [colorIsInvalid, setColorIsInvalid] = useState(isColorInvalid(color, colorPickerShowAlpha));
  const [stopIsInvalid, setStopIsInvalid] = useState(isStopInvalid(stop));
  const [numberInputRef, setNumberInputRef] = useState(null);
  const popoverRef = useRef(null);
  useEffect(() => {
    if (isPopoverOpen && popoverRef && popoverRef.current) {
      popoverRef.current.positionPopoverFixed();
    }
  }, [isPopoverOpen, stop]);

  const getStopFromMouseLocationFn = location => {
    // Guard against `null` ref in usage
    return getStopFromMouseLocation(location, parentRef, globalMin, globalMax);
  };

  const getPositionFromStopFn = stop => {
    // Guard against `null` ref in usage
    return getPositionFromStop(stop, parentRef, globalMin, globalMax);
  };

  const handleOnRemove = () => {
    if (onRemove) {
      closePopover();
      onRemove();
    }
  };

  const handleFocus = () => {
    setHasFocus(true);

    if (onFocus) {
      onFocus();
    }
  };

  const setHasFocusTrue = () => setHasFocus(true);

  const setHasFocusFalse = () => setHasFocus(false);

  const handleColorChange = value => {
    setColorIsInvalid(isColorInvalid(value, colorPickerShowAlpha));
    onChange({
      stop,
      color: value
    });
  };

  const handleStopChange = value => {
    const willBeInvalid = value > localMax || value < localMin;

    if (willBeInvalid) {
      if (value > localMax) {
        value = localMax;
      }

      if (value < localMin) {
        value = localMin;
      }
    }

    setStopIsInvalid(isStopInvalid(value));
    onChange({
      stop: value,
      color
    });
  };

  const handleStopInputChange = e => {
    let value = parseFloat(e.target.value);
    const willBeInvalid = value > globalMax || value < globalMin;

    if (willBeInvalid) {
      if (value > globalMax && max != null) {
        value = globalMax;
      }

      if (value < globalMin && min != null) {
        value = globalMin;
      }
    }

    setStopIsInvalid(isStopInvalid(value));
    onChange({
      stop: value,
      color
    });
  };

  const handlePointerChange = (location, isFirstInteraction) => {
    if (isFirstInteraction) return; // Prevents change on the initial MouseDown event

    if (parentRef == null) {
      return;
    }

    const newStop = getStopFromMouseLocationFn(location);
    handleStopChange(newStop);
  };

  const handleKeyDown = event => {
    switch (event.key) {
      case keys.ENTER:
        event.preventDefault();
        openPopover();
        break;

      case keys.ARROW_LEFT:
        event.preventDefault();
        if (readOnly) return;
        handleStopChange(stop - 1);
        break;

      case keys.ARROW_RIGHT:
        event.preventDefault();
        if (readOnly) return;
        handleStopChange(stop + 1);
        break;
    }
  };

  const [handleMouseDown, handleInteraction] = useMouseMove(handlePointerChange);

  const handleOnMouseDown = e => {
    if (!readOnly) {
      handleMouseDown(e);
    }

    openPopover();
  };

  const handleTouchInteraction = e => {
    if (!readOnly) {
      handleInteraction(e);
    }
  };

  const handleTouchStart = e => {
    handleTouchInteraction(e);

    if (!isPopoverOpen) {
      openPopover();
    }
  };

  const classes = classNames('ouiColorStopPopover', {
    'ouiColorStopPopover-hasFocus': hasFocus || isPopoverOpen
  }, className);
  return <OuiPopover ref={popoverRef} className={classes} anchorClassName="ouiColorStopPopover__anchor" panelPaddingSize="s" isOpen={isPopoverOpen} closePopover={closePopover} initialFocus={numberInputRef || undefined} focusTrapProps={{
    clickOutsideDisables: false
  }} panelClassName={numberInputRef ? undefined : 'ouiColorStopPopover-isLoadingPanel'} style={{
    left: `${getPositionFromStopFn(stop)}%`
  }} button={<OuiI18n tokens={['ouiColorStopThumb.buttonAriaLabel', 'ouiColorStopThumb.buttonTitle']} defaults={['Press the Enter key to modify this stop. Press Escape to focus the group', 'Click to edit, drag to reposition']}>
          {([buttonAriaLabel, buttonTitle]) => {
      const ariaLabel = buttonAriaLabel;
      const title = buttonTitle;
      return <OuiRangeThumb data-test-subj="ouiColorStopThumb" data-index={dataIndex} min={localMin} max={localMax} value={stop} onFocus={handleFocus} onBlur={setHasFocusFalse} onMouseOver={setHasFocusTrue} onMouseOut={setHasFocusFalse} onKeyDown={handleKeyDown} onMouseDown={handleOnMouseDown} onTouchStart={handleTouchStart} onTouchMove={handleTouchInteraction} aria-valuetext={ariaValueText} aria-label={ariaLabel} title={title} className="ouiColorStopThumb" tabIndex={-1} style={{
        background
      }} disabled={disabled} />;
    }}
        </OuiI18n>}>
      <div className="ouiColorStop" data-test-subj="ouiColorStopPopover">
        <OuiScreenReaderOnly>
          <p aria-live="polite">
            <OuiI18n token="ouiColorStopThumb.screenReaderAnnouncement" default="A popup with a color stop edit form opened.
            Tab forward to cycle through form controls or press
            escape to close this popup." />
          </p>
        </OuiScreenReaderOnly>
        <OuiFlexGroup gutterSize="s" responsive={false}>
          <OuiFlexItem>
            <OuiI18n tokens={['ouiColorStopThumb.stopLabel', 'ouiColorStopThumb.stopErrorMessage']} defaults={['Stop value', 'Value is out of range']}>
              {([stopLabel, stopErrorMessage]) => <OuiFormRow label={stopLabel} display="rowCompressed" isInvalid={stopIsInvalid} error={stopIsInvalid ? stopErrorMessage : null}>
                  <OuiFieldNumber {...valueInputProps} inputRef={setNumberInputRef} compressed={true} readOnly={readOnly} min={isRangeMin || min == null ? undefined : localMin} max={isRangeMax || max == null ? undefined : localMax} value={isStopInvalid(stop) ? '' : stop} isInvalid={stopIsInvalid} onChange={handleStopInputChange} />
                </OuiFormRow>}
            </OuiI18n>
          </OuiFlexItem>
          {!readOnly && <OuiFlexItem grow={false}>
              <OuiFormRow display="rowCompressed" hasEmptyLabelSpace={true}>
                <OuiI18n token="ouiColorStopThumb.removeLabel" default="Remove this stop">
                  {removeLabel => <OuiButtonIcon iconType="trash" color="danger" aria-label={removeLabel} title={removeLabel} disabled={!onRemove} onClick={handleOnRemove} />}
                </OuiI18n>
              </OuiFormRow>
            </OuiFlexItem>}
        </OuiFlexGroup>
        {!readOnly && <OuiSpacer size="s" />}
        <OuiColorPicker readOnly={readOnly} onChange={handleColorChange} color={color} mode={readOnly ? 'secondaryInput' : colorPickerMode} swatches={colorPickerSwatches} display="inline" showAlpha={colorPickerShowAlpha} isInvalid={colorIsInvalid} secondaryInputDisplay={colorPickerMode === 'swatch' ? 'none' : 'bottom'} />
      </div>
    </OuiPopover>;
};
OuiColorStopThumb.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onRemove: PropTypes.func,
  globalMin: PropTypes.number.isRequired,
  globalMax: PropTypes.number.isRequired,
  localMin: PropTypes.number.isRequired,
  localMax: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  isRangeMin: PropTypes.bool,
  isRangeMax: PropTypes.bool,
  parentRef: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.oneOf([null])]),
  colorPickerMode: PropTypes.oneOf(["default", "swatch", "picker", "secondaryInput"]).isRequired,
  colorPickerShowAlpha: PropTypes.bool,
  colorPickerSwatches: PropTypes.arrayOf(PropTypes.string.isRequired),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isPopoverOpen: PropTypes.bool.isRequired,
  openPopover: PropTypes.func.isRequired,
  closePopover: PropTypes.func.isRequired,
  "data-index": PropTypes.string,
  "aria-valuetext": PropTypes.string,
  valueInputProps: PropTypes.any,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  stop: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

try {
  OuiColorStopThumb.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiColorStopThumb',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: '(colorStop: ColorStop) => void'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      onRemove: {
        defaultValue: null,
        description: '',
        name: 'onRemove',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      globalMin: {
        defaultValue: null,
        description: '',
        name: 'globalMin',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      globalMax: {
        defaultValue: null,
        description: '',
        name: 'globalMax',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      localMin: {
        defaultValue: null,
        description: '',
        name: 'localMin',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      localMax: {
        defaultValue: null,
        description: '',
        name: 'localMax',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      isRangeMin: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isRangeMin',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isRangeMax: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isRangeMax',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      parentRef: {
        defaultValue: null,
        description: '',
        name: 'parentRef',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'HTMLDivElement'
        }
      },
      colorPickerMode: {
        defaultValue: null,
        description: '',
        name: 'colorPickerMode',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: 'OuiColorPickerMode',
          value: [{
            value: '"default"'
          }, {
            value: '"swatch"'
          }, {
            value: '"picker"'
          }, {
            value: '"secondaryInput"'
          }]
        }
      },
      colorPickerShowAlpha: {
        defaultValue: null,
        description: '',
        name: 'colorPickerShowAlpha',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      colorPickerSwatches: {
        defaultValue: null,
        description: '',
        name: 'colorPickerSwatches',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: null,
        description: '',
        name: 'readOnly',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isPopoverOpen: {
        defaultValue: null,
        description: '',
        name: 'isPopoverOpen',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      openPopover: {
        defaultValue: null,
        description: '',
        name: 'openPopover',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      closePopover: {
        defaultValue: null,
        description: '',
        name: 'closePopover',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      'data-index': {
        defaultValue: null,
        description: '',
        name: 'data-index',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-valuetext': {
        defaultValue: null,
        description: '',
        name: 'aria-valuetext',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueInputProps: {
        defaultValue: {
          value: '{}'
        },
        description: '',
        name: 'valueInputProps',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'OuiColorStopThumbProps'
        }],
        required: false,
        type: {
          name: 'Partial<OuiFieldNumberProps>'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      stop: {
        defaultValue: null,
        description: '',
        name: 'stop',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'ColorStop'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'ColorStop'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: {
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'ColorStop'
        },
        declarations: [{
          fileName: 'oui/src/components/color_picker/color_stops/color_stop_thumb.tsx',
          name: 'ColorStop'
        }],
        required: true,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiColorStopThumbProps', 'CommonProps', 'ColorStop']
  };
} catch (e) {}