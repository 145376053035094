/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import classNames from 'classnames';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { OuiScreenReaderOnly } from '../accessibility';
import { OuiBreadcrumbs } from '../breadcrumbs';
import { OuiButton, OuiButtonIcon } from '../button';
import { OuiI18n } from '../i18n';
import { OuiIcon } from '../icon';
import { OuiPortal } from '../portal';
/**
 * Extends OuiButton excluding `size`. Requires `label` as the `children`.
 */

export class OuiControlBar extends Component {
  static defaultProps = {
    leftOffset: 0,
    rightOffset: 0,
    position: 'fixed',
    size: 'l',
    showContent: false,
    showOnMobile: false
  };
  bar = null;

  componentDidMount() {
    if (this.props.position === 'fixed') {
      const height = this.bar ? this.bar.clientHeight : -1;
      document.body.style.paddingBottom = `${height}px`;

      if (this.props.bodyClassName) {
        document.body.classList.add(this.props.bodyClassName);
      }
    }
  }

  componentWillUnmount() {
    document.body.style.paddingBottom = '';

    if (this.props.bodyClassName) {
      document.body.classList.remove(this.props.bodyClassName);
    }
  }

  state = {
    selectedTab: ''
  };

  render() {
    const {
      children,
      className,
      showContent,
      controls,
      size,
      leftOffset,
      rightOffset,
      maxHeight,
      showOnMobile,
      style,
      position,
      bodyClassName,
      landmarkHeading,
      ...rest
    } = this.props;
    const styles = { ...style,
      left: leftOffset,
      right: rightOffset,
      maxHeight: maxHeight
    };
    const classes = classNames('ouiControlBar', className, {
      'ouiControlBar-isOpen': showContent,
      'ouiControlBar--large': size === 'l',
      'ouiControlBar--medium': size === 'm',
      'ouiControlBar--small': size === 's',
      'ouiControlBar--fixed': position === 'fixed',
      'ouiControlBar--absolute': position === 'absolute',
      'ouiControlBar--relative': position === 'relative',
      'ouiControlBar--showOnMobile': showOnMobile
    });

    const handleTabClick = (control, e) => {
      this.setState({
        selectedTab: control.id
      }, () => {
        control.onClick(e);
      });
    };

    const controlItem = (control, index) => {
      switch (control.controlType) {
        case 'button':
          {
            const {
              controlType,
              id,
              color = 'ghost',
              label,
              className,
              ...rest
            } = control;
            return <OuiButton key={id + index} className={classNames('ouiControlBar__button', className)} color={color} {...rest} size="s">
              {label}
            </OuiButton>;
          }

        case 'icon':
          {
            const {
              controlType,
              id,
              iconType,
              className,
              color = 'ghost',
              onClick,
              href,
              ...rest
            } = control;
            return onClick || href ? <OuiButtonIcon key={id + index} className={classNames('ouiControlBar__buttonIcon', className)} onClick={onClick} href={href} color={color} {...rest} iconType={iconType} /> : <OuiIcon key={id + index} className={classNames('ouiControlBar__icon', className)} type={iconType} color={color} {...rest} />;
          }

        case 'divider':
          return <div key={control.controlType + index} className="ouiControlBar__divider" />;

        case 'spacer':
          return <div key={control.controlType + index} className="ouiControlBar__spacer" />;

        case 'text':
          {
            const {
              controlType,
              id,
              text,
              className,
              ...rest
            } = control;
            return <div key={id} className={classNames('ouiControlBar__text', className)} {...rest}>
              {text}
            </div>;
          }

        case 'tab':
          {
            const {
              controlType,
              id,
              label,
              onClick,
              className,
              ...rest
            } = control;
            const tabClasses = classNames('ouiControlBar__tab', {
              'ouiControlBar__tab--active': showContent && id === this.state.selectedTab
            }, className);
            return <button key={id + index} className={tabClasses} onClick={event => handleTabClick(control, event)} {...rest}>
              {label}
            </button>;
          }

        case 'breadcrumbs':
          {
            const {
              controlType,
              id,
              ...rest
            } = control;
            return <OuiBreadcrumbs className="ouiControlBar__breadcrumbs" key={control.id} {...rest} />;
          }
      }
    };

    const controlBar = <OuiI18n token="ouiControlBar.screenReaderHeading" default="Page level controls">
        {screenReaderHeading => // Though it would be better to use aria-labelledby than aria-label and not repeat the same string twice
      // A bug in voiceover won't list some landmarks in the rotor without an aria-label
      <section className={classes} aria-label={landmarkHeading ? landmarkHeading : screenReaderHeading} {...rest} style={styles}>
            <OuiScreenReaderOnly>
              <h2>{landmarkHeading ? landmarkHeading : screenReaderHeading}</h2>
            </OuiScreenReaderOnly>
            <div className="ouiControlBar__controls" ref={node => {
          this.bar = node;
        }}>
              {controls.map((control, index) => controlItem(control, index))}
            </div>
            {this.props.showContent ? <div className="ouiControlBar__content">{children}</div> : null}
          </section>}
      </OuiI18n>;
    return position === 'fixed' ? <OuiPortal>
        {controlBar}
        <OuiScreenReaderOnly>
          <p aria-live="assertive">
            {landmarkHeading ? <OuiI18n token="ouiControlBar.customScreenReaderAnnouncement" default="There is a new region landmark called {landmarkHeading} with page level controls at the end of the document." values={{
            landmarkHeading
          }} /> : <OuiI18n token="ouiControlBar.screenReaderAnnouncement" default="There is a new region landmark with page level controls at the end of the document." />}
          </p>
        </OuiScreenReaderOnly>
      </OuiPortal> : controlBar;
  }

}
OuiControlBar.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Show or hide the content area containing the `children`
       */
  showContent: PropTypes.bool,

  /**
       * An array of controls, actions, and layout spacers to display.
       * Accepts `'button' | 'tab' | 'breadcrumbs' | 'text' | 'icon' | 'spacer' | 'divider'`
       */
  controls: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.node]),
    buttonRef: PropTypes.any,
    controlType: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOf(["button"]).isRequired, PropTypes.oneOfType([PropTypes.oneOf(["breadcrumbs"]).isRequired, PropTypes.oneOf(["tab"]).isRequired]).isRequired]).isRequired, PropTypes.oneOf(["text"]).isRequired]).isRequired, PropTypes.oneOf(["icon"]).isRequired]).isRequired, PropTypes.oneOf(["divider"]).isRequired]).isRequired, PropTypes.oneOf(["spacer"]).isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Hides extra (above the max) breadcrumbs under a collapsed item as the window gets smaller.
       * Pass a custom #OuiBreadcrumbResponsiveMaxCount object to change the number of breadcrumbs to show at the particular breakpoints.
       * Omitting or passing a `0` value will show all breadcrumbs.
       *
       * Pass `false` to turn this behavior off.
       *
       * Default: `{ xs: 1, s: 2, m: 4 }`
       */
    responsive: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.any.isRequired]),

    /**
       * Forces all breadcrumbs to single line and
       * truncates each breadcrumb to a particular width,
       * except for the last item
       */
    truncate: PropTypes.bool,

    /**
       * Collapses the inner items past the maximum set here
       * into a single ellipses item
       */
    max: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf([null])]),

    /**
       * The array of individual #OuiBreadcrumb items
       */
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,

      /**
         * Visible label of the breadcrumb
         */
      text: PropTypes.node.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func,

      /**
         * Force a max-width on the breadcrumb text
         */
      truncate: PropTypes.bool
    }).isRequired),
    text: PropTypes.node,
    iconType: PropTypes.string
  }).isRequired).isRequired,

  /**
       * The default height of the content area.
       */
  size: PropTypes.oneOf(["s", "m", "l"]),

  /**
       * Customize the max height.
       * Best when used with `size=l` as this will ensure the actual height equals the max height set.
       */
  maxHeight: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
       * Set the offset from the left side of the screen.
       */
  leftOffset: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
       * Set the offset from the left side of the screen.
       */
  rightOffset: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
       * The control bar is hidden on mobile by default. Use the `showOnMobile` prop to force it's display on mobile screens.
       * You'll need to ensure that the content you place into the bar renders as expected on mobile.
       */
  showOnMobile: PropTypes.bool,

  /**
       * By default OuiControlBar will live in a portal, fixed position to the browser window.
       * Change the position of the bar to live inside a container and be positioned against its parent.
       */
  position: PropTypes.oneOf(["fixed", "relative", "absolute"]),

  /**
       * Optional class applied to the body used when `position = fixed`
       */
  bodyClassName: PropTypes.string,

  /**
       * Customize the screen reader heading that helps users find this control. Default is "Page level controls".
       */
  landmarkHeading: PropTypes.string
};

try {
  OuiControlBar.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiControlBar',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      showContent: {
        defaultValue: {
          value: 'false'
        },
        description: 'Show or hide the content area containing the `children`',
        name: 'showContent',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      controls: {
        defaultValue: null,
        description: 'An array of controls, actions, and layout spacers to display.\n' + "Accepts `'button' | 'tab' | 'breadcrumbs' | 'text' | 'icon' | 'spacer' | 'divider'`",
        name: 'controls',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '((DisambiguateSet<(DisambiguateSet<(DisambiguateSet<(DisambiguateSet<(DisambiguateSet<ButtonControlProps, (DisambiguateSet<BreadcrumbControl, TabControl> & TabControl) | (DisambiguateSet<...> & BreadcrumbControl)> & DisambiguateSet<...> & TabControl) | (DisambiguateSet<...> & ... 1 more ... & BreadcrumbControl) | (D...'
        }
      },
      size: {
        defaultValue: {
          value: 'l'
        },
        description: 'The default height of the content area.',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }]
        }
      },
      maxHeight: {
        defaultValue: null,
        description: 'Customize the max height.\n' + 'Best when used with `size=l` as this will ensure the actual height equals the max height set.',
        name: 'maxHeight',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      leftOffset: {
        defaultValue: {
          value: '0'
        },
        description: 'Set the offset from the left side of the screen.',
        name: 'leftOffset',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      rightOffset: {
        defaultValue: {
          value: '0'
        },
        description: 'Set the offset from the left side of the screen.',
        name: 'rightOffset',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      showOnMobile: {
        defaultValue: {
          value: 'false'
        },
        description: "The control bar is hidden on mobile by default. Use the `showOnMobile` prop to force it's display on mobile screens.\n" + "You'll need to ensure that the content you place into the bar renders as expected on mobile.",
        name: 'showOnMobile',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      position: {
        defaultValue: {
          value: 'fixed'
        },
        description: 'By default OuiControlBar will live in a portal, fixed position to the browser window.\n' + 'Change the position of the bar to live inside a container and be positioned against its parent.',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "absolute" | "relative"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"absolute"'
          }, {
            value: '"relative"'
          }]
        }
      },
      bodyClassName: {
        defaultValue: null,
        description: 'Optional class applied to the body used when `position = fixed`',
        name: 'bodyClassName',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      landmarkHeading: {
        defaultValue: null,
        description: 'Customize the screen reader heading that helps users find this control. Default is "Page level controls".',
        name: 'landmarkHeading',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/control_bar/control_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}