/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiSideNavItem } from './side_nav_item';
import { OuiButtonEmpty } from '../button';
import { OuiTitle } from '../title';
import { OuiScreenReaderOnly } from '../accessibility';
import { htmlIdGenerator } from '../../services';
import { OuiHideFor, OuiShowFor } from '../responsive';
export class OuiSideNav extends Component {
  static defaultProps = {
    items: [],
    mobileBreakpoints: ['xs', 's']
  };
  isItemOpen = item => {
    // The developer can force the item to be open.
    if (item.forceOpen) {
      return true;
    } // Of course a selected item is open.


    if (item.isSelected) {
      return true;
    } // The item has to be open if it has a child that's open.


    if (item.items) {
      return item.items.some(this.isItemOpen);
    }

    return false;
  };
  renderTree = (items, depth = 0) => {
    const {
      renderItem,
      truncate
    } = this.props;
    return items.map(item => {
      const {
        id,
        name,
        isSelected,
        items: childItems,
        icon,
        onClick,
        href,
        forceOpen,
        ...rest
      } = item; // Root items are always open.

      const isOpen = depth === 0 ? true : this.isItemOpen(item);
      let renderedItems;

      if (childItems) {
        renderedItems = this.renderTree(childItems, depth + 1);
      } // Act as an accordion only if item is not linked but has children (and not the root)


      const childrenOnly = depth > 0 && !onClick && !href && !!childItems;
      return <OuiSideNavItem isOpen={isOpen} isSelected={!childrenOnly && isSelected} isParent={!!childItems} icon={icon} onClick={onClick} href={href} items={renderedItems} key={id} depth={depth} renderItem={renderItem} truncate={truncate} childrenOnly={childrenOnly} {...rest}>
          {name}
        </OuiSideNavItem>;
    });
  };

  render() {
    const {
      className,
      items,
      toggleOpenOnMobile,
      isOpenOnMobile,
      mobileTitle,
      mobileBreakpoints,
      // Extract this one out so it isn't passed to <nav>
      renderItem,
      truncate,
      heading,
      headingProps = {},
      ...rest
    } = this.props;
    const classes = classNames('ouiSideNav', className, {
      'ouiSideNav-isOpenMobile': isOpenOnMobile
    }); // To support the extra CSS needed to show/hide/animate the content,
    // We add a className for every breakpoint supported

    const contentClasses = classNames('ouiSideNav__content', mobileBreakpoints?.map(breakpointName => `ouiSideNav__contentMobile-${breakpointName}`));
    const sideNavContentId = htmlIdGenerator('ouiSideNavContent')();
    const navContent = <div id={sideNavContentId} className={contentClasses}>
        {this.renderTree(items)}
      </div>;
    const {
      screenReaderOnly: headingScreenReaderOnly = false,
      element: HeadingElement = 'h2',
      ...titleProps
    } = headingProps;
    const hasMobileVersion = mobileBreakpoints && mobileBreakpoints.length > 0;
    const hasHeader = !!heading;
    let headingNode;
    const sharedHeadingProps = {
      id: headingProps?.id || htmlIdGenerator('ouiSideNavHeading')(),
      className: headingProps?.className,
      'data-test-subj': headingProps?.['data-test-subj'],
      'aria-label': headingProps?.['aria-label']
    };

    if (hasHeader) {
      headingNode = <HeadingElement {...sharedHeadingProps}>{heading}</HeadingElement>;

      if (headingScreenReaderOnly) {
        headingNode = <OuiScreenReaderOnly>{headingNode}</OuiScreenReaderOnly>;
      } else {
        headingNode = <OuiTitle size="xs" {...titleProps} className={classNames('ouiSideNav__heading', headingProps?.className)}>
            <HeadingElement {...sharedHeadingProps}>{heading}</HeadingElement>
          </OuiTitle>;
      }
    }

    let mobileNode;
    const breakpoints = mobileBreakpoints;

    if (hasMobileVersion) {
      mobileNode = <OuiShowFor sizes={breakpoints || 'none'}>
          <nav aria-labelledby={sharedHeadingProps.id} className={classes} {...rest}>
            <HeadingElement {...sharedHeadingProps}>
              <OuiButtonEmpty className="ouiSideNav__mobileToggle" textProps={{
              className: 'ouiSideNav__mobileToggleText'
            }} contentProps={{
              className: 'ouiSideNav__mobileToggleContent'
            }} onClick={toggleOpenOnMobile} iconType="apps" iconSide="right" aria-controls={sideNavContentId} aria-expanded={isOpenOnMobile}>
                {mobileTitle || heading}
              </OuiButtonEmpty>
            </HeadingElement>
            {navContent}
          </nav>
        </OuiShowFor>;
    }

    return <>
        {mobileNode}
        <OuiHideFor sizes={breakpoints || 'none'}>
          <nav aria-labelledby={headingNode ? sharedHeadingProps.id : undefined} className={classes} {...rest}>
            {headingNode}
            {navContent}
          </nav>
        </OuiHideFor>
      </>;
  }

}
OuiSideNav.propTypes = {
  /**
       * Class names to be merged into the final `className` property.
       */
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Creates an associated heading element and uses the same node as default for `mobileTitle`
       */
  heading: PropTypes.node,

  /**
       * Adds a couple extra #OuiSideNavHeading props and extends the props of OuiTitle that wraps the `heading`
       */
  headingProps: PropTypes.shape({
    /**
       * The actual HTML heading element to wrap the `heading`.
       * Default is `h2`
       */
    element: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "span"]),

    /**
       * For best accessibility, `<nav>` elements should have a nested heading. But you can hide this element if it's redundent from something else (except on mobile).
       */
    screenReaderOnly: PropTypes.bool
  }),

  /**
       * When called, toggles visibility of the navigation menu at mobile responsive widths. The callback should set the `isOpenOnMobile` prop to actually toggle navigation visibility.
       */
  toggleOpenOnMobile: PropTypes.func,

  /**
       * If `true`, the navigation menu will be open at mobile device widths. Use in conjunction with the `toggleOpenOnMobile` prop.
       */
  isOpenOnMobile: PropTypes.bool,

  /**
       * A React node to render at mobile responsive widths, representing the title of this navigation menu.
       */
  mobileTitle: PropTypes.node,

  /**
       * Array of breakpoint names for when to show the mobile version.
       * Set to `undefined` to remove responsive behavior
       */
  mobileBreakpoints: PropTypes.arrayOf(PropTypes.oneOf(["xs", "s", "m", "l", "xl"]).isRequired),

  /**
       *  An array of #OuiSideNavItem objects. Lists navigation menu items.
       */
  items: PropTypes.arrayOf(PropTypes.shape({
    /**
       * A value that is passed to React as the `key` for this item
       */
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,

    /**
       * If set to true it will force the item to display in an "open" state at all times.
       */
    forceOpen: PropTypes.bool,

    /**
       * Array containing additional item objects, representing nested children of this navigation item.
       */
    items: PropTypes.arrayOf(PropTypes.shape({
      /**
         * A value that is passed to React as the `key` for this item
         */
      id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,

      /**
         * If set to true it will force the item to display in an "open" state at all times.
         */
      forceOpen: PropTypes.bool,

      /**
         * Array containing additional item objects, representing nested children of this navigation item.
         */
      items: PropTypes.arrayOf(PropTypes.any.isRequired),

      /**
         * React node representing the text to render for this item (usually a string will suffice).
         */
      name: PropTypes.node.isRequired,

      /**
         * Function overriding default rendering for this navigation item — when called, it should return a React node representing a replacement navigation item.
         */
      renderItem: PropTypes.func
    }).isRequired),

    /**
       * React node representing the text to render for this item (usually a string will suffice).
       */
    name: PropTypes.node.isRequired,

    /**
       * Function overriding default rendering for this navigation item — when called, it should return a React node representing a replacement navigation item.
       */
    renderItem: PropTypes.func
  }).isRequired).isRequired,

  /**
       * Overrides default navigation menu item rendering. When called, it should return a React node representing a replacement navigation item.
       */
  renderItem: PropTypes.func,

  /**
       * Truncates the text of all items to stick to a single line
       */
  truncate: PropTypes.bool
};

try {
  OuiSideNav.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSideNav',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: 'Class names to be merged into the final `className` property.',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: '`children` are not rendered. Use `items` to specify navigation items instead.',
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'never'
        }
      },
      heading: {
        defaultValue: null,
        description: 'Creates an associated heading element and uses the same node as default for `mobileTitle`',
        name: 'heading',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      headingProps: {
        defaultValue: null,
        description: 'Adds a couple extra #OuiSideNavHeading props and extends the props of OuiTitle that wraps the `heading`',
        name: 'headingProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiSideNavHeadingProps'
        }
      },
      toggleOpenOnMobile: {
        defaultValue: null,
        description: 'When called, toggles visibility of the navigation menu at mobile responsive widths. The callback should set the `isOpenOnMobile` prop to actually toggle navigation visibility.',
        name: 'toggleOpenOnMobile',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      isOpenOnMobile: {
        defaultValue: null,
        description: 'If `true`, the navigation menu will be open at mobile device widths. Use in conjunction with the `toggleOpenOnMobile` prop.',
        name: 'isOpenOnMobile',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      mobileTitle: {
        defaultValue: null,
        description: 'A React node to render at mobile responsive widths, representing the title of this navigation menu.',
        name: 'mobileTitle',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      mobileBreakpoints: {
        defaultValue: {
          value: "['xs', 's']"
        },
        description: 'Array of breakpoint names for when to show the mobile version.\n' + 'Set to `undefined` to remove responsive behavior',
        name: 'mobileBreakpoints',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiBreakpointSize[]'
        }
      },
      items: {
        defaultValue: {
          value: '[]'
        },
        description: 'An array of #OuiSideNavItem objects. Lists navigation menu items.',
        name: 'items',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiSideNavItemType<T>[]'
        }
      },
      renderItem: {
        defaultValue: null,
        description: 'Overrides default navigation menu item rendering. When called, it should return a React node representing a replacement navigation item.',
        name: 'renderItem',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'RenderItem<T>'
        }
      },
      truncate: {
        defaultValue: null,
        description: 'Truncates the text of all items to stick to a single line',
        name: 'truncate',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/side_nav/side_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}