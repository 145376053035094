/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import { OuiCodeBlockImpl } from './_code_block';
export var OuiCodeBlock = ({ ...rest
}) => {
  return <OuiCodeBlockImpl inline={false} {...rest} />;
};
OuiCodeBlock.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiCodeBlock.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiCodeBlock',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      paddingSize: {
        defaultValue: null,
        description: '',
        name: 'paddingSize',
        parent: {
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        },
        declarations: [{
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      fontSize: {
        defaultValue: null,
        description: '',
        name: 'fontSize',
        parent: {
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        },
        declarations: [{
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }]
        }
      },
      language: {
        defaultValue: null,
        description: 'Sets the syntax highlighting for a specific language\n' + '@see https://github.com/wooorm/refractor#syntaxes\n' + 'for options',
        name: 'language',
        parent: {
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        },
        declarations: [{
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      transparentBackground: {
        defaultValue: null,
        description: '',
        name: 'transparentBackground',
        parent: {
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        },
        declarations: [{
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isCopyable: {
        defaultValue: null,
        description: 'Displays an icon button to copy the code snippet to the clipboard.',
        name: 'isCopyable',
        parent: {
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        },
        declarations: [{
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      overflowHeight: {
        defaultValue: null,
        description: '',
        name: 'overflowHeight',
        parent: {
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        },
        declarations: [{
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      whiteSpace: {
        defaultValue: null,
        description: 'Specify how `white-space` inside the element is handled.\n' + "`pre` respects line breaks/white space but doesn't force them to wrap the line\n" + '`pre-wrap` respects line breaks/white space but does force them to wrap the line when necessary.',
        name: 'whiteSpace',
        parent: {
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        },
        declarations: [{
          fileName: 'oui/src/components/code/_code_block.tsx',
          name: 'OuiCodeBlockImplProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"pre" | "pre-wrap"',
          value: [{
            value: '"pre"'
          }, {
            value: '"pre-wrap"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'OuiCodeBlockImplProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}