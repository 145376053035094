/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { cloneElement } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiText } from '../../text';
import { OuiFormControlLayout } from './form_control_layout';
export var OuiFormControlLayoutDelimited = ({
  startControl,
  endControl,
  delimiter = '→',
  className,
  ...rest
}) => {
  const classes = classNames('ouiFormControlLayoutDelimited', className);
  return <OuiFormControlLayout className={classes} {...rest}>
      {addClassesToControl(startControl)}
      <OuiText className="ouiFormControlLayoutDelimited__delimeter" size="s" color="subdued">
        {delimiter}
      </OuiText>
      {addClassesToControl(endControl)}
    </OuiFormControlLayout>;
};
OuiFormControlLayoutDelimited.propTypes = {
  /**
     * Left side control
     */
  startControl: PropTypes.element.isRequired,

  /**
     * Right side control
     */
  endControl: PropTypes.element.isRequired,

  /**
     * The center content. Accepts a string to be wrapped in a subdued OuiText
     * or a single ReactElement
     */
  delimiter: PropTypes.node,
  className: PropTypes.string
};

function addClassesToControl(control) {
  return cloneElement(control, {
    className: classNames(control.props.className, 'ouiFormControlLayoutDelimited__input')
  });
}

try {
  OuiFormControlLayoutDelimited.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFormControlLayoutDelimited',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before children.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after children.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | FunctionComponent<{}> | ComponentClass<{}, any> | (Pick<CommonProps & DisambiguateSet<Pick<ButtonHTMLAttributes<HTMLButtonElement>, "value" | ... 262 more ... | "formTarget">, HTMLAttributes<...>> & HTMLAttributes<...> & { ...; }, "value" | ... 264 more ... | "formTarget"> & { ...; }) | (Pick<...> & { ...; })'
        }
      },
      clear: {
        defaultValue: null,
        description: '',
        name: 'clear',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & ButtonHTMLAttributes<HTMLButtonElement> & { size?: "s" | "m"; }'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: null,
        description: '',
        name: 'readOnly',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputId: {
        defaultValue: null,
        description: 'Connects the prepend and append labels to the input',
        name: 'inputId',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      startControl: {
        defaultValue: null,
        description: 'Left side control',
        name: 'startControl',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      endControl: {
        defaultValue: null,
        description: 'Right side control',
        name: 'endControl',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      delimiter: {
        defaultValue: {
          value: '→'
        },
        description: 'The center content. Accepts a string to be wrapped in a subdued OuiText\n' + 'or a single ReactElement',
        name: 'delimiter',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}