/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiFlexGroup, OuiFlexItem } from '../flex';
import { OuiIcon } from '../icon';

var resolveIconAndColor = function resolveIconAndColor(checked) {
  if (!checked) {
    return {
      icon: 'empty'
    };
  }

  return checked === 'on' ? {
    icon: 'check',
    color: 'text'
  } : {
    icon: 'cross',
    color: 'text'
  };
};

export class OuiFilterSelectItem extends Component {
  static defaultProps = {
    showIcons: true
  };
  buttonRef = null;
  state = {
    hasFocus: false
  };
  focus = () => {
    if (this.buttonRef) {
      this.buttonRef.focus();
    }
  };
  hasFocus = () => {
    return this.state.hasFocus;
  };

  render() {
    const {
      children,
      className,
      disabled,
      checked,
      isFocused,
      showIcons,
      ...rest
    } = this.props;
    const classes = classNames('ouiFilterSelectItem', {
      'ouiFilterSelectItem-isFocused': isFocused
    }, className);
    let iconNode;

    if (showIcons) {
      const {
        icon,
        color
      } = resolveIconAndColor(checked);
      iconNode = <OuiFlexItem grow={false}>
          <OuiIcon color={color} type={icon} />
        </OuiFlexItem>;
    }

    return <button ref={ref => this.buttonRef = ref} role="option" type="button" aria-selected={isFocused} className={classes} disabled={disabled} aria-disabled={disabled} {...rest}>
        <OuiFlexGroup alignItems="center" gutterSize="s" component="span" responsive={false}>
          {iconNode}
          <OuiFlexItem className="ouiFilterSelectItem__content" component="span">
            {children}
          </OuiFlexItem>
        </OuiFlexGroup>
      </button>;
  }

}
OuiFilterSelectItem.propTypes = {
  checked: PropTypes.oneOf(["on", "off"]),
  showIcons: PropTypes.bool,
  isFocused: PropTypes.bool,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiFilterSelectItem.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFilterSelectItem',
    methods: [],
    props: {
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: {
          fileName: 'oui/src/components/filter_group/filter_select_item.tsx',
          name: 'OuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/filter_group/filter_select_item.tsx',
          name: 'OuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"on" | "off"',
          value: [{
            value: '"on"'
          }, {
            value: '"off"'
          }]
        }
      },
      showIcons: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'showIcons',
        parent: {
          fileName: 'oui/src/components/filter_group/filter_select_item.tsx',
          name: 'OuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/filter_group/filter_select_item.tsx',
          name: 'OuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isFocused: {
        defaultValue: null,
        description: '',
        name: 'isFocused',
        parent: {
          fileName: 'oui/src/components/filter_group/filter_select_item.tsx',
          name: 'OuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'oui/src/components/filter_group/filter_select_item.tsx',
          name: 'OuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiFilterSelectItemProps', 'CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}