/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiScreenReaderOnly } from '../accessibility';
import { OuiI18n } from '../i18n';
var HUE_RANGE = 359;
export var OuiHue = ({
  className,
  hex,
  hue = 1,
  id,
  onChange,
  ...rest
}) => {
  const handleChange = e => {
    onChange(Number(e.target.value));
  };

  const classes = classNames('ouiHue', className);
  return <React.Fragment>
      <OuiScreenReaderOnly>
        <label htmlFor={`${id}-hue`}>
          <OuiI18n token="ouiHue.label" default="Select the HSV color mode 'hue' value" />
        </label>
      </OuiScreenReaderOnly>
      <OuiScreenReaderOnly>
        <p aria-live="polite">{hex}</p>
      </OuiScreenReaderOnly>
      <div className={classes}>
        <input id={`${id}-hue`} min={0} max={HUE_RANGE} step={1} type="range" className="ouiHue__range" value={hue} onChange={handleChange} {...rest} />
      </div>
    </React.Fragment>;
};
OuiHue.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  hex: PropTypes.string,
  hue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onChange: PropTypes.func.isRequired
};

try {
  OuiHue.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiHue',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      hex: {
        defaultValue: null,
        description: '',
        name: 'hex',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/color_picker/hue.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      hue: {
        defaultValue: {
          value: '1'
        },
        description: '',
        name: 'hue',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/color_picker/hue.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/color_picker/hue.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(hue: number) => void'
        }
      }
    },
    extendedInterfaces: ['InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}