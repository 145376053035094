/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
import { OuiTextColor } from './text_color';
import { OuiTextAlign } from './text_align';
var textSizeToClassNameMap = {
  xs: 'ouiText--extraSmall',
  s: 'ouiText--small',
  m: 'ouiText--medium'
};
export var TEXT_SIZES = keysOf(textSizeToClassNameMap);
export var OuiText = ({
  size = 'm',
  color,
  grow = true,
  textAlign,
  children,
  className,
  ...rest
}) => {
  const classes = classNames('ouiText', textSizeToClassNameMap[size], className, {
    'ouiText--constrainedWidth': !grow
  });
  let optionallyAlteredText;

  if (color) {
    optionallyAlteredText = <OuiTextColor color={color} component="div">
        {children}
      </OuiTextColor>;
  }

  if (textAlign) {
    optionallyAlteredText = <OuiTextAlign textAlign={textAlign}>
        {optionallyAlteredText || children}
      </OuiTextAlign>;
  }

  return <div className={classes} {...rest}>
      {optionallyAlteredText || children}
    </div>;
};
OuiText.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  textAlign: PropTypes.oneOf(["left", "right", "center"]),
  size: PropTypes.oneOf(["xs", "s", "m"]),

  /**
       * **`secondary` color is DEPRECATED, use `success` instead**
       */
  color: PropTypes.oneOf(["default", "subdued", "secondary", "success", "accent", "danger", "warning", "ghost"]),
  grow: PropTypes.bool
};

try {
  OuiText.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiText',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      textAlign: {
        defaultValue: null,
        description: '',
        name: 'textAlign',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "center"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"center"'
          }]
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      color: {
        defaultValue: null,
        description: '**`secondary` color is DEPRECATED, use `success` instead**',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"default" | "secondary" | "success" | "accent" | "warning" | "danger" | "subdued" | "ghost"',
          value: [{
            value: '"default"'
          }, {
            value: '"secondary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'grow',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}