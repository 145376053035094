/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import { OuiI18nConsumer } from '../context';
var defaultFormatter = new Intl.NumberFormat('en');

function defaultFormatNumber(value) {
  return defaultFormatter.format(value);
}

function hasValues(x) {
  return x.values != null;
}

var OuiI18nNumber = props => <OuiI18nConsumer>
    {i18nConfig => {
    const formatNumber = i18nConfig.formatNumber || defaultFormatNumber;

    if (hasValues(props)) {
      return props.children(props.values.map(value => formatNumber(value)));
    }

    const formattedValue = (formatNumber || defaultFormatNumber)(props.value);

    if (props.children) {
      return props.children(formattedValue);
    } else {
      return formattedValue;
    }
  }}
  </OuiI18nConsumer>;

OuiI18nNumber.propTypes = {
  value: PropTypes.number,

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.func.isRequired]),
  values: PropTypes.arrayOf(PropTypes.number.isRequired)
};
export { OuiI18nNumber };

try {
  OuiI18nNumber.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiI18nNumber',
    methods: [],
    props: {
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'oui/src/components/i18n/i18n_number.tsx',
          name: 'OuiI18nNumberValueShape'
        },
        declarations: [{
          fileName: 'oui/src/components/i18n/i18n_number.tsx',
          name: 'OuiI18nNumberValueShape'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      values: {
        defaultValue: null,
        description: '',
        name: 'values',
        parent: {
          fileName: 'oui/src/components/i18n/i18n_number.tsx',
          name: 'OuiI18nNumberValuesShape'
        },
        declarations: [{
          fileName: 'oui/src/components/i18n/i18n_number.tsx',
          name: 'OuiI18nNumberValuesShape'
        }],
        required: false,
        type: {
          name: 'number[]'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'oui/src/components/i18n/i18n_number.tsx',
          name: 'OuiI18nNumberValuesShape'
        },
        declarations: [{
          fileName: 'oui/src/components/i18n/i18n_number.tsx',
          name: 'OuiI18nNumberValuesShape'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/i18n/i18n_number.tsx',
          name: 'OuiI18nNumberValueShape'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(x: ReactChild[]) => ReactElement<any, string | ((props: any) => ReactElement<any, string | ... | (new (props: any) => Component<any, any, any>)>) | (new (props: any) => Component<...>)>'
        }
      }
    },
    extendedInterfaces: ['OuiI18nNumberValueShape', 'OuiI18nNumberValuesShape']
  };
} catch (e) {}