/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
export var OuiOverlayMaskProps = () => <div />;
OuiOverlayMaskProps.propTypes = {
  /**
     * Function that applies to clicking the mask itself and not the children
     */
  onClick: PropTypes.func,

  /**
     * ReactNode to render as this component's children
     */
  children: PropTypes.node,

  /**
     * Should the mask visually sit above or below the OuiHeader (controlled by z-index)
     */
  headerZindexLocation: PropTypes.oneOf(["above", "below"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiOverlayMaskProps.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiOverlayMaskProps',
    methods: [],
    props: {
      onClick: {
        defaultValue: null,
        description: 'Function that applies to clicking the mask itself and not the children',
        name: 'onClick',
        parent: {
          fileName: 'oui/src-docs/src/views/overlay_mask/props.tsx',
          name: 'OuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/views/overlay_mask/props.tsx',
          name: 'OuiOverlayMaskInterface'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's children",
        name: 'children',
        parent: {
          fileName: 'oui/src-docs/src/views/overlay_mask/props.tsx',
          name: 'OuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/views/overlay_mask/props.tsx',
          name: 'OuiOverlayMaskInterface'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      headerZindexLocation: {
        defaultValue: null,
        description: 'Should the mask visually sit above or below the OuiHeader (controlled by z-index)',
        name: 'headerZindexLocation',
        parent: {
          fileName: 'oui/src-docs/src/views/overlay_mask/props.tsx',
          name: 'OuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'oui/src-docs/src/views/overlay_mask/props.tsx',
          name: 'OuiOverlayMaskInterface'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"above" | "below"',
          value: [{
            value: '"above"'
          }, {
            value: '"below"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiOverlayMaskInterface', 'CommonProps']
  };
} catch (e) {}