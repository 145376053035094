/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiBeacon = ({
  className,
  size = 12,
  style,
  ...rest
}) => {
  const classes = classNames('ouiBeacon', className);
  const styles = { ...style,
    height: size,
    width: size
  };
  return <div className={classes} style={styles} {...rest} />;
};
OuiBeacon.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Height and width of the center circle. Value is passed directly to the `style` attribute
       */
  size: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired])
};

try {
  OuiBeacon.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiBeacon',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      size: {
        defaultValue: {
          value: '12'
        },
        description: 'Height and width of the center circle. Value is passed directly to the `style` attribute',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/beacon/beacon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}