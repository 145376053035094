/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { Children, cloneElement, useRef, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";

function isMutableRef(ref) {
  return ref != null && ref.hasOwnProperty('current');
}

export var OuiValidatableControl = ({
  isInvalid,
  children
}) => {
  const control = useRef(null);
  const child = Children.only(children);
  const childRef = child.ref;
  const replacedRef = useCallback(element => {
    control.current = element; // Call the original ref, if any

    if (typeof childRef === 'function') {
      childRef(element);
    } else if (isMutableRef(childRef)) {
      childRef.current = element;
    }
  }, [childRef]);
  useEffect(() => {
    if (control.current === null || typeof control.current.setCustomValidity !== 'function') {
      return; // jsdom doesn't polyfill this for the server-side
    }

    if (isInvalid) {
      control.current.setCustomValidity('Invalid');
    } else {
      control.current.setCustomValidity('');
    }
  });
  return cloneElement(child, {
    ref: replacedRef
  });
};
OuiValidatableControl.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  isInvalid: PropTypes.bool,

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.shape({
    ref: PropTypes.any
  }).isRequired
};

try {
  OuiValidatableControl.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiValidatableControl',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'oui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'OuiValidatableControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'OuiValidatableControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'oui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'OuiValidatableControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'OuiValidatableControlProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElementWithRef'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'OuiValidatableControlProps']
  };
} catch (e) {}