/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
import { OuiIcon } from '../icon';
var colorToClassNameMap = {
  subdued: 'ouiExpression--subdued',
  primary: 'ouiExpression--primary',
  success: 'ouiExpression--success',
  secondary: 'ouiExpression--secondary',
  accent: 'ouiExpression--accent',
  warning: 'ouiExpression--warning',
  danger: 'ouiExpression--danger'
};
var textWrapToClassNameMap = {
  'break-word': null,
  truncate: 'ouiExpression--truncate'
};
export var COLORS = keysOf(colorToClassNameMap);
var displayToClassNameMap = {
  inline: null,
  columns: 'ouiExpression--columns'
};
export var OuiExpression = ({
  className,
  description,
  descriptionProps,
  value,
  valueProps,
  color = 'success',
  uppercase = true,
  isActive = false,
  display = 'inline',
  descriptionWidth = '20%',
  onClick,
  isInvalid = false,
  textWrap = 'break-word',
  ...rest
}) => {
  const calculatedColor = isInvalid ? 'danger' : color;
  const classes = classNames('ouiExpression', className, {
    'ouiExpression-isActive': isActive,
    'ouiExpression-isClickable': onClick,
    'ouiExpression-isUppercase': uppercase
  }, displayToClassNameMap[display], colorToClassNameMap[calculatedColor], textWrapToClassNameMap[textWrap]);
  const Component = onClick ? 'button' : 'span';
  const descriptionStyle = descriptionProps && descriptionProps.style;
  const customWidth = display === 'columns' && descriptionWidth ? {
    flexBasis: descriptionWidth,
    ...descriptionStyle
  } : undefined;
  const invalidIcon = isInvalid ? <OuiIcon className="ouiExpression__icon" type="alert" color={calculatedColor} /> : undefined;
  return <Component className={classes} onClick={onClick} {...rest}>
      <span className="ouiExpression__description" style={customWidth} {...descriptionProps}>
        {description}
      </span>{' '}
      {value && <span className="ouiExpression__value" {...valueProps}>
          {value}
        </span>}
      {invalidIcon}
    </Component>;
};
OuiExpression.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * First part of the expression
     */

  /**
     * First part of the expression
     */
  description: PropTypes.node.isRequired,
  descriptionProps: PropTypes.any,

  /**
     * Second part of the expression
     */

  /**
     * Second part of the expression
     */
  value: PropTypes.node,
  valueProps: PropTypes.any,

  /**
     * Color of the `description`
     * **`secondary` color is DEPRECATED, use `success` instead**
     */

  /**
     * Color of the `description`
     * **`secondary` color is DEPRECATED, use `success` instead**
     */
  color: PropTypes.oneOf(["subdued", "primary", "success", "secondary", "accent", "warning", "danger"]),

  /**
     * Should the `description` auto-uppercase?
     */

  /**
     * Should the `description` auto-uppercase?
     */
  uppercase: PropTypes.bool,

  /**
     * Adds an solid border at the bottom
     */

  /**
     * Adds an solid border at the bottom
     */
  isActive: PropTypes.bool,

  /**
     * Turns the component into a button and adds an editable style border at the bottom
     */

  /**
     * Turns the component into a button and adds an editable style border at the bottom
     */
  onClick: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.func, PropTypes.func.isRequired]), PropTypes.func]),

  /**
     * Sets the display style for the expression. Defaults to `inline`
     */

  /**
     * Sets the display style for the expression. Defaults to `inline`
     */
  display: PropTypes.oneOf(["inline", "columns"]),

  /**
     * Forces color to display as `danger` and shows an `alert` icon
     */

  /**
     * Forces color to display as `danger` and shows an `alert` icon
     */
  isInvalid: PropTypes.bool,

  /**
     * Sets a custom width for the description when using the columns layout.
     * Set to a number for a custom width in `px`.
     * Set to a string for a custom width in custom measurement.
     * Defaults to `20%`
     */

  /**
     * Sets a custom width for the description when using the columns layout.
     * Set to a number for a custom width in `px`.
     * Set to a string for a custom width in custom measurement.
     * Defaults to `20%`
     */
  descriptionWidth: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
     * Sets how to handle the wrapping of long text.
     */

  /**
     * Sets how to handle the wrapping of long text.
     */
  textWrap: PropTypes.oneOf(["break-word", "truncate"])
};

try {
  OuiExpression.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiExpression',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      description: {
        defaultValue: null,
        description: 'First part of the expression',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      descriptionProps: {
        defaultValue: null,
        description: '',
        name: 'descriptionProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLSpanElement>'
        }
      },
      value: {
        defaultValue: null,
        description: 'Second part of the expression',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      valueProps: {
        defaultValue: null,
        description: '',
        name: 'valueProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLSpanElement>'
        }
      },
      color: {
        defaultValue: {
          value: 'success'
        },
        description: 'Color of the `description`\n' + '**`secondary` color is DEPRECATED, use `success` instead**',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"primary" | "secondary" | "success" | "accent" | "warning" | "danger" | "subdued"',
          value: [{
            value: '"primary"'
          }, {
            value: '"secondary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }]
        }
      },
      uppercase: {
        defaultValue: {
          value: 'true'
        },
        description: 'Should the `description` auto-uppercase?',
        name: 'uppercase',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isActive: {
        defaultValue: {
          value: 'false'
        },
        description: 'Adds an solid border at the bottom',
        name: 'isActive',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: 'Turns the component into a button and adds an editable style border at the bottom',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '((event: MouseEvent<HTMLButtonElement, MouseEvent>) => void) | (((event: MouseEvent<HTMLButtonElement, MouseEvent>) => void) & ((event: MouseEvent<...>) => void))'
        }
      },
      display: {
        defaultValue: {
          value: 'inline'
        },
        description: 'Sets the display style for the expression. Defaults to `inline`',
        name: 'display',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inline" | "columns"',
          value: [{
            value: '"inline"'
          }, {
            value: '"columns"'
          }]
        }
      },
      isInvalid: {
        defaultValue: {
          value: 'false'
        },
        description: 'Forces color to display as `danger` and shows an `alert` icon',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      descriptionWidth: {
        defaultValue: {
          value: '20%'
        },
        description: 'Sets a custom width for the description when using the columns layout.\n' + 'Set to a number for a custom width in `px`.\n' + 'Set to a string for a custom width in custom measurement.\n' + 'Defaults to `20%`',
        name: 'descriptionWidth',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      textWrap: {
        defaultValue: {
          value: 'break-word'
        },
        description: 'Sets how to handle the wrapping of long text.',
        name: 'textWrap',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/expression/expression.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"truncate" | "break-word"',
          value: [{
            value: '"truncate"'
          }, {
            value: '"break-word"'
          }]
        }
      }
    },
    extendedInterfaces: ['ButtonHTMLAttributes', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}