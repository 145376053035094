/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiButtonIcon } from '../button';
export var OuiResizableCollapseButton = ({
  className,
  externalPosition,
  internalPosition = 'middle',
  direction = 'horizontal',
  isVisible,
  isCollapsed,
  ...rest
}) => {
  const isHorizontal = direction === 'horizontal';
  const classes = classNames('ouiResizableToggleButton', `ouiResizableToggleButton--${direction}`, `ouiResizableToggleButton--${externalPosition}`, `ouiResizableToggleButton--${internalPosition}`, {
    'ouiResizableToggleButton-isVisible': isVisible,
    'ouiResizableToggleButton-isCollapsed': isCollapsed
  }, className); // Default to simiple grab icon in case there is no externalPosition specified

  let COLLAPSED_ICON = isHorizontal ? 'grab' : 'grabHorizontal';
  let NOT_COLLAPSED_ICON = isHorizontal ? 'grab' : 'grabHorizontal';

  switch (externalPosition) {
    case 'before':
      COLLAPSED_ICON = isHorizontal ? 'menuLeft' : 'menuUp';
      NOT_COLLAPSED_ICON = isHorizontal ? 'menuRight' : 'menuDown';
      break;

    case 'after':
      COLLAPSED_ICON = isHorizontal ? 'menuRight' : 'menuDown';
      NOT_COLLAPSED_ICON = isHorizontal ? 'menuLeft' : 'menuUp';
      break;
  }

  return <OuiButtonIcon display={isCollapsed ? 'empty' : 'fill'} color={isCollapsed ? 'text' : 'ghost'} {...rest} className={classes} iconType={isCollapsed ? COLLAPSED_ICON : NOT_COLLAPSED_ICON} />;
};
OuiResizableCollapseButton.propTypes = {
  /**
     * Position of the toggle button.
     * Enums based on the `direction` of the OuiResizableContainer
     */
  internalPosition: PropTypes.oneOf(["top", "middle", "bottom", "left", "right"]),

  /**
     * Position of the toggle button.
     * Enums based on the `direction` of the OuiResizableContainer
     */
  externalPosition: PropTypes.oneOf(["before", "after"]),

  /**
     * Same direction derived from OuiResizableContainer
     */
  direction: PropTypes.oneOf(["vertical", "horizontal"]),

  /**
     *
     */
  isVisible: PropTypes.bool,
  isCollapsed: PropTypes.bool
};

try {
  OuiResizableCollapseButton.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiResizableCollapseButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      color: {
        defaultValue: null,
        description: 'Any of the named color palette options.\n' + '**`subdued` set to be DEPRECATED, use `text` instead**',
        name: 'color',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiButtonIconColor',
          value: [{
            value: '"text"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: false,
        type: {
          name: '(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      display: {
        defaultValue: null,
        description: 'Sets the display style for matching other OuiButton types.\n' + '`base` is equivelant to a typical OuiButton\n' + '`fill` is equivelant to a filled OuiButton\n' + '`empty` (default) is equivelant to an OuiButtonEmpty',
        name: 'display',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"base" | "empty" | "fill"',
          value: [{
            value: '"base"'
          }, {
            value: '"empty"'
          }, {
            value: '"fill"'
          }]
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'ButtonHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "reset" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      size: {
        defaultValue: null,
        description: 'Overall size of button.\nMatches the sizes of other OuiButtons',
        name: 'size',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement>'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      iconSize: {
        defaultValue: null,
        description: 'Size of the icon only.\n' + 'This will not affect the overall size of the button',
        name: 'iconSize',
        parent: {
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        },
        declarations: [{
          fileName: 'oui/src/components/button/button_icon/button_icon.tsx',
          name: 'OuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl" | "original" | "xxl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"original"'
          }, {
            value: '"xxl"'
          }]
        }
      },
      internalPosition: {
        defaultValue: {
          value: 'middle'
        },
        description: 'Position of the toggle button.\n' + 'Enums based on the `direction` of the OuiResizableContainer',
        name: 'internalPosition',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "top" | "bottom" | "middle"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }, {
            value: '"middle"'
          }]
        }
      },
      externalPosition: {
        defaultValue: null,
        description: 'Position of the toggle button.\n' + 'Enums based on the `direction` of the OuiResizableContainer',
        name: 'externalPosition',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"before" | "after"',
          value: [{
            value: '"before"'
          }, {
            value: '"after"'
          }]
        }
      },
      direction: {
        defaultValue: {
          value: 'horizontal'
        },
        description: 'Same direction derived from OuiResizableContainer',
        name: 'direction',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"horizontal" | "vertical"',
          value: [{
            value: '"horizontal"'
          }, {
            value: '"vertical"'
          }]
        }
      },
      isVisible: {
        defaultValue: null,
        description: '',
        name: 'isVisible',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isCollapsed: {
        defaultValue: null,
        description: '',
        name: 'isCollapsed',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['ButtonHTMLAttributes', 'HTMLAttributes', 'OuiButtonIconProps', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}