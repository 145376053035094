/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiI18n } from '../i18n';
import { OuiInnerText } from '../inner_text';
import { keysOf } from '../common';
import { isNil } from '../../services/predicate';
var sizeToClassNameMap = {
  xs: 'ouiProgress--xs',
  s: 'ouiProgress--s',
  m: 'ouiProgress--m',
  l: 'ouiProgress--l'
};
export var SIZES = keysOf(sizeToClassNameMap);
var colorToClassNameMap = {
  primary: 'ouiProgress--primary',
  secondary: 'ouiProgress--secondary',
  success: 'ouiProgress--success',
  warning: 'ouiProgress--warning',
  danger: 'ouiProgress--danger',
  subdued: 'ouiProgress--subdued',
  accent: 'ouiProgress--accent',
  vis0: 'ouiProgress--vis0',
  vis1: 'ouiProgress--vis1',
  vis2: 'ouiProgress--vis2',
  vis3: 'ouiProgress--vis3',
  vis4: 'ouiProgress--vis4',
  vis5: 'ouiProgress--vis5',
  vis6: 'ouiProgress--vis6',
  vis7: 'ouiProgress--vis7',
  vis8: 'ouiProgress--vis8',
  vis9: 'ouiProgress--vis9'
};
export var COLORS = keysOf(colorToClassNameMap);

function isNamedColor(name) {
  return colorToClassNameMap.hasOwnProperty(name);
}

var dataColorToClassNameMap = {
  primary: 'ouiProgress__data--primary',
  secondary: 'ouiProgress__data--secondary',
  success: 'ouiProgress__data--success',
  warning: 'ouiProgress__data--warning',
  danger: 'ouiProgress__data--danger',
  subdued: 'ouiProgress__data--subdued',
  accent: 'ouiProgress__data--accent',
  vis0: 'ouiProgress__data--vis0',
  vis1: 'ouiProgress__data--vis1',
  vis2: 'ouiProgress__data--vis2',
  vis3: 'ouiProgress__data--vis3',
  vis4: 'ouiProgress__data--vis4',
  vis5: 'ouiProgress__data--vis5',
  vis6: 'ouiProgress__data--vis6',
  vis7: 'ouiProgress__data--vis7',
  vis8: 'ouiProgress__data--vis8',
  vis9: 'ouiProgress__data--vis9'
};
var positionsToClassNameMap = {
  fixed: 'ouiProgress--fixed',
  absolute: 'ouiProgress--absolute',
  static: ''
};
export var POSITIONS = keysOf(positionsToClassNameMap);
export var OuiProgress = ({
  className,
  color = 'success',
  size = 'm',
  position = 'static',
  max,
  valueText = false,
  label,
  value,
  labelProps,
  ...rest
}) => {
  const determinate = !isNil(max);
  let colorClass = null;
  let dataColorClass = null;
  let optionalCustomStyles = null;

  if (color) {
    if (isNamedColor(color)) {
      colorClass = colorToClassNameMap[color];
      dataColorClass = dataColorToClassNameMap[color];
    } else {
      optionalCustomStyles = {
        color: color
      };
      colorClass = 'ouiProgress--customColor';
    }
  }

  const classes = classNames('ouiProgress', {
    'ouiProgress--indeterminate': !determinate,
    'ouiProgress--native': determinate
  }, sizeToClassNameMap[size], colorClass, positionsToClassNameMap[position], className);
  const dataClasses = classNames('ouiProgress__data', {
    'ouiProgress__data--l': size === 'l'
  }, dataColorClass);
  const labelClasses = classNames('ouiProgress__label', labelProps && labelProps.className);
  let valueRender;

  if (valueText === true) {
    // valueText is true
    valueRender = <OuiI18n token="ouiProgress.valueText" default="{value}%" values={{
      value
    }} />;
  } else if (valueText) {
    // valueText exists
    valueRender = valueText;
  } // Because of a Firefox animation issue, indeterminate progress needs to not use <progress />.
  // See https://css-tricks.com/html5-progress-element/


  if (determinate) {
    return <Fragment>
        {label || valueText ? <div className={dataClasses}>
            {label && <OuiInnerText>
                {(ref, innerText) => <span title={innerText} ref={ref} {...labelProps} className={labelClasses}>
                    {label}
                  </span>}
              </OuiInnerText>}
            {valueRender && <OuiInnerText>
                {(ref, innerText) => <span title={innerText} ref={ref} style={optionalCustomStyles} className="ouiProgress__valueText">
                    {valueRender}
                  </span>}
              </OuiInnerText>}
          </div> : undefined}
        <progress className={classes} style={optionalCustomStyles} max={max} value={value} aria-hidden={label && valueText ? true : false} {...rest} />
      </Fragment>;
  } else {
    return <div style={optionalCustomStyles} className={classes} {...rest} />;
  }
};
OuiProgress.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  size: PropTypes.oneOf(["xs", "s", "m", "l"]),

  /**
     * One of OUI's color palette, vis colors or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
     * **`secondary` color is DEPRECATED, use `success` instead**
     */

  /**
     * One of OUI's color palette, vis colors or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
     * **`secondary` color is DEPRECATED, use `success` instead**
     */
  color: PropTypes.oneOfType([PropTypes.oneOf(["primary", "secondary", "success", "warning", "danger", "subdued", "accent", "vis0", "vis1", "vis2", "vis3", "vis4", "vis5", "vis6", "vis7", "vis8", "vis9"]).isRequired, PropTypes.any.isRequired]),
  position: PropTypes.oneOf(["fixed", "absolute", "static"]),
  max: PropTypes.number,

  /*
       * If true, will render the percentage, otherwise pass a custom node
       */
  valueText: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.node.isRequired]),
  label: PropTypes.node,

  /**
       * Object of props passed to the <span/> wrapping the determinate progress's label
       */
  labelProps: PropTypes.any
};

try {
  OuiProgress.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiProgress',
    methods: [],
    props: {
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      valueText: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'valueText',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      labelProps: {
        defaultValue: null,
        description: "Object of props passed to the <span/> wrapping the determinate progress's label",
        name: 'labelProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLSpanElement>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'TokenSize',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }]
        }
      },
      color: {
        defaultValue: {
          value: 'success'
        },
        description: "One of OUI's color palette, vis colors or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value\n" + '**`secondary` color is DEPRECATED, use `success` instead**',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      position: {
        defaultValue: {
          value: 'static'
        },
        description: '',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "absolute" | "static"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"absolute"'
          }, {
            value: '"static"'
          }]
        }
      }
    },
    extendedInterfaces: ['ProgressHTMLAttributes', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}