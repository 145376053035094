/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiBreadcrumbs } from '../../breadcrumbs';
export var OuiHeaderBreadcrumbs = ({
  className,
  breadcrumbs,
  ...rest
}) => {
  const classes = classNames('ouiHeaderBreadcrumbs', className);
  return <OuiBreadcrumbs max={4} truncate breadcrumbs={breadcrumbs} className={classes} {...rest} />;
};
OuiHeaderBreadcrumbs.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Hides extra (above the max) breadcrumbs under a collapsed item as the window gets smaller.
     * Pass a custom #OuiBreadcrumbResponsiveMaxCount object to change the number of breadcrumbs to show at the particular breakpoints.
     * Omitting or passing a `0` value will show all breadcrumbs.
     *
     * Pass `false` to turn this behavior off.
     *
     * Default: `{ xs: 1, s: 2, m: 4 }`
     */
  responsive: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.any.isRequired]),

  /**
     * Forces all breadcrumbs to single line and
     * truncates each breadcrumb to a particular width,
     * except for the last item
     */
  truncate: PropTypes.bool,

  /**
     * Collapses the inner items past the maximum set here
     * into a single ellipses item
     */
  max: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf([null])]),

  /**
     * The array of individual #OuiBreadcrumb items
     */
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Visible label of the breadcrumb
       */
    text: PropTypes.node.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,

    /**
       * Force a max-width on the breadcrumb text
       */
    truncate: PropTypes.bool
  }).isRequired).isRequired
};

try {
  OuiHeaderBreadcrumbs.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiHeaderBreadcrumbs',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      responsive: {
        defaultValue: null,
        description: 'Hides extra (above the max) breadcrumbs under a collapsed item as the window gets smaller.\n' + 'Pass a custom #OuiBreadcrumbResponsiveMaxCount object to change the number of breadcrumbs to show at the particular breakpoints.\n' + 'Omitting or passing a `0` value will show all breadcrumbs.\n' + '\n' + 'Pass `false` to turn this behavior off.\n' + '\n' + 'Default: `{ xs: 1, s: 2, m: 4 }`',
        name: 'responsive',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/breadcrumbs/breadcrumbs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | OuiBreadcrumbResponsiveMaxCount'
        }
      },
      truncate: {
        defaultValue: null,
        description: 'Forces all breadcrumbs to single line and\n' + 'truncates each breadcrumb to a particular width,\n' + 'except for the last item',
        name: 'truncate',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/breadcrumbs/breadcrumbs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      max: {
        defaultValue: null,
        description: 'Collapses the inner items past the maximum set here\n' + 'into a single ellipses item',
        name: 'max',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/breadcrumbs/breadcrumbs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      breadcrumbs: {
        defaultValue: null,
        description: 'The array of individual #OuiBreadcrumb items',
        name: 'breadcrumbs',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/breadcrumbs/breadcrumbs.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'OuiBreadcrumb[]'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}