/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiFormControlLayout } from '../form_control_layout';
import { OuiValidatableControl } from '../validatable_control';
import { OuiButtonIcon } from '../../button';
import { useOuiI18n } from '../../i18n';
import { useCombinedRefs } from '../../../services';
export var OuiFieldPassword = ({
  className,
  id,
  name,
  placeholder,
  value,
  isInvalid,
  fullWidth,
  isLoading,
  compressed,
  inputRef: _inputRef,
  prepend,
  append,
  type = 'password',
  dualToggleProps,
  ...rest
}) => {
  // Set the initial input type to `password` if they want dual
  const [inputType, setInputType] = useState(type === 'dual' ? 'password' : type); // Setup toggle aria-label

  const [showPasswordLabel, maskPasswordLabel] = useOuiI18n(['ouiFieldPassword.showPassword', 'ouiFieldPassword.maskPassword'], ['Show password as plain text. Note: this will visually expose your password on the screen.', 'Mask password']); // Setup the inputRef to auto-focus when toggling visibility

  const [inputRef, _setInputRef] = useState(null);
  const setInputRef = useCombinedRefs([_setInputRef, _inputRef]);

  const handleToggle = (event, isVisible) => {
    setInputType(isVisible ? 'password' : 'text');

    if (inputRef) {
      inputRef.focus();
    }

    if (dualToggleProps && dualToggleProps.onClick) {
      dualToggleProps.onClick(event);
    }
  }; // Convert any `append` elements to an array so the visibility
  // toggle can be added to it


  let appends = Array.isArray(append) ? append : [];
  if (append && !Array.isArray(append)) appends.push(append); // Add a toggling button to switch between `password` and `input` if consumer wants `dual`
  // https://www.w3schools.com/howto/howto_js_toggle_password.asp

  if (type === 'dual') {
    const isVisible = inputType === 'text';
    const visibilityToggle = <OuiButtonIcon iconType={isVisible ? 'eyeClosed' : 'eye'} aria-label={isVisible ? maskPasswordLabel : showPasswordLabel} title={isVisible ? maskPasswordLabel : showPasswordLabel} disabled={rest.disabled} {...dualToggleProps} onClick={e => handleToggle(e, isVisible)} />;
    appends = [...appends, visibilityToggle];
  }

  const finalAppend = appends.length ? appends : undefined;
  const classes = classNames('ouiFieldPassword', {
    'ouiFieldPassword--fullWidth': fullWidth,
    'ouiFieldPassword--compressed': compressed,
    'ouiFieldPassword-isLoading': isLoading,
    'ouiFieldPassword--inGroup': prepend || finalAppend,
    'ouiFieldPassword--withToggle': type === 'dual'
  }, className);
  return <OuiFormControlLayout icon="lock" fullWidth={fullWidth} isLoading={isLoading} compressed={compressed} prepend={prepend} append={finalAppend}>
      <OuiValidatableControl isInvalid={isInvalid}>
        <input type={inputType} id={id} name={name} placeholder={placeholder} className={classes} value={value} ref={setInputRef} {...rest} spellCheck="false" />
      </OuiValidatableControl>
    </OuiFormControlLayout>;
};
OuiFieldPassword.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  isInvalid: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  compressed: PropTypes.bool,
  inputRef: PropTypes.any,

  /**
       * Creates an input group with element(s) coming before input.
       * `string` | `ReactElement` or an array of these
       */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

  /**
       * Creates an input group with element(s) coming after input.
       * `string` | `ReactElement` or an array of these
       */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),

  /**
       * Change the `type` of input for manually handling obfuscation.
       * The `dual` option adds the ability to toggle the obfuscation of the input by
       * adding an icon button as the first `append` element
       */
  type: PropTypes.oneOf(["password", "text", "dual"]),

  /**
       * Additional props to apply to the dual toggle. Extends OuiButtonIcon
       */
  dualToggleProps: PropTypes.any
};
OuiFieldPassword.defaultProps = {
  value: undefined,
  fullWidth: false,
  isLoading: false,
  compressed: false
};

try {
  OuiFieldPassword.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFieldPassword',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLInputElement>'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      value: {
        defaultValue: {
          value: 'undefined'
        },
        description: '',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      type: {
        defaultValue: {
          value: 'password'
        },
        description: 'Change the `type` of input for manually handling obfuscation.\n' + 'The `dual` option adds the ability to toggle the obfuscation of the input by\n' + 'adding an icon button as the first `append` element',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "password" | "dual"',
          value: [{
            value: '"text"'
          }, {
            value: '"password"'
          }, {
            value: '"dual"'
          }]
        }
      },
      dualToggleProps: {
        defaultValue: null,
        description: 'Additional props to apply to the dual toggle. Extends OuiButtonIcon',
        name: 'dualToggleProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<OuiButtonIconPropsForButton>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'InputHTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}