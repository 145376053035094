/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiRangeLabel = ({
  children,
  disabled,
  side = 'max'
}) => {
  const classes = classNames('ouiRangeLabel', `ouiRangeLabel--${side}`, {
    'ouiRangeLabel--isDisabled': disabled
  });
  return <label className={classes}>{children}</label>;
};
OuiRangeLabel.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  disabled: PropTypes.bool,
  side: PropTypes.oneOf(["min", "max"])
};

try {
  OuiRangeLabel.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeLabel',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'oui/src/components/form/range/range_label.tsx',
          name: 'OuiRangeLabelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_label.tsx',
          name: 'OuiRangeLabelProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactText'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'oui/src/components/form/range/range_label.tsx',
          name: 'OuiRangeLabelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_label.tsx',
          name: 'OuiRangeLabelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      side: {
        defaultValue: {
          value: 'max'
        },
        description: '',
        name: 'side',
        parent: {
          fileName: 'oui/src/components/form/range/range_label.tsx',
          name: 'OuiRangeLabelProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_label.tsx',
          name: 'OuiRangeLabelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"max" | "min"',
          value: [{
            value: '"max"'
          }, {
            value: '"min"'
          }]
        }
      }
    },
    extendedInterfaces: ['OuiRangeLabelProps']
  };
} catch (e) {}