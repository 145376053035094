/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
var gutterSizeToClassNameMap = {
  none: null,
  xs: 'ouiBadgeGroup--gutterExtraSmall',
  s: 'ouiBadgeGroup--gutterSmall'
};
export var GUTTER_SIZES = keysOf(gutterSizeToClassNameMap);
export var OuiBadgeGroup = forwardRef(({
  children,
  className,
  gutterSize = 'xs',
  ...rest
}, ref) => {
  const classes = classNames('ouiBadgeGroup', gutterSizeToClassNameMap[gutterSize], className);
  return <div className={classes} ref={ref} {...rest}>
        {React.Children.map(children, child => <span className="ouiBadgeGroup__item">{child}</span>)}
      </div>;
});
OuiBadgeGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Space between badges
     */
  gutterSize: PropTypes.oneOf(["none", "xs", "s"]),

  /**
     * Should be a list of OuiBadge's but can also be any other element
     * Will apply an extra class to add spacing
     */
  children: PropTypes.node
};
OuiBadgeGroup.displayName = 'OuiBadgeGroup';

try {
  OuiBadgeGroup.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiBadgeGroup',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "Should be a list of OuiBadge's but can also be any other element\n" + 'Will apply an extra class to add spacing',
        name: 'children',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'oui/src/components/badge/badge_group/badge_group.tsx',
          name: 'OuiBadgeGroupProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'xs'
        },
        description: 'Space between badges',
        name: 'gutterSize',
        parent: {
          fileName: 'oui/src/components/badge/badge_group/badge_group.tsx',
          name: 'OuiBadgeGroupProps'
        },
        declarations: [{
          fileName: 'oui/src/components/badge/badge_group/badge_group.tsx',
          name: 'OuiBadgeGroupProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "none"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"none"'
          }]
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiBadgeGroupProps', 'Attributes']
  };
} catch (e) {}