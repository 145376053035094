/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiScreenReaderOnly } from '../../accessibility';
import { OuiSuperSelectControl } from './super_select_control';
import { OuiInputPopover } from '../../popover';
import { OuiContextMenuItem } from '../../context_menu';
import { keys } from '../../../services';
import { OuiI18n } from '../../i18n';
var ShiftDirection;

(function (ShiftDirection) {
  ShiftDirection["BACK"] = "back";
  ShiftDirection["FORWARD"] = "forward";
})(ShiftDirection || (ShiftDirection = {}));

export class OuiSuperSelect extends Component {
  static defaultProps = {
    hasDividers: false,
    fullWidth: false,
    compressed: false,
    isInvalid: false,
    isLoading: false
  };
  itemNodes = [];
  _isMounted = false;
  state = {
    isPopoverOpen: this.props.isOpen || false
  };

  componentDidMount() {
    this._isMounted = true;

    if (this.props.isOpen) {
      this.openPopover();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setItemNode = (node, index) => {
    this.itemNodes[index] = node;
  };
  openPopover = () => {
    this.setState({
      isPopoverOpen: true
    });

    const focusSelected = () => {
      const indexOfSelected = this.props.options.reduce((indexOfSelected, option, index) => {
        if (indexOfSelected != null) return indexOfSelected;
        if (option == null) return null;
        return option.value === this.props.valueOfSelected ? index : null;
      }, null);
      requestAnimationFrame(() => {
        if (!this._isMounted) {
          return;
        }

        if (this.props.valueOfSelected != null) {
          if (indexOfSelected != null) {
            this.focusItemAt(indexOfSelected);
          } else {
            focusSelected();
          }
        }
      });
    };

    requestAnimationFrame(focusSelected);
  };
  closePopover = () => {
    this.setState({
      isPopoverOpen: false
    });
  };
  itemClicked = value => {
    this.setState({
      isPopoverOpen: false
    });

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
  onSelectKeyDown = event => {
    if (event.key === keys.ARROW_UP || event.key === keys.ARROW_DOWN) {
      event.preventDefault();
      event.stopPropagation();
      this.openPopover();
    }
  };
  onItemKeyDown = event => {
    switch (event.key) {
      case keys.ESCAPE:
        // close the popover and prevent ancestors from handling
        event.preventDefault();
        event.stopPropagation();
        this.closePopover();
        break;

      case keys.TAB:
        // no-op
        event.preventDefault();
        event.stopPropagation();
        break;

      case keys.ARROW_UP:
        event.preventDefault();
        event.stopPropagation();
        this.shiftFocus(ShiftDirection.BACK);
        break;

      case keys.ARROW_DOWN:
        event.preventDefault();
        event.stopPropagation();
        this.shiftFocus(ShiftDirection.FORWARD);
        break;
    }
  };

  focusItemAt(index) {
    const targetElement = this.itemNodes[index];

    if (targetElement != null) {
      targetElement.focus();
    }
  }

  shiftFocus(direction) {
    const currentIndex = this.itemNodes.indexOf(document.activeElement);
    let targetElementIndex;

    if (currentIndex === -1) {
      // somehow the select options has lost focus
      targetElementIndex = 0;
    } else {
      if (direction === ShiftDirection.BACK) {
        targetElementIndex = currentIndex === 0 ? this.itemNodes.length - 1 : currentIndex - 1;
      } else {
        targetElementIndex = currentIndex === this.itemNodes.length - 1 ? 0 : currentIndex + 1;
      }
    }

    this.focusItemAt(targetElementIndex);
  }

  render() {
    const {
      className,
      options,
      valueOfSelected,
      onChange,
      isOpen,
      isInvalid,
      hasDividers,
      itemClassName,
      itemLayoutAlign,
      fullWidth,
      popoverClassName,
      compressed,
      ...rest
    } = this.props;
    const popoverClasses = classNames('ouiSuperSelect', popoverClassName);
    const buttonClasses = classNames({
      'ouiSuperSelect--isOpen__button': this.state.isPopoverOpen
    }, className);
    const itemClasses = classNames('ouiSuperSelect__item', {
      'ouiSuperSelect__item--hasDividers': hasDividers
    }, itemClassName);
    const button = <OuiSuperSelectControl options={options} value={valueOfSelected} onClick={this.state.isPopoverOpen ? this.closePopover : this.openPopover} onKeyDown={this.onSelectKeyDown} className={buttonClasses} fullWidth={fullWidth} isInvalid={isInvalid} compressed={compressed} {...rest} />;
    const items = options.map((option, index) => {
      const {
        value,
        dropdownDisplay,
        inputDisplay,
        ...optionRest
      } = option;
      return <OuiContextMenuItem key={index} className={itemClasses} icon={valueOfSelected === value ? 'check' : 'empty'} onClick={() => this.itemClicked(value)} onKeyDown={this.onItemKeyDown} layoutAlign={itemLayoutAlign} buttonRef={node => this.setItemNode(node, index)} role="option" id={value} aria-selected={valueOfSelected === value} {...optionRest}>
          {dropdownDisplay || inputDisplay}
        </OuiContextMenuItem>;
    });
    return <OuiInputPopover className={popoverClasses} input={button} isOpen={isOpen || this.state.isPopoverOpen} closePopover={this.closePopover} panelPaddingSize="none" fullWidth={fullWidth}>
        <OuiScreenReaderOnly>
          <p role="alert">
            <OuiI18n token="ouiSuperSelect.screenReaderAnnouncement" default="You are in a form selector of {optionsCount} items and must select a single option.
              Use the up and down keys to navigate or escape to close." values={{
            optionsCount: options.length
          }} />
          </p>
        </OuiScreenReaderOnly>
        <div className="ouiSuperSelect__listbox" role="listbox" aria-activedescendant={valueOfSelected} tabIndex={0}>
          {items}
        </div>
      </OuiInputPopover>;
  }

}
OuiSuperSelect.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  compressed: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,

  /**
     * Creates an input group with element(s) coming before input.
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

  /**
     * Creates an input group with element(s) coming after input.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

  /**
       * Pass an array of options that must at least include:
       * `value`: storing unique value of item,
       * `inputDisplay`: what shows inside the form input when selected
       * `dropdownDisplay` (optional): what shows for the item in the dropdown
       */
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    inputDisplay: PropTypes.node,
    dropdownDisplay: PropTypes.node,
    disabled: PropTypes.bool,
    "data-test-subj": PropTypes.string
  }).isRequired).isRequired,
  valueOfSelected: PropTypes.any,

  /**
       * Classes for the context menu item
       */
  itemClassName: PropTypes.string,

  /**
       * You must pass an `onChange` function to handle the update of the value
       */
  onChange: PropTypes.func,

  /**
       * Change to `true` if you want horizontal lines between options.
       * This is best used when options are multi-line.
       */
  hasDividers: PropTypes.bool,

  /**
       * Change `OuiContextMenuItem` layout position of icon
       */
  itemLayoutAlign: PropTypes.oneOf(["center", "top", "bottom"]),

  /**
       * Applied to the outermost wrapper (popover)
       */
  popoverClassName: PropTypes.string,

  /**
       * Controls whether the options are shown. Default: false
       */
  isOpen: PropTypes.bool
};

try {
  OuiSuperSelect.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSuperSelect',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: null,
        description: '',
        name: 'readOnly',
        parent: {
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      options: {
        defaultValue: null,
        description: 'Pass an array of options that must at least include:\n' + '`value`: storing unique value of item,\n' + '`inputDisplay`: what shows inside the form input when selected\n' + '`dropdownDisplay` (optional): what shows for the item in the dropdown',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'OuiSuperSelectOption<T>[]'
        }
      },
      valueOfSelected: {
        defaultValue: null,
        description: '',
        name: 'valueOfSelected',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      itemClassName: {
        defaultValue: null,
        description: 'Classes for the context menu item',
        name: 'itemClassName',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'You must pass an `onChange` function to handle the update of the value',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(value: T) => void'
        }
      },
      hasDividers: {
        defaultValue: {
          value: 'false'
        },
        description: 'Change to `true` if you want horizontal lines between options.\n' + 'This is best used when options are multi-line.',
        name: 'hasDividers',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      itemLayoutAlign: {
        defaultValue: null,
        description: 'Change `OuiContextMenuItem` layout position of icon',
        name: 'itemLayoutAlign',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiContextMenuItemLayoutAlignment',
          value: [{
            value: '"center"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      },
      popoverClassName: {
        defaultValue: null,
        description: 'Applied to the outermost wrapper (popover)',
        name: 'popoverClassName',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isOpen: {
        defaultValue: null,
        description: 'Controls whether the options are shown. Default: false',
        name: 'isOpen',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'OuiSuperSelectControlProps', 'HTMLAttributes', 'ButtonHTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}