function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

import PropTypes from "prop-types";
/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React, { Component } from 'react';
import { isString } from '../../services/predicate';
import { OuiFlexGroup, OuiFlexItem } from '../flex';
import { OuiSearchBox } from './search_box';
import { OuiSearchFilters } from './search_filters';
import { Query } from './query';
export { Query, AST as Ast } from './query';

var parseQuery = function parseQuery(query, props) {
  var schema = undefined;

  if (props.box && props.box.schema && _typeof(props.box.schema) === 'object') {
    schema = props.box.schema;
  }

  var dateFormat = props.dateFormat;
  var parseOptions = {
    schema: schema,
    dateFormat: dateFormat
  };

  if (!query) {
    return Query.parse('', parseOptions);
  }

  return isString(query) ? Query.parse(query, parseOptions) : query;
};

export class OuiSearchBar extends Component {
  static Query = Query;

  constructor(props) {
    super(props);
    const query = parseQuery(props.defaultQuery || props.query, props);
    this.state = {
      query,
      queryText: query.text,
      error: null
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ((nextProps.query || nextProps.query === '') && (!prevState.query || typeof nextProps.query !== 'string' && nextProps.query.text !== prevState.query.text || typeof nextProps.query === 'string' && nextProps.query !== prevState.query.text)) {
      const query = parseQuery(nextProps.query, nextProps);
      return {
        query,
        queryText: query.text,
        error: null
      };
    }

    return null;
  }

  notifyControllingParent(newState) {
    const {
      onChange
    } = this.props;

    if (!onChange) {
      return;
    }

    const oldState = this.state;
    const {
      query,
      queryText,
      error
    } = newState;
    const isQueryDifferent = oldState.queryText !== queryText;
    const oldError = oldState.error ? oldState.error.message : null;
    const newError = error ? error.message : null;
    const isErrorDifferent = oldError !== newError;

    if (isQueryDifferent || isErrorDifferent) {
      if (error == null) {
        onChange({
          query: query,
          queryText,
          error
        });
      } else {
        onChange({
          query: null,
          queryText,
          error
        });
      }
    }
  }

  onSearch = queryText => {
    try {
      const query = parseQuery(queryText, this.props);
      this.notifyControllingParent({
        query,
        queryText,
        error: null
      });
      this.setState({
        query,
        queryText,
        error: null
      });
    } catch (e) {
      const error = {
        name: e.name,
        message: e.message
      };
      this.notifyControllingParent({
        query: null,
        queryText,
        error
      });
      this.setState({
        queryText,
        error
      });
    }
  };
  onFiltersChange = query => {
    this.notifyControllingParent({
      query,
      queryText: query.text,
      error: null
    });
    this.setState({
      query,
      queryText: query.text,
      error: null
    });
  };

  renderTools(tools) {
    if (!tools) {
      return undefined;
    }

    if (Array.isArray(tools)) {
      return tools.map(tool => <OuiFlexItem grow={false} key={tool.key == null ? undefined : tool.key}>
          {tool}
        </OuiFlexItem>);
    }

    return <OuiFlexItem grow={false}>{tools}</OuiFlexItem>;
  }

  render() {
    const {
      query,
      queryText,
      error
    } = this.state;
    const {
      box: {
        schema,
        ...box
      } = {
        schema: ''
      },
      // strip `schema` out to prevent passing it to OuiSearchBox
      filters,
      toolsLeft,
      toolsRight
    } = this.props;
    const toolsLeftEl = this.renderTools(toolsLeft);
    const filtersBar = !filters ? undefined : <OuiFlexItem className="ouiSearchBar__filtersHolder" grow={false}>
        <OuiSearchFilters filters={filters} query={query} onChange={this.onFiltersChange} />
      </OuiFlexItem>;
    const toolsRightEl = this.renderTools(toolsRight);
    return <OuiFlexGroup gutterSize="m" alignItems="center" wrap>
        {toolsLeftEl}
        <OuiFlexItem className="ouiSearchBar__searchHolder" grow={true}>
          <OuiSearchBox {...box} query={queryText} onSearch={this.onSearch} isInvalid={error != null} title={error ? error.message : undefined} />
        </OuiFlexItem>
        {filtersBar}
        {toolsRightEl}
      </OuiFlexGroup>;
  }

}
OuiSearchBar.propTypes = {
  onChange: PropTypes.func,

  /**
     The initial query the bar will hold when first mounted
     */
  defaultQuery: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.string.isRequired]),

  /**
     If you wish to use the search bar as a controlled component, continuously pass the query via this prop.
     */
  query: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.string.isRequired]),

  /**
     Configures the search box. Set `placeholder` to change the placeholder text in the box and `incremental` to support incremental (as you type) search.
     */
  box: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
    fullWidth: PropTypes.bool,
    isLoading: PropTypes.bool,

    /**
       * Called when the user presses [Enter] OR on change if the incremental prop is `true`.
       * If you don't need the on[Enter] functionality, prefer using onChange
       */
    onSearch: PropTypes.func,

    /**
       * When `true` the search will be executed (that is, the `onSearch` will be called) as the
       * user types.
       */
    incremental: PropTypes.bool,

    /**
       * when `true` creates a shorter height input
       */
    compressed: PropTypes.bool,
    inputRef: PropTypes.func,

    /**
       * Shows a button that quickly clears any input
       */
    isClearable: PropTypes.bool,

    /**
       * Creates an input group with element(s) coming before input
       * `string` | `ReactElement` or an array of these
       */
    prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

    /**
       * Creates an input group with element(s) coming after input.
       * `string` | `ReactElement` or an array of these
       */
    append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    // Boolean values are not meaningful to this OuiSearchBox, but are allowed so that other
    // components can use e.g. a true value to mean "auto-derive a schema". See OuiInMemoryTable.
    // Admittedly, this is a bit of a hack.
    schema: PropTypes.oneOfType([PropTypes.shape({
      strict: PropTypes.bool,
      fields: PropTypes.any,
      flags: PropTypes.arrayOf(PropTypes.string.isRequired)
    }).isRequired, PropTypes.bool.isRequired])
  }),

  /**
     An array of search filters. See #SearchFilterConfig.
     */
  filters: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
    type: PropTypes.oneOf(["is"]).isRequired,
    field: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    negatedName: PropTypes.string,
    available: PropTypes.func
  }).isRequired, PropTypes.shape({
    type: PropTypes.oneOf(["field_value_selection"]).isRequired,
    field: PropTypes.string,
    name: PropTypes.string.isRequired,

    /**
       * See #FieldValueOptionType
       */
    options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired, PropTypes.shape({
        type: PropTypes.oneOf(["date"]).isRequired,
        raw: PropTypes.any.isRequired,
        granularity: PropTypes.oneOfType([PropTypes.shape({
          es: PropTypes.oneOf(["d", "w", "M", "y"]).isRequired,
          js: PropTypes.oneOf(["day", "week", "month", "year"]).isRequired,
          isSame: PropTypes.func.isRequired,
          start: PropTypes.func.isRequired,
          startOfNext: PropTypes.func.isRequired,
          iso8601: PropTypes.func.isRequired
        }).isRequired, PropTypes.oneOf([undefined])]).isRequired,
        text: PropTypes.string.isRequired,
        resolve: PropTypes.func.isRequired
      }).isRequired]).isRequired,
      name: PropTypes.string,
      view: PropTypes.node
    }).isRequired).isRequired, PropTypes.func.isRequired]).isRequired,
    filterWith: PropTypes.oneOfType([PropTypes.oneOf(["prefix", "includes"]), PropTypes.func.isRequired]),
    cache: PropTypes.number,
    multiSelect: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["and", "or"])]),
    loadingMessage: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    searchThreshold: PropTypes.number,
    available: PropTypes.func,
    autoClose: PropTypes.bool,
    operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
  }).isRequired, PropTypes.shape({
    type: PropTypes.oneOf(["field_value_toggle"]).isRequired,
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired, PropTypes.shape({
      type: PropTypes.oneOf(["date"]).isRequired,
      raw: PropTypes.any.isRequired,
      granularity: PropTypes.oneOfType([PropTypes.shape({
        es: PropTypes.oneOf(["d", "w", "M", "y"]).isRequired,
        js: PropTypes.oneOf(["day", "week", "month", "year"]).isRequired,
        isSame: PropTypes.func.isRequired,
        start: PropTypes.func.isRequired,
        startOfNext: PropTypes.func.isRequired,
        iso8601: PropTypes.func.isRequired
      }).isRequired, PropTypes.oneOf([undefined])]).isRequired,
      text: PropTypes.string.isRequired,
      resolve: PropTypes.func.isRequired
    }).isRequired]).isRequired,
    name: PropTypes.string.isRequired,
    negatedName: PropTypes.string,
    available: PropTypes.func,
    operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
  }).isRequired, PropTypes.shape({
    type: PropTypes.oneOf(["field_value_toggle_group"]).isRequired,
    field: PropTypes.string.isRequired,

    /**
       * See #FieldValueToggleGroupFilterItemType
       */
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired]).isRequired,
      name: PropTypes.string.isRequired,
      negatedName: PropTypes.string,
      operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
    }).isRequired).isRequired,
    available: PropTypes.func
  }).isRequired]).isRequired),

  /**
     * Tools which go to the left of the search bar.
     */
  toolsLeft: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element.isRequired).isRequired]),

  /**
     * Tools which go to the right of the search bar.
     */
  toolsRight: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element.isRequired).isRequired]),

  /**
     * Date formatter to use when parsing date values
     */
  dateFormat: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  Query.__docgenInfo = {
    tags: {},
    description: "This is the consumer interface for the query - it's effectively a wrapper construct around\n" + 'the AST and some of its related utility functions (e.g. parsing, text representation, executing, etc...)\n' + 'It is immutable - all mutating operations return a new (mutated) query instance.',
    displayName: 'Query',
    methods: [],
    props: {},
    extendedInterfaces: ['OuiSearchBarProps', 'CommonProps']
  };
} catch (e) {}

try {
  OuiSearchBar.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSearchBar',
    methods: [],
    props: {
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        },
        declarations: [{
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        }],
        required: false,
        type: {
          name: '(args: ArgsWithQuery | ArgsWithError) => boolean | void'
        }
      },
      defaultQuery: {
        defaultValue: null,
        description: 'The initial query the bar will hold when first mounted',
        name: 'defaultQuery',
        parent: {
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        },
        declarations: [{
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'QueryType'
        }
      },
      query: {
        defaultValue: null,
        description: 'If you wish to use the search bar as a controlled component, continuously pass the query via this prop.',
        name: 'query',
        parent: {
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        },
        declarations: [{
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'QueryType'
        }
      },
      box: {
        defaultValue: null,
        description: 'Configures the search box. Set `placeholder` to change the placeholder text in the box and `incremental` to support incremental (as you type) search.',
        name: 'box',
        parent: {
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        },
        declarations: [{
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'OuiFieldSearchProps & { schema?: boolean | SchemaType; }'
        }
      },
      filters: {
        defaultValue: null,
        description: 'An array of search filters. See #SearchFilterConfig.',
        name: 'filters',
        parent: {
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        },
        declarations: [{
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'SearchFilterConfig[]'
        }
      },
      toolsLeft: {
        defaultValue: null,
        description: 'Tools which go to the left of the search bar.',
        name: 'toolsLeft',
        parent: {
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        },
        declarations: [{
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'Tools'
        }
      },
      toolsRight: {
        defaultValue: null,
        description: 'Tools which go to the right of the search bar.',
        name: 'toolsRight',
        parent: {
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        },
        declarations: [{
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'Tools'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: 'Date formatter to use when parsing date values',
        name: 'dateFormat',
        parent: {
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        },
        declarations: [{
          fileName: 'oui/src/components/search_bar/search_bar.tsx',
          name: 'OuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'object'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiSearchBarProps', 'CommonProps']
  };
} catch (e) {}

try {
  OuiSearchBar.Query.__docgenInfo = {
    tags: {},
    description: "This is the consumer interface for the query - it's effectively a wrapper construct around\n" + 'the AST and some of its related utility functions (e.g. parsing, text representation, executing, etc...)\n' + 'It is immutable - all mutating operations return a new (mutated) query instance.',
    displayName: 'OuiSearchBar.Query',
    methods: [],
    props: {},
    extendedInterfaces: ['OuiSearchBarProps', 'CommonProps']
  };
} catch (e) {}