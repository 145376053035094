/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
export var I18nShapeProps = () => <div />;
I18nShapeProps.propTypes = {
  mapping: PropTypes.shape({}),
  mappingFunc: PropTypes.func,
  formatNumber: PropTypes.func,
  formatDateTime: PropTypes.func,
  locale: PropTypes.string
};

try {
  I18nShapeProps.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'I18nShapeProps',
    methods: [],
    props: {
      mapping: {
        defaultValue: null,
        description: '',
        name: 'mapping',
        parent: {
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '{ [key: string]: Renderable<object>; }'
        }
      },
      mappingFunc: {
        defaultValue: null,
        description: '',
        name: 'mappingFunc',
        parent: {
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '(value: string) => string'
        }
      },
      formatNumber: {
        defaultValue: null,
        description: '',
        name: 'formatNumber',
        parent: {
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '(x: number) => string'
        }
      },
      formatDateTime: {
        defaultValue: null,
        description: '',
        name: 'formatDateTime',
        parent: {
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '(x: Date) => string'
        }
      },
      locale: {
        defaultValue: null,
        description: '',
        name: 'locale',
        parent: {
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'oui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['I18nShape']
  };
} catch (e) {}