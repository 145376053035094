/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
import { OuiTitle } from '../../title';
import { OuiText } from '../../text';
import { OuiFlexGroup, OuiFlexItem } from '../../flex';
var paddingSizeToClassNameMap = {
  xxxs: 'ouiDescribedFormGroup__fieldPadding--xxxsmall',
  xxs: 'ouiDescribedFormGroup__fieldPadding--xxsmall',
  xs: 'ouiDescribedFormGroup__fieldPadding--xsmall',
  s: 'ouiDescribedFormGroup__fieldPadding--small',
  m: 'ouiDescribedFormGroup__fieldPadding--medium',
  l: 'ouiDescribedFormGroup__fieldPadding--large'
};
export var PADDING_SIZES = keysOf(paddingSizeToClassNameMap);
export var OuiDescribedFormGroup = ({
  children,
  className,
  gutterSize = 'l',
  fullWidth = false,
  titleSize = 'xs',
  title,
  description,
  descriptionFlexItemProps,
  fieldFlexItemProps,
  ...rest
}) => {
  const classes = classNames('ouiDescribedFormGroup', {
    'ouiDescribedFormGroup--fullWidth': fullWidth
  }, className);
  const fieldClasses = classNames('ouiDescribedFormGroup__fields', paddingSizeToClassNameMap[titleSize], fieldFlexItemProps && fieldFlexItemProps.className);
  let renderedDescription;

  if (description) {
    renderedDescription = <OuiText size="s" color="subdued" className="ouiDescribedFormGroup__description">
        {description}
      </OuiText>;
  }

  return <div role="group" className={classes} {...rest}>
      <OuiFlexGroup gutterSize={gutterSize}>
        <OuiFlexItem {...descriptionFlexItemProps}>
          <OuiTitle size={titleSize} className="ouiDescribedFormGroup__title">
            {title}
          </OuiTitle>

          {renderedDescription}
        </OuiFlexItem>

        <OuiFlexItem {...fieldFlexItemProps} className={fieldClasses}>
          {children}
        </OuiFlexItem>
      </OuiFlexGroup>
    </div>;
};
OuiDescribedFormGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * One or more `OuiFormRow`s
       */
  children: PropTypes.node,

  /**
       * Passed to `OuiFlexGroup`
       */
  gutterSize: PropTypes.oneOf(["none", "xs", "s", "m", "l", "xl"]),
  fullWidth: PropTypes.bool,

  /**
       * For better accessibility, it's recommended the use of HTML headings
       */
  title: PropTypes.element.isRequired,
  titleSize: PropTypes.oneOf(["xxxs", "xxs", "xs", "s", "m", "l"]),

  /**
       * Added as a child of `OuiText`
       */
  description: PropTypes.node,

  /**
       * For customizing the field container. Extended from `OuiFlexItem`
       */
  descriptionFlexItemProps: PropTypes.any,
  fieldFlexItemProps: PropTypes.any
};

try {
  OuiDescribedFormGroup.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiDescribedFormGroup',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: 'One or more `OuiFormRow`s',
        name: 'children',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'oui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'l'
        },
        description: 'Passed to `OuiFlexGroup`',
        name: 'gutterSize',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m" | "l" | "xl" | "none"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"none"'
          }]
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      title: {
        defaultValue: null,
        description: "For better accessibility, it's recommended the use of HTML headings",
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      titleSize: {
        defaultValue: {
          value: 'xs'
        },
        description: '',
        name: 'titleSize',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      description: {
        defaultValue: null,
        description: 'Added as a child of `OuiText`',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      descriptionFlexItemProps: {
        defaultValue: null,
        description: 'For customizing the description container. Extended from `OuiFlexItem`',
        name: 'descriptionFlexItemProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLDivElement | HTMLSpanElement> & OuiFlexItemProps'
        }
      },
      fieldFlexItemProps: {
        defaultValue: null,
        description: 'For customizing the field container. Extended from `OuiFlexItem`',
        name: 'fieldFlexItemProps',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLDivElement | HTMLSpanElement> & OuiFlexItemProps'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}