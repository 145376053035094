/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
export var OuiHeaderSectionsProp = () => <div />;
OuiHeaderSectionsProp.propTypes = {
  /**
     * An arry of items that will be wrapped in a #OuiHeaderSectionItem
     */
  items: PropTypes.arrayOf(PropTypes.node.isRequired),

  /**
     * Apply the passed border side to each #OuiHeaderSectionItem
     */
  borders: PropTypes.oneOf(["left", "right", "none"]),

  /**
     * Breadcrumbs in the header cannot be wrapped in a #OuiHeaderSection in order for truncation to work.
     * Simply pass the array of OuiBreadcrumb objects
     */
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Visible label of the breadcrumb
       */
    text: PropTypes.node.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,

    /**
       * Force a max-width on the breadcrumb text
       */
    truncate: PropTypes.bool
  }).isRequired),

  /**
     * Other props to pass to #OuiHeaderBreadcrumbs
     */
  breadcrumbProps: PropTypes.any
};

try {
  OuiHeaderSectionsProp.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiHeaderSectionsProp',
    methods: [],
    props: {
      items: {
        defaultValue: null,
        description: 'An arry of items that will be wrapped in a #OuiHeaderSectionItem',
        name: 'items',
        parent: {
          fileName: 'oui/src/components/header/header.tsx',
          name: 'OuiHeaderSections'
        },
        declarations: [{
          fileName: 'oui/src/components/header/header.tsx',
          name: 'OuiHeaderSections'
        }],
        required: false,
        type: {
          name: 'ReactNode[]'
        }
      },
      borders: {
        defaultValue: null,
        description: 'Apply the passed border side to each #OuiHeaderSectionItem',
        name: 'borders',
        parent: {
          fileName: 'oui/src/components/header/header.tsx',
          name: 'OuiHeaderSections'
        },
        declarations: [{
          fileName: 'oui/src/components/header/header.tsx',
          name: 'OuiHeaderSections'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "none"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"none"'
          }]
        }
      },
      breadcrumbs: {
        defaultValue: null,
        description: 'Breadcrumbs in the header cannot be wrapped in a #OuiHeaderSection in order for truncation to work.\n' + 'Simply pass the array of OuiBreadcrumb objects',
        name: 'breadcrumbs',
        parent: {
          fileName: 'oui/src/components/header/header.tsx',
          name: 'OuiHeaderSections'
        },
        declarations: [{
          fileName: 'oui/src/components/header/header.tsx',
          name: 'OuiHeaderSections'
        }],
        required: false,
        type: {
          name: 'OuiBreadcrumb[]'
        }
      },
      breadcrumbProps: {
        defaultValue: null,
        description: 'Other props to pass to #OuiHeaderBreadcrumbs',
        name: 'breadcrumbProps',
        parent: {
          fileName: 'oui/src/components/header/header.tsx',
          name: 'OuiHeaderSections'
        },
        declarations: [{
          fileName: 'oui/src/components/header/header.tsx',
          name: 'OuiHeaderSections'
        }],
        required: false,
        type: {
          name: 'Pick<OuiBreadcrumbsProps, "className" | "aria-label" | "data-test-subj" | "max" | "responsive" | "truncate">'
        }
      }
    },
    extendedInterfaces: ['OuiHeaderSections']
  };
} catch (e) {}