/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
export var useOuiTextDiffProp = () => {
  return <div />;
};
useOuiTextDiffProp.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * The starting string
     */
  beforeText: PropTypes.string.isRequired,

  /**
     * The string used to compare against `beforeText`
     */
  afterText: PropTypes.string.isRequired,

  /**
     * HTML element to wrap insertion differences.
     * Defaults to `ins`
     */
  insertComponent: PropTypes.any,

  /**
     * HTML element to wrap deletion differences.
     * Defaults to `del`
     */
  deleteComponent: PropTypes.any,

  /**
     * HTML element to wrap text with no differences.
     * Doesn't wrap with anything by default
     */
  sameComponent: PropTypes.any,

  /**
     * Time in milliseconds. Passing a timeout of value '0' disables the timeout state
     */
  timeout: PropTypes.number
};

try {
  useOuiTextDiffProp.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'useOuiTextDiffProp',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      beforeText: {
        defaultValue: null,
        description: 'The starting string',
        name: 'beforeText',
        parent: {
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      afterText: {
        defaultValue: null,
        description: 'The string used to compare against `beforeText`',
        name: 'afterText',
        parent: {
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      insertComponent: {
        defaultValue: null,
        description: 'HTML element to wrap insertion differences.\nDefaults to `ins`',
        name: 'insertComponent',
        parent: {
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        }],
        required: false,
        type: {
          name: 'ElementType<any>'
        }
      },
      deleteComponent: {
        defaultValue: null,
        description: 'HTML element to wrap deletion differences.\nDefaults to `del`',
        name: 'deleteComponent',
        parent: {
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        }],
        required: false,
        type: {
          name: 'ElementType<any>'
        }
      },
      sameComponent: {
        defaultValue: null,
        description: 'HTML element to wrap text with no differences.\n' + "Doesn't wrap with anything by default",
        name: 'sameComponent',
        parent: {
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        }],
        required: false,
        type: {
          name: 'ElementType<any>'
        }
      },
      timeout: {
        defaultValue: null,
        description: "Time in milliseconds. Passing a timeout of value '0' disables the timeout state",
        name: 'timeout',
        parent: {
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        },
        declarations: [{
          fileName: 'oui/src/components/text_diff/text_diff.tsx',
          name: 'Props'
        }],
        required: false,
        type: {
          name: 'number'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'Props', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}