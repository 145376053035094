/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiTabs, OuiTab } from '../../../../../src/components/tabs';
import { OuiErrorBoundary } from '../../../../../src/components/error_boundary';
import { OuiHorizontalRule } from '../../../../../src/components/horizontal_rule';
import { GuideSectionSnippets } from './guide_section_snippets';
import { GuideSectionExampleCode } from './guide_section_code';
import { GuideSectionPropsTable } from './guide_section_props_table';
import { OuiFlexGroup, OuiFlexItem } from '../../../../../src/components/flex';
export var GuideSectionExampleTabs = ({
  tabs,
  rightSideControl
}) => {
  const [selectedTabId, setSelectedTabId] = useState('');

  const onSelectedTabChanged = id => {
    if (id === selectedTabId) {
      setSelectedTabId('');
    } else {
      setSelectedTabId(id);
    }
  };

  const tabClasses = classNames('guideSectionTabs', {
    'guideSectionTabs--open': selectedTabId
  });

  const renderTabs = () => {
    return <OuiTabs size="s" display="condensed">
        {tabs.map((tab, index) => {
        const {
          displayName,
          code,
          name,
          props,
          snippets,
          ...rest
        } = tab;
        return <OuiTab {...rest} className="guideSectionTabs__tab" name={name} onClick={() => onSelectedTabChanged(name)} isSelected={name === selectedTabId} key={index}>
              {tab.displayName}
            </OuiTab>;
      })}
      </OuiTabs>;
  };

  const renderContent = () => {
    if (!selectedTabId) return null;
    const selectedTab = tabs.find(tab => tab.name === selectedTabId); // SNIPPET

    if (selectedTab && selectedTab.snippets) {
      return <OuiErrorBoundary>
          <OuiHorizontalRule margin="none" />
          <GuideSectionSnippets snippets={selectedTab.snippets} />
        </OuiErrorBoundary>; // SOURCE CODE BLOCK
    } else if (selectedTab && selectedTab.code) {
      return <OuiErrorBoundary>
          <OuiHorizontalRule margin="none" />
          <GuideSectionExampleCode code={selectedTab.code} />
        </OuiErrorBoundary>; // PROPS TABLE
    } else if (selectedTab && selectedTab.props) {
      const components = Object.keys(selectedTab.props);
      return components.map(component => <OuiErrorBoundary key={component}>
          <GuideSectionPropsTable key={component} componentName={component} component={selectedTab.props[component]} />
        </OuiErrorBoundary>);
    }
  };

  return <>
      <OuiFlexGroup className={tabClasses} responsive={false} wrap gutterSize="none" alignItems="center">
        <OuiFlexItem>{renderTabs()}</OuiFlexItem>
        <OuiFlexItem grow={false}>{rightSideControl}</OuiFlexItem>
      </OuiFlexGroup>
      {selectedTabId && renderContent()}
    </>;
};
GuideSectionExampleTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    code: PropTypes.any,
    snippets: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]),
    props: PropTypes.any,
    displayName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired).isRequired,

  /** Renders any content to the right of the tabs (playground toggle) */
  rightSideControl: PropTypes.node
};

try {
  GuideSectionExampleTabs.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'GuideSectionExampleTabs',
    methods: [],
    props: {
      tabs: {
        defaultValue: null,
        description: '',
        name: 'tabs',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section_parts/guide_section_tabs.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'GuideSectionExampleTabType[]'
        }
      },
      rightSideControl: {
        defaultValue: null,
        description: 'Renders any content to the right of the tabs (playground toggle)',
        name: 'rightSideControl',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src-docs/src/components/guide_section/guide_section_parts/guide_section_tabs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}