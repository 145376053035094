/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiRangeHighlight = ({
  className,
  hasFocus,
  showTicks,
  lowerValue,
  upperValue,
  max,
  min,
  compressed,
  background,
  onClick
}) => {
  // Calculate the width the range based on value
  // const rangeWidth = (value - min) / (max - min);
  const leftPosition = (lowerValue - min) / (max - min);
  const rangeWidth = (upperValue - lowerValue) / (max - min);
  const rangeWidthStyle = {
    background,
    marginLeft: `${leftPosition * 100}%`,
    width: `${rangeWidth * 100}%`
  };
  const classes = classNames('ouiRangeHighlight', {
    'ouiRangeHighlight--hasTicks': showTicks,
    'ouiRangeHighlight--compressed': compressed
  }, className);
  const progressClasses = classNames('ouiRangeHighlight__progress', {
    'ouiRangeHighlight__progress--hasFocus': hasFocus
  });
  return <div className={classes} onClick={onClick}>
      <div className={progressClasses} style={rangeWidthStyle} />
    </div>;
};
OuiRangeHighlight.propTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  compressed: PropTypes.bool,
  hasFocus: PropTypes.bool,
  showTicks: PropTypes.bool,
  lowerValue: PropTypes.number.isRequired,
  upperValue: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onClick: PropTypes.func
};

try {
  OuiRangeHighlight.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeHighlight',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      background: {
        defaultValue: null,
        description: '',
        name: 'background',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasFocus: {
        defaultValue: null,
        description: '',
        name: 'hasFocus',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: '',
        name: 'showTicks',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      lowerValue: {
        defaultValue: null,
        description: '',
        name: 'lowerValue',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      upperValue: {
        defaultValue: null,
        description: '',
        name: 'upperValue',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        },
        declarations: [{
          fileName: 'oui/src/components/form/range/range_highlight.tsx',
          name: 'OuiRangeHighlightProps'
        }],
        required: false,
        type: {
          name: '(e: any) => void'
        }
      }
    },
    extendedInterfaces: ['OuiRangeHighlightProps']
  };
} catch (e) {}