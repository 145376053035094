/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { tabbable } from 'tabbable';
import { OuiFocusTrap } from '../focus_trap';
import { OuiPopover } from './popover';
import { OuiResizeObserver } from '../observer/resize_observer';
import { cascadingMenuKeys } from '../../services';
export var OuiInputPopover = ({
  children,
  className,
  disableFocusTrap = false,
  input,
  fullWidth = false,
  onPanelResize,
  ...props
}) => {
  const [inputEl, setInputEl] = useState(null);
  const [inputElWidth, setInputElWidth] = useState();
  const [panelEl, setPanelEl] = useState(null);

  const inputRef = node => setInputEl(node);

  const panelRef = node => setPanelEl(node);

  const setPanelWidth = useCallback(width => {
    if (panelEl && (!!inputElWidth || !!width)) {
      const newWidth = !!width ? width : inputElWidth;
      panelEl.style.width = `${newWidth}px`;

      if (onPanelResize) {
        onPanelResize(newWidth);
      }
    }
  }, [panelEl, inputElWidth, onPanelResize]);
  const onResize = useCallback(() => {
    if (inputEl) {
      const width = inputEl.getBoundingClientRect().width;
      setInputElWidth(width);
      setPanelWidth(width);
    }
  }, [inputEl, setPanelWidth]);
  useEffect(() => {
    onResize();
  }, [onResize]);
  useEffect(() => {
    setPanelWidth();
  }, [setPanelWidth]);

  const onKeyDown = event => {
    if (panelEl && event.key === cascadingMenuKeys.TAB) {
      const tabbableItems = tabbable(panelEl).filter(el => {
        return Array.from(el.attributes).map(el => el.name).indexOf('data-focus-guard') < 0;
      });

      if (disableFocusTrap || tabbableItems.length && tabbableItems[tabbableItems.length - 1] === document.activeElement) {
        props.closePopover();
      }
    }
  };

  const classes = classnames('ouiInputPopover', {
    'ouiInputPopover--fullWidth': fullWidth
  }, className);
  return <OuiPopover ownFocus={false} button={<OuiResizeObserver onResize={onResize}>
          {resizeRef => <div ref={resizeRef}>{input}</div>}
        </OuiResizeObserver>} buttonRef={inputRef} panelRef={panelRef} className={classes} {...props}>
      <OuiFocusTrap clickOutsideDisables={true} disabled={disableFocusTrap}>
        <div onKeyDown={onKeyDown}>{children}</div>
      </OuiFocusTrap>
    </OuiPopover>;
};
OuiInputPopover.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  disableFocusTrap: PropTypes.bool,
  fullWidth: PropTypes.bool,
  input: PropTypes.any.isRequired,
  inputRef: PropTypes.any,
  onPanelResize: PropTypes.func
};
OuiInputPopover.defaultProps = {
  anchorPosition: 'downLeft',
  attachToAnchor: true,
  display: 'block',
  panelPaddingSize: 's'
};

try {
  OuiInputPopover.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiInputPopover',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + 'Provide a name to the popover panel\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      disableFocusTrap: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'disableFocusTrap',
        parent: {
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      input: {
        defaultValue: null,
        description: '',
        name: 'input',
        parent: {
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLDivElement) => void'
        }
      },
      onPanelResize: {
        defaultValue: null,
        description: '',
        name: 'onPanelResize',
        parent: {
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/input_popover.tsx',
          name: '_OuiInputPopoverProps'
        }],
        required: false,
        type: {
          name: '(width?: number) => void'
        }
      },
      anchorClassName: {
        defaultValue: null,
        description: 'Class name passed to the direct parent of the button',
        name: 'anchorClassName',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      display: {
        defaultValue: {
          value: 'block'
        },
        description: 'CSS display type for both the popover and anchor',
        name: 'display',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inlineBlock" | "block"',
          value: [{
            value: '"inlineBlock"'
          }, {
            value: '"block"'
          }]
        }
      },
      offset: {
        defaultValue: null,
        description: 'Distance away from the anchor that the popover will render',
        name: 'offset',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      closePopover: {
        defaultValue: null,
        description: 'Callback to handle hiding of the popover',
        name: 'closePopover',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      anchorPosition: {
        defaultValue: {
          value: 'downLeft'
        },
        description: 'Alignment of the popover and arrow relative to the button',
        name: 'anchorPosition',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'PopoverAnchorPosition',
          value: [{
            value: '"upCenter"'
          }, {
            value: '"upLeft"'
          }, {
            value: '"upRight"'
          }, {
            value: '"downCenter"'
          }, {
            value: '"downLeft"'
          }, {
            value: '"downRight"'
          }, {
            value: '"leftCenter"'
          }, {
            value: '"leftUp"'
          }, {
            value: '"leftDown"'
          }, {
            value: '"rightCenter"'
          }, {
            value: '"rightUp"'
          }, {
            value: '"rightDown"'
          }]
        }
      },
      attachToAnchor: {
        defaultValue: {
          value: 'true'
        },
        description: 'Style and position alteration for arrow-less, left-aligned\n' + 'attachment. Intended for use with inputs as anchors, e.g.\n' + 'OuiInputPopover',
        name: 'attachToAnchor',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      container: {
        defaultValue: null,
        description: "Restrict the popover's position within this element",
        name: 'container',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'HTMLElement'
        }
      },
      focusTrapProps: {
        defaultValue: null,
        description: 'Object of props passed to OuiFocusTrap',
        name: 'focusTrapProps',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Pick<OuiFocusTrapProps, "clickOutsideDisables" | "noIsolation" | "scrollLock">'
        }
      },
      hasArrow: {
        defaultValue: null,
        description: 'Show arrow indicating to originating button',
        name: 'hasArrow',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus; Can be a DOM\n' + 'node, or a selector string (which will be passed to\n' + 'document.querySelector() to find the DOM node), or a function that\n' + 'returns a DOM node\n' + 'Set to `false` to prevent initial auto-focus. Use only\n' + 'when your app handles setting initial focus state.',
        name: 'initialFocus',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string | false | HTMLElement | (() => HTMLElement)'
        }
      },
      insert: {
        defaultValue: null,
        description: 'Passed directly to OuiPortal for DOM positioning. Both properties are\n' + 'required if prop is specified',
        name: 'insert',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      isOpen: {
        defaultValue: null,
        description: 'Visibility state of the popover',
        name: 'isOpen',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ownFocus: {
        defaultValue: null,
        description: 'Traps tab focus within the popover contents',
        name: 'ownFocus',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelClassName: {
        defaultValue: null,
        description: 'Custom class added to the OuiPanel containing the popover contents',
        name: 'panelClassName',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      panelPaddingSize: {
        defaultValue: {
          value: 's'
        },
        description: 'OuiPanel padding on all sides',
        name: 'panelPaddingSize',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      panelStyle: {
        defaultValue: null,
        description: 'Standard DOM `style` attribute. Passed to the OuiPanel',
        name: 'panelStyle',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      panelProps: {
        defaultValue: null,
        description: 'Object of props passed to OuiPanel',
        name: 'panelProps',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Pick<(DisambiguateSet<_OuiPanelButtonlike, _OuiPanelDivlike> & _OuiPanelDivlike) | (DisambiguateSet<_OuiPanelDivlike, _OuiPanelButtonlike> & _OuiPanelButtonlike), "value" | ... 270 more ... | "borderRadius">'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLElement) => void'
        }
      },
      popoverRef: {
        defaultValue: null,
        description: '',
        name: 'popoverRef',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the popover's position is re-calculated when the user\n" + 'scrolls, this supports having fixed-position popover anchors',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      zIndex: {
        defaultValue: null,
        description: 'By default, popover content inherits the z-index of the anchor\n' + 'component; pass `zIndex` to override',
        name: 'zIndex',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      onTrapDeactivation: {
        defaultValue: null,
        description: 'Function callback for when the focus trap is deactivated',
        name: 'onTrapDeactivation',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      buffer: {
        defaultValue: null,
        description: 'Minimum distance between the popover and the bounding container;\n' + 'Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`\n' + 'Default is 16',
        name: 'buffer',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number | [number, number, number, number]'
        }
      },
      arrowChildren: {
        defaultValue: null,
        description: 'Element to pass as the child element of the arrow;\n' + 'Use case is typically limited to an accompanying `OuiBeacon`',
        name: 'arrowChildren',
        parent: {
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'oui/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', '_OuiInputPopoverProps', 'OuiPopoverProps']
  };
} catch (e) {}