/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiFlexGroup, OuiFlexItem } from '../../flex';
import { htmlIdGenerator } from '../../../services';
export var OuiHeaderAlert = ({
  action,
  className,
  date,
  text,
  title,
  badge,
  ...rest
}) => {
  const classes = classNames('ouiHeaderAlert', className);
  const ariaId = htmlIdGenerator()();
  return <article aria-labelledby={`${ariaId}-title`} className={classes} {...rest}>
      <OuiFlexGroup justifyContent="spaceBetween">
        <OuiFlexItem>
          <div className="ouiHeaderAlert__date">{date}</div>
        </OuiFlexItem>
        {badge && <OuiFlexItem grow={false}>{badge}</OuiFlexItem>}
      </OuiFlexGroup>

      <h3 id={`${ariaId}-title`} className="ouiHeaderAlert__title">
        {title}
      </h3>
      <div className="ouiHeaderAlert__text">{text}</div>
      {action && <div className="ouiHeaderAlert__action ouiLink">{action}</div>}
    </article>;
};
OuiHeaderAlert.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Adds a link to the alert.
       */
  action: PropTypes.node,
  date: PropTypes.node.isRequired,
  text: PropTypes.node,
  title: PropTypes.node.isRequired,

  /**
       * Accepts an `OuiBadge` that displays on the alert
       */
  badge: PropTypes.node
};

try {
  OuiHeaderAlert.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiHeaderAlert',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      action: {
        defaultValue: null,
        description: 'Adds a link to the alert.',
        name: 'action',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      date: {
        defaultValue: null,
        description: '',
        name: 'date',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      text: {
        defaultValue: null,
        description: '',
        name: 'text',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      badge: {
        defaultValue: null,
        description: 'Accepts an `OuiBadge` that displays on the alert',
        name: 'badge',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}