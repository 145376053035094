/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { OuiModal } from './modal';
import { OuiModalFooter } from './modal_footer';
import { OuiModalHeader } from './modal_header';
import { OuiModalHeaderTitle } from './modal_header_title';
import { OuiModalBody } from './modal_body';
import { OuiButton, OuiButtonEmpty } from '../button';
import { OuiText } from '../text';
export var CONFIRM_BUTTON = 'confirm';
export var CANCEL_BUTTON = 'cancel';
export var OuiConfirmModal = ({
  children,
  title,
  onCancel,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  confirmButtonDisabled,
  className,
  buttonColor = 'primary',
  defaultFocusedButton,
  isLoading,
  ...rest
}) => {
  const [cancelButton, setCancelButton] = useState(null);
  const [confirmButton, setConfirmButton] = useState(null);
  useEffect(() => {
    // We have to do this instead of using `autoFocus` because React's polyfill for auto-focusing
    // elements conflicts with the focus-trap logic we have on OuiModal.
    // Wait a beat for the focus-trap to complete, and then set focus to the right button. Check that
    // the buttons exist first, because it's possible the modal has been closed already.
    requestAnimationFrame(() => {
      if (defaultFocusedButton === CANCEL_BUTTON && cancelButton) {
        cancelButton.focus();
      } else if (defaultFocusedButton === CONFIRM_BUTTON && confirmButton) {
        confirmButton.focus();
      }
    });
  });

  const confirmRef = node => setConfirmButton(node);

  const cancelRef = node => setCancelButton(node);

  const classes = classnames('ouiModal--confirmation', className);
  let modalTitle;

  if (title) {
    modalTitle = <OuiModalHeader>
        <OuiModalHeaderTitle data-test-subj="confirmModalTitleText">
          {title}
        </OuiModalHeaderTitle>
      </OuiModalHeader>;
  }

  let message;

  if (typeof children === 'string' && children.length > 0) {
    message = <p>{children}</p>;
  } else {
    message = children;
  }

  return <OuiModal className={classes} onClose={onCancel} {...rest}>
      {modalTitle}

      {message && <OuiModalBody>
          <OuiText data-test-subj="confirmModalBodyText">{message}</OuiText>
        </OuiModalBody>}

      <OuiModalFooter>
        <OuiButtonEmpty data-test-subj="confirmModalCancelButton" onClick={onCancel} buttonRef={cancelRef}>
          {cancelButtonText}
        </OuiButtonEmpty>

        <OuiButton data-test-subj="confirmModalConfirmButton" onClick={onConfirm} isLoading={isLoading} fill buttonRef={confirmRef} color={buttonColor} isDisabled={confirmButtonDisabled}>
          {confirmButtonText}
        </OuiButton>
      </OuiModalFooter>
    </OuiModal>;
};
OuiConfirmModal.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,
  title: PropTypes.node,
  cancelButtonText: PropTypes.node,
  confirmButtonText: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  confirmButtonDisabled: PropTypes.bool,
  className: PropTypes.string,
  defaultFocusedButton: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),
  buttonColor: PropTypes.oneOf(["primary", "accent", "secondary", "success", "warning", "danger", "ghost", "text"]),
  // For docs only, will get passed with ...rest

  /**
     * Sets the max-width of the modal.
     * Set to `true` to use the default (`ouiBreakpoints 'm'`),
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  maxWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
     * Passes `isLoading` prop to the confirm button
     */
  isLoading: PropTypes.bool
};

try {
  OuiConfirmModal.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiConfirmModal',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      cancelButtonText: {
        defaultValue: null,
        description: '',
        name: 'cancelButtonText',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      confirmButtonText: {
        defaultValue: null,
        description: '',
        name: 'confirmButtonText',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      onCancel: {
        defaultValue: null,
        description: '',
        name: 'onCancel',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: true,
        type: {
          name: '(event?: any) => void'
        }
      },
      onConfirm: {
        defaultValue: null,
        description: '',
        name: 'onConfirm',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: '(event: any) => void'
        }
      },
      confirmButtonDisabled: {
        defaultValue: null,
        description: '',
        name: 'confirmButtonDisabled',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      defaultFocusedButton: {
        defaultValue: null,
        description: '',
        name: 'defaultFocusedButton',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"cancel" | "confirm"',
          value: [{
            value: '"cancel"'
          }, {
            value: '"confirm"'
          }]
        }
      },
      buttonColor: {
        defaultValue: {
          value: 'primary'
        },
        description: '',
        name: 'buttonColor',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ButtonColor',
          value: [{
            value: '"text"'
          }, {
            value: '"primary"'
          }, {
            value: '"secondary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      maxWidth: {
        defaultValue: null,
        description: 'Sets the max-width of the modal.\n' + "Set to `true` to use the default (`ouiBreakpoints 'm'`),\n" + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'maxWidth',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'boolean | number | string'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Passes `isLoading` prop to the confirm button',
        name: 'isLoading',
        parent: {
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        },
        declarations: [{
          fileName: 'oui/src/components/modal/confirm_modal.tsx',
          name: 'OuiConfirmModalProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiConfirmModalProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}