/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { getSecureRelForTarget } from '../../services';
import { validateHref } from '../../services/security/href_validator';
export var OuiTab = ({
  isSelected,
  children,
  className,
  disabled: _disabled,
  href,
  target,
  rel,
  ...rest
}) => {
  const isHrefValid = !href || validateHref(href);
  const disabled = _disabled || !isHrefValid;
  const classes = classNames('ouiTab', className, {
    'ouiTab-isSelected': isSelected,
    'ouiTab-isDisabled': disabled
  }); //  <a> elements don't respect the `disabled` attribute. So if we're disabled, we'll just pretend
  //  this is a button and piggyback off its disabled styles.

  if (href && !disabled) {
    const secureRel = getSecureRelForTarget({
      href,
      target,
      rel
    });
    return <a role="tab" aria-selected={!!isSelected} className={classes} href={href} target={target} rel={secureRel} {...rest}>
        <span className="ouiTab__content">{children}</span>
      </a>;
  }

  return <button role="tab" aria-selected={!!isSelected} type="button" className={classes} disabled={disabled} {...rest}>
      <span className="ouiTab__content">{children}</span>
    </button>;
};
OuiTab.propTypes = {
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func
};

try {
  OuiTab.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTab',
    methods: [],
    props: {
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/tabs/tab.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isSelected: {
        defaultValue: null,
        description: '',
        name: 'isSelected',
        parent: {
          fileName: 'oui/src/components/tabs/tab.tsx',
          name: 'OuiTabProps'
        },
        declarations: [{
          fileName: 'oui/src/components/tabs/tab.tsx',
          name: 'OuiTabProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'oui/src/components/tabs/tab.tsx',
          name: 'OuiTabProps'
        },
        declarations: [{
          fileName: 'oui/src/components/tabs/tab.tsx',
          name: 'OuiTabProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'ButtonHTMLAttributes'
        }, {
          fileName: 'oui/src/components/tabs/tab.tsx',
          name: 'OuiTabProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'oui/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'oui/src/components/tabs/tab.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'oui/src/components/tabs/tab.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '((event: MouseEvent<HTMLButtonElement, MouseEvent>) => void) | ((event: MouseEvent<HTMLAnchorElement, MouseEvent>) => void)'
        }
      }
    },
    extendedInterfaces: ['AnchorHTMLAttributes', 'OuiTabProps', 'CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}