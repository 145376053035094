/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { FocusOn } from 'react-focus-on';
export class OuiFocusTrap extends Component {
  static defaultProps = {
    clickOutsideDisables: false,
    disabled: false,
    returnFocus: true,
    noIsolation: true,
    scrollLock: false
  };
  state = {
    hasBeenDisabledByClick: false
  };
  lastInterceptedEvent = null;
  preventFocusExit = false;

  componentDidMount() {
    this.setInitialFocus(this.props.initialFocus);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled === true && this.props.disabled === false) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasBeenDisabledByClick: false
      });
    }
  } // Programmatically sets focus on a nested DOM node; optional


  setInitialFocus = initialFocus => {
    let node = initialFocus instanceof HTMLElement ? initialFocus : null;

    if (typeof initialFocus === 'string') {
      node = document.querySelector(initialFocus);
    } else if (typeof initialFocus === 'function') {
      node = initialFocus();
    }

    if (!node) return; // `data-autofocus` is part of the 'react-focus-on' API

    node.setAttribute('data-autofocus', 'true');
  };
  handleOutsideClick = (...args) => {
    const {
      onClickOutside,
      clickOutsideDisables
    } = this.props;

    if (clickOutsideDisables) {
      this.setState({
        hasBeenDisabledByClick: true
      });
    }

    if (onClickOutside) {
      onClickOutside(...args);
    }
  };

  render() {
    const {
      children,
      clickOutsideDisables,
      disabled,
      returnFocus,
      noIsolation,
      scrollLock,
      ...rest
    } = this.props;
    const isDisabled = disabled || this.state.hasBeenDisabledByClick;
    const focusOnProps = {
      returnFocus,
      noIsolation,
      scrollLock,
      enabled: !isDisabled,
      ...rest,
      onClickOutside: this.handleOutsideClick
    };
    return <FocusOn {...focusOnProps}>{children}</FocusOn>;
  }

}
OuiFocusTrap.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Clicking outside the trap area will disable the trap
     */
  clickOutsideDisables: PropTypes.bool,

  /**
     * Reference to element that will get focus when the trap is initiated
     */
  initialFocus: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.string.isRequired, PropTypes.func.isRequired]),
  style: PropTypes.any,
  disabled: PropTypes.bool
};

try {
  OuiFocusTrap.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFocusTrap',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'oui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      clickOutsideDisables: {
        defaultValue: {
          value: 'false'
        },
        description: 'Clicking outside the trap area will disable the trap',
        name: 'clickOutsideDisables',
        parent: {
          fileName: 'oui/src/components/focus_trap/focus_trap.tsx',
          name: 'OuiFocusTrapInterface'
        },
        declarations: [{
          fileName: 'oui/src/components/focus_trap/focus_trap.tsx',
          name: 'OuiFocusTrapInterface'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Reference to element that will get focus when the trap is initiated',
        name: 'initialFocus',
        parent: {
          fileName: 'oui/src/components/focus_trap/focus_trap.tsx',
          name: 'OuiFocusTrapInterface'
        },
        declarations: [{
          fileName: 'oui/src/components/focus_trap/focus_trap.tsx',
          name: 'OuiFocusTrapInterface'
        }],
        required: false,
        type: {
          name: 'FocusTarget'
        }
      },
      disabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'oui/src/components/focus_trap/focus_trap.tsx',
          name: 'OuiFocusTrapInterface'
        },
        declarations: [{
          fileName: 'oui/src/components/focus_trap/focus_trap.tsx',
          name: 'OuiFocusTrapInterface'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'ReactFocusOnProps', 'OuiFocusTrapInterface']
  };
} catch (e) {}